import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from 'lib/config'

export const useWeb3 = async () =>{
    if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
        var provider = config.provider
        const web3 = new Web3(provider);
        return web3;
      }
      else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'mt') {
        var provider = window.ethereum;
        const web3 = new Web3(provider);
        return web3;
  
      }
}

export const useHTTPWeb3 = async () =>{
    const web3 = new Web3(config.BNBPROVIDER);
    return web3
}