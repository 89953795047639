import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Create(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div>
            <h2 className="page-heading">Create</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Create</a></li>
            </ul>
            
        </div>
        </div>
        </div>
        <div className="container container_custom mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="heading_sm_blk_new">Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to sell one collectible multiple times</p>
             <div className="notable_sec mx-0 masonry mt-5">
               <div className="row w-100 create_row_img justify-content-center">
                 <div className="col-12 col-md-6 col-lg-4 mb-4">
                 <Link to="/create-single" className="item">
                         <div className="card_inner_item">
                         
                     <div className="item_inner_img">
                     <img src={require("../assets/images/create_single_2.png")}  alt="Collections" className="img-fluid" />
                     </div> 
                     <div className="text-center card_user_div p-3 card_user_bg_2">
                                       <h2 className="text-center">Create Single</h2>
                                     <p className="mb-4 h-40">If you want to highlight the uniqueness and individuality of your item</p>

                                     <Link to="/create-single" className="btn btn_purple_sm">Create</Link>
                                       </div>
                                   
                     
                   
                
                                     </div>
                                     </Link>
                 </div>

                 <div className="col-12 col-md-6 col-lg-4 mb-4">
                 <Link to="/create-multiple" className="item">
                         <div className="card_inner_item">
                         
                     <div className="item_inner_img">
                     <img src={require("../assets/images/create_multiple_3.png")}  alt="Collections" className="img-fluid" />
                     </div> 
                     <div className="text-center card_user_div p-3 card_user_bg_2">
                                       <h2 className="text-center">Create Multiple</h2>
                                     <p className="mb-4 h-40">If you want to share your item with a large number of community members</p>

                                     <Link to="/create-multiple" className="btn btn_purple_sm">Create</Link>
                                       </div>
                                   
                     
                   
                
                                     </div>
                                     </Link>
                 </div>
               </div>
        

                                     </div>
            
              <p className="create_para mt-3 text-center pb-5">We do not own your private keys and cannot access your funds without your confirmation</p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
