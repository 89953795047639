import { ContactsOutlined, Pause } from '@material-ui/icons';
import * as V1Toke from 'actions/v1/token';

export const ipfsImageHashGet = async (payload,Image) => {
  var formData = new FormData();
  if (Image) {formData.append("Image", Image);}
  if (payload.name) {formData.append("name", payload.name);}
  if (payload.description) {formData.append("desc", payload.description);}
  
  var Resp = await V1Toke.v1_ipfsImageHashGet(formData)
  return Resp
}

export const NFTDetails2DB = async (payload,Image,User) => {
var formData = new FormData();
    if(Image) { formData.append('Image',Image); }
    if(payload.IpfsImage) { formData.append('ipfsimage', payload.IpfsImage); }
    if(payload.name) { formData.append('Name', payload.name); }
    if((payload.TokenID)) { formData.append('TokenID', payload.TokenID); }
    if(payload.description) { formData.append('Description', payload.description); }
    if(payload.TokenPrice) { formData.append('Price', payload.TokenPrice); }
    if(payload.royalties) { formData.append('Royalities', payload.royalties); }
    if(payload.NFTCategory) { formData.append('Category_label', payload.NFTCategory); }
    if(payload.properties) { formData.append('Properties', payload.properties); }
    if(User) { formData.append('Owner', User); }
    if(User) { formData.append('Creator', User) }
    if(payload.quantity) { formData.append('Quantity', payload.quantity) }
    if(payload.quantity) { formData.append('Balance', payload.quantity) }
    if(payload.contractaddress) { formData.append('ContractAddress', payload.contractaddress) }
    if(payload.Status) { formData.append('Status', payload.Status) }
    if(payload.HashValue) { formData.append('HashValue', payload.HashValue) }
    if(payload.type) { formData.append('Type', payload.type) }
    if(payload.MinimumBid) { formData.append('MinimumBid', payload.MinimumBid) }
    if(payload.AuctionEnd) { formData.append('auctionend', payload.AuctionEnd) }
    if(payload.AuctionStart) { formData.append('auctionstart', payload.AuctionStart) }
    if(payload.UnlockContent) { formData.append('UnLockcontent', payload.UnlockContent) }
    if (payload.Coinname) { formData.append('coinname', payload.Coinname) }
    if(payload.PutonSale) { formData.append('PutOnSale', payload.PutonSale) }
    if(payload.SaleType) { formData.append('PutOnSaleType', payload.SaleType) }

    var Resp = await V1Toke.v1_NFTDetails2DB(formData)
    return Resp
}

export const MyItemsData = async (Payload) =>{
  var Resp = await V1Toke.v1_MyItemsData(Payload)
  Resp = {Success:Resp.data.Resp.Success,Tab:Resp.data.Tab,records:Resp.data.Resp.records}
  //console.log("hbekbhfrjwkbhkwb",Resp)
  return Resp
}

export const NFTInfo = async (Payload) =>{
  //console.log("nft info i/p fgdg ",Payload)
  var Resp = await V1Toke.v1_NFTInfo(Payload)
  var Info = Resp.data
  var NFTInfo = null
  //console.log("NFTInfodetails",Resp.data,Payload)
  if(Info.Success)
  {
    if(Info.records.length > 0)
    {
    NFTInfo = Info.records[0]
    NFTInfo.Mybid=(NFTInfo.AllBids.filter((Bids)=>Bids.tokenBidAddress === Payload.curAddr))
    }
    else{
      NFTInfo = {NFTOwners:[]}
    }
  }
  return NFTInfo
}

export const UpdateNFTOwners = async (Payload) =>{
  var Payload = {Purchase:{
    "tokenPrice" : 0,
    "coinname" : Payload.coinname,
    "deleted" : 1,
    "balance" : Number(Payload.Owns),
    "quantity" : Number(Payload.Owns),
    "contractAddress" : Payload.ConAddr,
    "type" : Payload.NFTDetail.type,
    "burnToken" : 0,
    "previousPrice" : Payload.NFTDetail.tokenPrice,
    "tokenID" : Payload.NFTDetail.tokenID,
    "tokenOwner" : Payload.NewOwner,
    "tokenCreator" : Payload.NFTDetail.tokenCreator,
    "status" : Payload.Status,
    "PutOnSale" : "false",
    "hashValue" : Payload.Hash,
    "OldOwner" : Payload.OldOwner,
    "tokenName" : Payload.tokenName 
  },Action:Payload.Action}
  var Resp = await V1Toke.v1_UpdateNFTOwners(Payload)
  //console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

export const BidAction2DB = async (Payload) =>{
  var Payload = {
    Payload:{
    "status" : "pending",
    "deleted" : 1,
    "NoOfToken" : Payload.Quantity,
    "completed" : 0,
    "cancelled" : 0,
    "pending" : Payload.Quantity,
    "tokenBidAmt" : Payload.BidAmt,
    "tokenBidAddress" : Payload.Bidder,
    "tokenID" : Payload.tokenID,
    "coinname":Payload.coinName
    },
    Act:{
      "tokeName":Payload.tokeName,
      "hash":Payload.Hash,
      "ConAddr":Payload.ConAddr,
      "Coin":Payload.coinName
    }
  }
  var Resp = await V1Toke.v1_BidAction2DB(Payload)
  //console.log("NFTInfodetails",Resp.data)
  return Resp.data
}


