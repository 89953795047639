import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ipfsImageHashGet, NFTDetails2DB } from "actions/controller/tokenControl";
import { useMintReceipt,useApprovalReceipt } from "hooks/useReceipts";
import { useSignCall, useSignLock } from "hooks/useMethods";
import Web3Utils from 'web3-utils'
import { useSelector } from "react-redux";
import $ from "jquery";
import config from 'lib/config'
import { useHistory } from "react-router-dom";

export default function MintingCard(props) {
  const { NFTDetails } = props;
  //console.log("NFT detials from props--",NFTDetails)
  let history = useHistory()
  const [IpfsStatus, setIpfsStatus] = useState(false);
  const [MintStatus, setMintStatus] = useState(true);
  const [SignSellStatus, setSignSellStatus] = useState(true);
  const [SignLockStatus, setSignLockStatus] = useState(true);
  const [IpfsStatusTxt, setIpfsStatusTxt] = useState("Start");
  const [MintStatusTxt, setMintStatusTxt] = useState("Start");
  const [SignSellStatusTxt, setSignSellStatusTxt] = useState("Start");
  const [SignLockStatusTxt, setSignLockStatusTxt] = useState("Start");
 
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [tokenid, settokenid] = useState("");


  const Approved = async()=>{
    setIpfsStatus(true);
    setIpfsStatusTxt("On progress");
    
    
   try{
   var receipt = await useApprovalReceipt(NFTDetails.curraddress,NFTDetails.type,NFTDetails.contractaddress);
    if(receipt != null){
      var tokenid = receipt.logs[1].topics[2];
      const someString = Web3Utils.hexToNumber(tokenid);
      settokenid(someString)
      if(someString != null){
        const id = toast.loading("Generating IPFS Data");
        setIpfsStatus(true);
        var result = await ipfsImageHashGet(NFTDetails, NFTDetails.File)
        if(result.Success){
          setIpfsStatusTxt("Done");
          setMintStatus(false)
          NFTDetails.Ipfs = `${config.IPFS_IMG}/${result.data.ipfsmetadata}`
          NFTDetails.IpfsImage = result.data.ipfsimage
         toast.update(id, {render: result.Message, type: "success", isLoading: false, autoClose: 2500});
         //console.log("NFTDetails after ipsf updation",NFTDetails);
        }else{   setIpfsStatus(false);
          setIpfsStatusTxt("Try again");
          toast.update(id, {render: result.Message, type: "error", isLoading: false, autoClose: 2500});;}


      }
    }

   }
   catch(error)
   {
     //console.log("bdshbfudhs",error)
   }
  }

  const MintCall = async()=>{
    const id = toast.loading("Awaiting Response");
    setMintStatusTxt("On Progress")
    setMintStatus(true)
    try{
    var Blockresp = await useMintReceipt(NFTDetails,NFTDetails.type,Wallet_Details,tokenid)
    if(Blockresp && Blockresp.status)
    {
      Object.assign(NFTDetails,{TokenID:tokenid,Status:Blockresp.status,HashValue:Blockresp.transactionHash})
      await NFTDetails2DB(NFTDetails,NFTDetails.File,Wallet_Details.UserAccountAddr) 
      toast.update(id, {render: "NFT Minted Successfully",type: "success",isLoading:false,autoClose:2000});
      setMintStatusTxt("Done")
      setSignSellStatus(false)
    }
    else
    {
      toast.update(id, {render: "Failed To Confirm",type: "error", isLoading:false,autoClose:2000});
      setMintStatusTxt("Try Again")
      setMintStatus(false)
    }
    //console.log("respformminthook",Blockresp)
  }
  catch(e)
  {
    toast.update(id, {render: "Failed to Confirm",type: "error",isLoading:false,autoClose:2000});
    //console.log("respformminthook",e)
    setMintStatusTxt("Try Again")
    setMintStatus(false)
  }
}


const SignCall = async()=>{
  setSignSellStatusTxt("On Progress")
  setSignSellStatus(true)
  var resp = await useSignCall("Confirm Sign",Wallet_Details.UserAccountAddr)
  if(resp)
  {
    setSignSellStatusTxt("Done")
    setSignLockStatus(false)
    toast.success("sign sell success");
  }
  else
  {
    setSignSellStatusTxt("Try again")
    setSignSellStatus(false)
    toast.error("sign sell error");
  }
}

const SignLock = async() => {
  setSignLockStatusTxt("On Progress")
  setSignLockStatus(true)
  var resp = await useSignCall("Confirm Sign",Wallet_Details.UserAccountAddr)
  if(resp)
  {
     setSignLockStatusTxt("Done")
    $('#ClosingMintCard').trigger( "click" );
    history.push('/my-items')
    toast.success("sign Lock success");
  }
  else
  {
    setSignLockStatusTxt("Try again")
    setSignLockStatus(false)
      toast.error("sign Lock Error");
  }
}

 



  

  
  return (
    <>
      {/* create_item Modal */}
      <div class="modal fade primary_modal" id="create_item_modal" tabindex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="create_item_modalLabel">Follow Steps</h5>
       <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="ClosingMintCard" >
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">                         
       <form> 
       <div className="media approve_media">
       {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
       {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
       {IpfsStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : IpfsStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
       <div className="media-body">
         <p className="mt-0 approve_text" >Approve</p> 
         <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="btn_outline_red btn-block" onClick={()=>Approved()}  disabled={IpfsStatus}>
       {IpfsStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {IpfsStatusTxt}</Button>                
       </div>
       <div className="media approve_media">
       {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
       {MintStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : MintStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
       <div className="media-body">
         <p className="mt-0 approve_text">Upload files & Mint token</p> 
         <p className="mt-0 approve_desc">Call contract method</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className={(MintStatusTxt === 'Done')?"btn_outline_red btn-block":"create_btn btn-block"} onClick={()=>MintCall()}   disabled={MintStatus} > {MintStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {MintStatusTxt}</Button>                
       </div>

       <div className="media approve_media">
       {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
       {SignSellStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : SignSellStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
       <div className="media-body">
         <p className="mt-0 approve_text">Sign sell order</p> 
         <p className="mt-0 approve_desc">Sign sell order using tour wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className={(SignSellStatusTxt == "Done")?"btn_outline_red btn-block":"create_btn btn-block"}  onClick={()=>SignCall()}  disabled={SignSellStatus}>{SignSellStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {SignSellStatusTxt}</Button>                
       </div>

       <div className="media approve_media">
       {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
       {SignLockStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : SignLockStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
       <div className="media-body">
         <p className="mt-0 approve_text">Sign lock order</p> 
         <p className="mt-0 approve_desc">Sign lock order using tour wallet</p>
       </div>
     </div>
       <div className="text-center mt-3">
       <Button className={(SignLockStatusTxt == "Done")?"btn_outline_red btn-block":"create_btn btn-block"}  onClick={()=>SignLock()}  disabled={SignLockStatus}>
                     {SignLockStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {SignLockStatusTxt}</Button>                
       </div>
       </form>
     </div>
   </div>
 </div>
</div>
      {/* end create_item modal */}
    </>
  );
}
