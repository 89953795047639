import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import   config   from  "../lib/config"

// @material-ui/icons
import { AccountBalanceWallet } from '@material-ui/icons';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { v1_Faq } from "actions/v1/user";
import {HelpSearch} from "actions/v1/token"
import { configs } from "eslint-plugin-prettier";
import ReactHtmlParser from 'react-html-parser';






const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Help(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1

  };


  const [HomeMid,setHomeMid] = useState({})

  
  const [ key, setkey] = useState('')
  const [SearchRes, setSearchRes] = useState({
    users: [],
    items: {},
    collections: [],
  });


  const classes = useStyles();
  const { ...rest } = props;

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };

  const Featured = async () => {
    var Resp = await v1_Faq();
    //console.log("fmjbgjkfbvkjfbk",Resp);
    var userval =Resp.userValue
    var help = userval.filter((item)=> item.status == 'help')
    //console.log("bdshbvuhdsbvcuhsdvcuh",help);
    setHomeMid(help)
  };

  useEffect(() => {
    Featured()
  }, []);

  const toggleSearchmenu = async (e) => {
    e.persist();
    if (e && e.target && e.target.value && e.target.value) {
      let keywordVal = e.target.value;
      setkey(keywordVal)
      let postData = {
        limit: 3,
        keyword: keywordVal,
      };
      if(keywordVal){
      // setLoader(true)
      var resp = await HelpSearch (postData);
      if (resp.data && resp.data.Success) {
        //console.log("Searchecs", resp);
        setHomeMid([]);
        setHomeMid(resp.data.Resp);
        setTimeout(() => {
          // setLoader(false)
        }, 1000);
      }
    }
    }
  };


  return (
    <div>

      <div className="home_header">
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
          leftLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />


        <section className="page-head body-top pb-50">
          <div className="container container_custom">
            <h2 className="page-heading">Help Center</h2>
            <ul className="breadcrums">
              <li><a href="#">Home</a></li>
              <li><a href="#" className="active">Help Center</a></li>
            </ul>
          </div>
        </section>
        <section className="help pt-0">
          <div className="container container_custom">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-6 text-center">
                <h2 className="page-heading">How can we help you?</h2>
                <div class="input-group mb-3 helpsearch">
                  <input type="text" class="form-control" placeholder="Type your questions" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => toggleSearchmenu(e)} />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"><i className=" fa fa-search"></i> </span>
                  </div>
                </div>
                <p>or choose a categories to quickly find the help you need</p>

              </div>
            </div>
            <div className="row justify-content-center mt-4">
             
              {HomeMid && HomeMid.length > 0 && HomeMid.map((Midesec) => {
            
                return (
                  <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                   
                    <Link to={"/AboutUs/" + Midesec._id}>
                      <div className="sell-steps grid-card">
                        <span >  <img
                        src={`${config.Back_URL}/Help/${Midesec.image}`}
                        className="img-fluid" width="64" height="64"
                      /></span>
                        <h2>{ReactHtmlParser(Midesec.question)}</h2>
                       <span>{ReactHtmlParser(Midesec.answer)}</span>
                      </div>

                    </Link>

                  </div>
                )
              })
              }
              {/* <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                <div className="sell-steps grid-card">
                  <span className="fa fa-file stepicon mx-auto"></span>
                  <h2>Create your collection</h2>
                  <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                    account at this point, buttonvery few are actually set up to buy an NFT.</p>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                <div className="sell-steps grid-card">
                  <span className="fa fa-shopping-cart stepicon mx-auto"></span>

                  <h2>Buyin</h2>
                  <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                    account at this point, buttonvery few are actually set up to buy an NFT.</p>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                <div className="sell-steps grid-card">
                  <span className="fa fa-signal stepicon mx-auto"></span>

                  <h2>Sellin</h2>
                  <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                    account at this point, buttonvery few are actually set up to buy an NFT.</p>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                <div className="sell-steps grid-card">
                  <span className="fa fa-link stepicon mx-auto"></span>

                  <h2>Partner</h2>
                  <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                    account at this point, buttonvery few are actually set up to buy an NFT.</p>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 text-center mb-4">
                <div className="sell-steps grid-card">
                  <span className="fa fa-cog stepicon mx-auto"></span>

                  <h2>Developer</h2>
                  <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                    account at this point, buttonvery few are actually set up to buy an NFT.</p>
                </div>
              </div> */}
            </div>

          </div>
        </section>








        <div id="logo_overlay" className="logo_ovelay">

          <Footer />

        </div>
      </div>
    </div>
  );
}
