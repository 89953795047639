import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Abi721 from '../ABI/721.json'
import Abi1155 from '../ABI/1155.json' 
import AbiTOKEN from '../ABI/token.json'
import { useWeb3, useHTTPWeb3 } from "./useWeb3";
import config from '../lib/config'

export const useContract = async (Type) =>{
    const web3 = await useWeb3() 
    if(Type === 721)
    {
    const contract = new web3.eth.Contract(Abi721, config.singleContract);
    return contract; 
    }
    if(Type === 1155)
    {
    const contract = new web3.eth.Contract(Abi1155, config.multipleContract);
    return contract; 
    }
}

export const useNFTContract = async (Type, NFTConAddress) =>{
    const web3 = await useWeb3() 
    if(Type === 721)
    {
    const contract = new web3.eth.Contract(Abi721, NFTConAddress);
    return contract; 
    }
    if(Type === 1155)
    {
    const contract = new web3.eth.Contract(Abi1155, NFTConAddress);
    return contract; 
    }
}

export const useTokenContract = async (Type, TokenName) =>{
    //console.log("Type, TokenName ",Type, TokenName)
    const web3 = await useWeb3() 
    const OwnContract = await useContract(Type)
    const TokenAddress = await OwnContract.methods.getTokenAddress(TokenName).call()
    if(Type === 721)
    {
        const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
        return contract; 
    }
    if(Type === 1155)
    {
        const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
        return contract; 
    }
}

export const useServiceFee = async () =>{
    const web3 = await useHTTPWeb3() 
    const contract = new web3.eth.Contract(Abi721, config.singleContract);
    const Fee = await contract
                        .methods
                        .getServiceFee()
                        .call()
    //console.log("dshffrijmnrg",Fee)
    var Serfee = {Buyer:Number(Web3.utils.fromWei(Fee)),Seller:Number(Web3.utils.fromWei(Fee))}
    return Serfee; 
}