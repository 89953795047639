import web3 from "web3";
import React, { useEffect, useState, useRef } from "react";
import { useWeb3 } from "./useWeb3";
import config from '../lib/config'
import { useContract, useNFTContract, useTokenContract } from "./useContract";

export const useMint = async (Arg,Type,Provider,tokid) =>{
    //console.log('aksljdfhljkasdfjksdf',Arg,Type,Provider,tokid)
    var tokenvalue;
   
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    if( Arg.TokenPrice){
    if(  Arg.TokenPrice > 0){
        tokenvalue =  web3.utils.toWei(String(Number(Arg.TokenPrice)))
        //console.log("tokenvalue",tokenvalue)
    }
}else{tokenvalue = 0}
    //var TokenPrice = (Number(Arg.TokenPrice) > 0)?web3.utils.toWei(String(Number(Arg.TokenPrice).toFixed(4))):'0'
     var tokenID = Number(tokid)
     var ipfsdata = String(Arg.Ipfs)  
   
    var tokensupply = Number(Arg.quantity)
     var royalty = Number(Arg.royalties)
     //console.log("params for mint call ",tokenID,ipfsdata,tokenvalue,tokensupply,royalty)
    // .mint(Arg.Ipfs,TokenPrice,Arg.quantity,Arg.royalties)
    try{
        if(Type === 721)
        {
            await Contract
                    .methods
                    .mint(tokenID,ipfsdata,tokenvalue,tokensupply,royalty)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    })
        }
        else    //.mint(TokenPrice,Arg.royalties,Arg.quantity,Arg.Ipfs)
        {
            await Contract
                    .methods
                    .mint(tokenID,tokenvalue,royalty,tokensupply,ipfsdata)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    })
        }
    return Hash
    }
    catch(error)
    {
        //console.log("useMint-Hookerror",error)
        return false
    }
}

export const useTokenApprove = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Hash = null
    //console.log("approve con call data ",Type, Arg.Coinname, Arg.PriceWithSerfee);
    var Contract = await useTokenContract(Type, Arg.Coinname)
    try{
        if(Type === 721) 
        {
            await Contract
                    .methods
                    .approve(config.singleContract, Arg.PriceWithSerfee)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    })
        } 
        else
        {
            await Contract
                    .methods
                    .approve(config.multipleContract, Arg.PriceWithSerfee)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    })
        }
    return Hash
    }
    catch(error)
    {
        //console.log("useTokenApprove-Hookerror",error)
        return false
    }
}

export const usePurchase = async (Arg,Type,Provider,isToken) =>{
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    try{
        if(Type === 721) 
        {
            if(isToken)
            {  
                await Contract
                        .methods
                        .salewithToken(Arg.Coinname, Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee)
                        .send({from: Wallet_Details.UserAccountAddr})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
            }
            else
            {
                await Contract
                        .methods
                        .saleToken(Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee)
                        .send({from:Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
            }
        } 
        else
        {
            if(isToken)
            {
                await Contract
                        .methods
                        .saleWithToken(Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Arg.Coinname)
                        .send({from: Wallet_Details.UserAccountAddr})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
            }
            else
            {
                await Contract
                        .methods
                        .saleToken(Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs)
                        .send({from:Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
            }
        }
    return Hash
    }
    catch(error)
    {
        //console.log("usePurchase-Hookerror",error)
        return false
    }
}

export const useAcceptBid = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    try{
        if(Type === 721) 
        {
            await Contract
                        .methods
                        .acceptBId(Arg.Coinname, Arg.BidderAddress, Arg.PriceWithoutSerfee, Arg.NFTId)
                        .send({from: Wallet_Details.UserAccountAddr})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
        }
        else
        {
            await Contract
                        .methods
                        .acceptBId(Arg.Coinname, Arg.BidderAddress, Arg.PriceWithoutSerfee, Arg.NFTId, Arg.NoofNFTs)
                        .send({from: Wallet_Details.UserAccountAddr})
                        .on('transactionHash', async(transactionHash) => {
                            Hash = transactionHash
                        })
        }
    return Hash
    }
    catch(error)
    {
        //console.log("useAcceptBid-Hookerror",error)
        return false
    }
}

export const useBurn = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    try{
        if(Type === 721) 
        {
            await Contract
                    .methods
                    .burnToken(Arg.NFTId, Arg.NFTOwner)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    })
        } 
        else
        {
            await Contract
                    .methods
                    .burnToken(Arg.NFTOwner, Arg.NFTId, Arg.NoofNFTs)
                    .send({from: Wallet_Details.UserAccountAddr})
                    .on('transactionHash', async(transactionHash) => {
                        Hash = transactionHash
                    }) 
        }
    return Hash
    }
    catch(error)
    {
        //console.log("useBurn-Hookerror",error)
        return false
    }
}

export const usePutonSale = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    try{
        await Contract
                .methods
                .orderPlace(Arg.NFTId, Arg.PriceWithoutSerfee)
                .send({from: Wallet_Details.UserAccountAddr})
                .on('transactionHash', async(transactionHash) => {
                    Hash = transactionHash
                })
    return Hash
    }
    catch(error)
    {
        //console.log("usePutonSale-Hookerror",error)
        return false
    }
}

export const useCancelOrder = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    try{
        await Contract
                .methods
                .cancelOrder(Arg.NFTId)
                .send({from: Wallet_Details.UserAccountAddr})
                .on('transactionHash', async(transactionHash) => {
                    Hash = transactionHash
                })
    return Hash
    }
    catch(error)
    {
        //console.log("usePutonSale-Hookerror",error)
        return false
    }
}

export const useAllowance = async (Arg,Type,Provider) =>{
    const Wallet_Details = Provider
    var Allowance = null
    var Contract = await useTokenContract(Type,Arg.Coinname)
    try{
        if(Type === 721) 
        {
            Allowance = await Contract.methods.allowance(Wallet_Details.UserAccountAddr, config.singleContract).call()
        } 
        else
        {
            Allowance = await Contract.methods.allowance(Wallet_Details.UserAccountAddr, config.singleContract).call();
        }
    return Allowance
    }
    catch(error)
    {
        //console.log("useAllowance-Hookerror",error)
        return false
    }
}

export const useNFTBalCheck = async (Arg,Type) =>{
    var balance = null
    var Contract = await useNFTContract(Type,Arg.NFTConAddress)
    try{
        if(Type === 721) 
        {
            const Owner = await Contract.methods.ownerOf(Arg.NFTId).call()
            if((Owner).toLowerCase() === Arg.NFTOwner)
                balance = 1
            else
                balance = 0
        } 
        else
        {
            balance = await Contract.methods.balanceOf(Arg.NFTOwner,Arg.NFTId).call();
        }
    return balance
    }
    catch(error)
    {
        //console.log("useNFTBalCheck-Hookerror",error)
        return false
    }
}

export const useTokenDecimal = async (Arg,Type) =>{
    const TokenContract = await useTokenContract(Type,Arg.Coin)
    try{
      const Decimal = await TokenContract
                                .methods
                                .decimals()
                                .call()
    return Decimal
    }
    catch(error)
    {
        //console.log("useTokenDecimal-Hookerror",error)
        return false
    }
}

export const useUserTokenBalance = async (Arg,Type,Provider) =>{
    //console.log("use user token bal i/p ",Arg,Type,Provider)
    var Wallet_Details = Provider 
    const TokenContract = await useTokenContract(Type,Arg.Coin)
    try{
      const Balance = await TokenContract
                                .methods
                                .balanceOf(Wallet_Details.UserAccountAddr)
                                .call()
        //console.log("available cake balance ",Balance)
    return Balance
    }
    catch(error)
    {
        //console.log("useTokenDecimal-Hookerror",error)
        return false
    }
}

export const useSignCall = async (Arg,UserAccountAddr) =>{
    const web3 = await useWeb3()
    try{
        const Confirm = await web3.eth.personal.sign(Arg,UserAccountAddr)
        return true
    }
    catch(error)
    {
        //console.log("useSignCall-Hookerror",error)
        return false
    }
}

export const useSignLock = async (UserAccountAddr) =>{
    const web3 = await useWeb3()
    try{
        const Confirm = await web3.eth.personal.sign("Sign Lock Order", UserAccountAddr)
        return true
    }
    catch(error)
    {
        //console.log("useSignLock-Hookerror",error)
        return false
    }
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  export const useApprovalFunctionCall = async (userAddress,Type,conAddress,boolValue) =>{
    
   //console.log("userAddress,Type,conAddress,boolValue  ",userAddress,Type,conAddress,boolValue)
    var Hash;
    var Contract = await useContract(Type)
    //console.log("contract instance",Contract);
    try{
        if(Contract !== ""){
            var approve_hash = await Contract.methods.setApproval(conAddress,boolValue)
                                .send({from:userAddress})
                                .on('transactionHash', async(transactionHash) => {
                                 Hash = transactionHash
                })
    return Hash
          
        }
        
    }
    catch(error)
    {
        //console.log("approval error",error)
        return false
    }
}


