import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter , Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';





// pages for this product
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-single.js";
import CreateMultiple from "views/create-multiple.js";

import HowItWorks from "views/how-it-works.js";
import EditProfile from "views/edit-profile.js";
import Proposals from "views/proposals.js";
import Myitems from "views/my-items.js";
import Explore from "views/Explore.js";

import Following from "views/following.js";
import Discussion from "views/Discussion.js";
import Discussionlatest from "views/discussion-latest.js";
import DiscussionDetail from "views/discussion-detail.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";

import AboutUs from "views/Aboutus"

import Info from "views/info.js";

import Activity from "views/activity.js";
import Search from "views/search.js";

import ConnectWallet from "views/connect-wallet.js";
import comingsoon from "views/comingsoon.js";
import Notfound from "views/not-found";
import Notification from "views/Notification";
import newmyitems from "views/newmyitems";
import Rankings from "views/Rankings";

import Ourcreators from "views/ourcreators";
import Authors from "views/authors";
import help from "views/help";
import contact from "views/contact";
import Faq from "views/faq.js";

import config from 'lib/config'

import { useSelector, useDispatch } from 'react-redux';
import { useServiceFee } from "hooks/useContract"
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import Web3 from 'web3';
import { usePrevious } from "hooks/useMethods";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { v1_Social } from "actions/v1/user";
import { v1_Category } from "actions/v1/user";

import { getTokenListItem } from "actions/v1/token";


import {AddressUserDetails_GetOrSave_Action,} from 'actions/v1/user';
  toast.configure()
var toasterOption=config.toasterOption;




















export default function Routes(props) {

  const birds = useSelector(state => state.wallet_connect_context);
  const dispatch = useDispatch();
  const [useraccounts, setaccounts] = useState('')
  const timerRef = useRef(null);

  const Serfee = async() =>{
    var Fee = await useServiceFee()
    //console.log("service fee details ",Fee)
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee,
      }
    })
  }

  const getTokenList = async() =>{
   
    var TokenList = await getTokenListItem()
    //console.log("token list to dispatch",TokenList.data)
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        TokenList : TokenList.data,
      }
    })
  }

  const SoCat = async() =>{
    var Social = await v1_Social()
    var Category = await v1_Category()
    //console.log("category collection consle --",Category)
    var id = "0000"
    var shareTag = []
    if(Category.Success){
      //alert("category success")
      //console.log("category collection consle --",Category.userValue)
      Category = Category.userValue}
    else
      Category = ''
    if(Social && Social.Success && Social.userValue.length > 0)
        Social = Social.userValue[0].social
    else
        Social = ''
    axios.get(config.v1Url + '/admin/panel/getnfttag/' + id)
    .then((data) => {
      if (data && data.data && data.data.userValue) {
        (data.data.userValue).map((item) => {
          (shareTag).push(item.nfttag)
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              shareTag: shareTag,
              Social:Social,
              Category:Category
            }
          })
        })
      }
    })
  }

  useEffect(() => {
     SoCat();
     Serfee();
     getTokenList();
    if(localStorage.walletConnectType)
        getInit(localStorage.walletConnectType);
  },[birds.Wall]);

  async function connect_Wallet(type) {
    if (type == 'wc') {
      var provider = new WalletConnectProvider({
        rpc: {
          //56: "https://bsc-dataseed1.ninicoin.io",
          4:"https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          //1:"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
        },
        // network: 'binance',
          // chainId: 56,
          network: 'rinkeby',
          chainId: 4,
          // network: 'ethereum',
          // chainId: 1,
      }
      );
      config.provider = provider
      return provider;
    }
    else if (type == 'mt') {
      var provider = window.ethereum;
      config.provider = provider
      return provider;

    }

  }
  const AfterWalletConnected = async (currentAddress) => {
    //var sendaddr = config.currAddress
    var ReqData = {
      addr: currentAddress
    }
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    if (Resp && Resp.data && Resp.data.data && Resp.data.data.RetData && Resp.data.data.RetData.found) {
      return Resp.data.data.RetData.User
    } else {
      return null
    }
  }


  async function getInit(type) {
    var toasttxt = null
    if(type === 'mt')
      toasttxt="Unlock Metamask & Refresh Page"
    else if(type === 'bcw')
      toasttxt="Binance Chain Wallet Connection Checking"
    else if(type === 'wc')
      toasttxt="Wallet Connect Checking"
    else if(type === 'tw')
      toasttxt=" Trust Wallet Connect Checking"
    const id = toast.loading(`${toasttxt}`,{closeButton: true,theme: "dark"});
    var provider = null;
    provider = await connect_Wallet(type);
    if (provider) {
      var web3 = new Web3(provider)
      try {
        await provider.enable()
          .then(async function () {
            var currentAddress = '';
            var balance = 0, setaddress = '';
            var val
            if ((web3.currentProvider.networkVersion == config.networkVersion)
              || (web3.currentProvider.chainId == config.networkVersion)
            ) {
              if (localStorage.walletConnectType == "wc") {
                var result = JSON.parse(localStorage.walletconnect).accounts
                setaddress = result[0];
                val = await web3.eth.getBalance(setaddress)
                balance = Number(web3.utils.fromWei(String(val)));
                currentAddress = String(setaddress).toLowerCase();
              }
              else if (localStorage.walletConnectType == "mt") {
                toast.update(id, {
                  render: "Metamask Wallet Connecting"
                });
                var result = await web3.eth.getAccounts()
                setaddress = result[0];
                await web3.eth.getBalance(setaddress).then((val) => {
                  balance = Number(web3.utils.fromWei(String(val)));
                })
                currentAddress = String(setaddress).toLowerCase();
              }
              else if (localStorage.walletConnectType == "tw") {
                toast.update(id, {
                  render: "Trust Wallet Connecting"
                });
                var result = await web3.eth.getAccounts()
                setaddress = result[0];
                await web3.eth.getBalance(setaddress).then((val) => {
                  balance = Number(web3.utils.fromWei(String(val)));
                })
                currentAddress = String(setaddress).toLowerCase();
              }
              else if (localStorage.walletConnectType == "bcw") {
                toast.update(id, {
                  render: "Binance Chain Wallet Connecting"
                });
                var result = await web3.eth.getAccounts()
                setaddress = result[0];
                await web3.eth.getBalance(setaddress).then((val) => {
                  balance = Number(web3.utils.fromWei(String(val)));
                })
                currentAddress = String(setaddress).toLowerCase();
              }
              setaccounts(currentAddress)
              //console.log("After wallet connect function is calling@123",currentAddress,balance)
              if(birds.UserAccountAddr === "")
              {
              var AddressUserDetails = await AfterWalletConnected(currentAddress);
              //console.log("Addefefrfsvrv",AddressUserDetails,currentAddress)
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  UserAccountAddr: currentAddress,
                  providerss: provider,
                  UserAccountBal: balance,
                  WalletConnected: "true",
                  Accounts: setaddress,
                  AddressUserDetails: AddressUserDetails,
                }
              })
              }
              toast.update(id, {
                render: "Wallet Connected Succesfully",
                type: "success",
                isLoading: false,
                autoClose: 2500,
                closeButton: true,
                theme: "dark"
              });
            }
            else {
              
                toast.warning("Please Connect to Binance Network", toasterOption);
            }
          })
          .catch((e) => {
            //console.log("cauddhdhf",e)
          })
      } catch (err) {
        //localStorage.setItem('connect', 'false')

      }
    }
    else {
      toast.warning("Please Add Metamask External", toasterOption);
    }
  }

  window.addEventListener('load', async (event) => {
    if(localStorage.getItem('walletConnectType')=='mt'){
      if(window.ethereum) {
         window.ethereum.on('accountsChanged', function (accounts) {
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = 
          setTimeout(() => {
            getInit('mt');
          }, 1000);
        })
        
        window.ethereum.on('chainChanged', async function (networkId) {
          //console.log("chainid",networkId)
         if(networkId == config.chainId){
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            getInit('mt');
          }, 1000);
        }
        else {
                //localStorage.setItem('connect', 'false')
                toast.warning("Please Connect to Binance Network", toasterOption);
        }
      })
    }}
    else if(localStorage.walletConnectType=="wc"){
      var provider3 = null
      if(provider3==null){
         provider3 = await connect_Wallet("wc");
      }
      else if(provider3!=null){
      (provider3).on("connect", () => {
        getInit('wc')
      });
      (provider3).on("disconnect", () => {
        localStorage.removeItem('walletConnectType')
      });
      }}
  })




return(
    <>
    <Switch>
     
     <Route path="/home" component={Home} />      
     <Route path="/create" component={Create} />
     <Route path="/create-single" component={CreateSingle} />
     <Route path="/create-multiple" component={CreateSingle} />
     <Route path="/edit-profile" component={EditProfile} />
     <Route path="/Info/:conaddr/:owneraddr/:tokenID" component={Info} />
     {/* <Route path="/Info" component={Info} /> */}
     {/* <Route path="/my-items/user/:userurl" component={Myitems} />
     <Route path="/my-items/:useradd" component={Myitems} />
     <Route path="/my-items" component={Myitems} /> */}
     <Route path="/my-items/user/:userurl" component={Authors} />
     <Route path="/my-items/:useradd" component={Authors} />
     <Route path="/my-items" component={Authors} />
     <Route path="/explore" component={Explore} />
     {/* <Route path="/newmyitems" component={newmyitems} /> */}

     {/* <Route path="/Rankings" component={Rankings} /> */}

     <Route path="/ourcreators" component={Ourcreators} />      
     <Route path="/authors" component={Authors} />      


     <Route path="/search/query/:q" component={Search} />      
     <Route path="/Following" component={Following} />
     <Route path="/activity" component={Activity} />
     <Route path="/notification" component={Notification} />
     <Route path="/connect-wallet" component={ConnectWallet} />

     <Route path="/comingsoon" component={comingsoon} />
     <Route path="/not-found" component={Notfound} />
     <Route path="/privacy-policy" component={Privacypolicy} />
     <Route path="/terms-and-conditions" component={Terms} /> 
     <Route path="/how-it-works" component={HowItWorks} />   

     <Route path="/help" component={help} /> 
     <Route path="/contact" component={contact} />      
     <Route path="/AboutUs/:FaqID" component={AboutUs} />



     <Route exact path="/*" component={Home}>
       <Redirect to="/home" />
     </Route>

       {/* <Route path="/Discussion" component={Discussion} />
     <Route path="/category" component={Discussionlatest} />
     <Route path="/discussion-detail" component={DiscussionDetail} /> */}

     {/* <Route path="/voting-proposals" component={Proposals} /> */}
     {/* <Route path="/FAQ" component={Faq} /> */}


     {/* <Route path="/profile-page" component={ProfilePage} />
     <Route path="/login-page" component={LoginPage} />
     <Route path="/" component={Components} /> */}
   </Switch>
    </>
)
}