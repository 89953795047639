import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
  } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import { Audio, TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "lib/config";
import $ from "jquery"
import { v1_BurnToken } from "actions/v1/token";
import { useBurnReceipt } from "hooks/useReceipts";

export const BurnToken = forwardRef((props, ref) => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [BurnState, setBurnState] = useState(false);
    const [NoofToken, setNoofToken] = useState(0)
    const [NFTImage, setNFTImage] = useState("");
    const [NFTDet, setNFTDet] = useState({});
    const [NFT, setNFT] = useState({ CurrentOwner: {} });
    const history = useHistory()
    useImperativeHandle(ref, () => ({
        async BurnTokenClick(ClickedNFT, NFTimage, NFT) {
          setNFTImage(NFTimage);
          setNFTDet(NFT);
          if (Wallet_Details.UserAccountAddr) {
            setNFT(ClickedNFT);
            //console.log("jdsnbfufnuewrgn", ClickedNFT, NFTimage, NFT);
          }
        },
      }));

    const BurnCall = async (Biddet) => {
    setBurnState(true)
    const id = toast.loading("Token Burn On Progress",{closeButton:true});
    var Arg = {
        NFTOwner: NFT.tokenOwner,
        NFTId: NFT.tokenID,
        NoofNFTs: NoofToken,
      };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    var Receipt = await useBurnReceipt(Arg,Type,Provider)
    //console.log("burnreceiot",Receipt)
    if(Receipt.status)
    {
    var Burn = {Type:NFT.type,Owner:NFT.tokenOwner,id:NFT.tokenID,ConAddr:Receipt.to,NoOfToken:NoofToken,Status:Receipt.status,Hash:Receipt.transactionHash,} 
    var Resp = await v1_BurnToken(Burn)
    //console.log("burnreceiot",Resp)
    if (Resp.data.Success) {
        toast.update(id, {render: Resp.data.Message,type: "success",isLoading: false,autoClose: 2500,});
        $('#burn_closs').trigger( "click" );
        setTimeout(() => {
          history.push('/my-items')
        }, 1000);
    } else {
        setBurnState(false)
        toast.update(id, {
        render: Resp.data.Message,
        type: "error",
        isLoading: false,
        autoClose: 2500,
        });
    }
    }
    };

    return(
        <div
        className="modal fade primary_modal"
        id="burn_order_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel_order_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="cancel_order_modalLabel">
                Burn Token
              </h5>
              {!NFTDet ? (
                <>
                  <TailSpin
                    wrapperClass="reactloader mt-0 mb-3"
                    color="#00BFFF"
                    height={100}
                    width={70}
                  />
                </>
              ) : (
                <>
                  <div>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                      NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      You are about to <span className="text-danger">Burn</span> the NFT{" "}
                      <span className="text-danger">{NFTDet.tokenName}</span>
                    </p>
                    <p className="text-gray font_we_600 font_14">
                      You only own <span className="text-danger">{NFT.balance}</span> Quantity
                    </p>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="burn_closs"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body px-0 pt-0">
                      <form className="px-4 bid_form">
                        <label htmlFor="bid" className="text-center">
                        <input
                          type="number"
                          id="bid"
                          className="form-control text-center"
                          placeholder="Enter Quantity to Burn"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={(e)=>setNoofToken(e.target.value)}
                        />
                        </label>
                        {((NoofToken > NFT.balance)||(NoofToken < 1)) &&
                        <p className="text-gray font_we_600 font_14">
                            Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                        </p>
                        }
                        <div className="text-center mt-3">
                          <Button
                            className="create_btn btn-block"
                            disabled={!NoofToken || (NoofToken < 1) ||(NoofToken > NFT.balance)||BurnState}
                            onClick={() => {
                              BurnCall();
                            }}
                          >
                            {BurnState && (
                              <i
                                class="fa fa-spinner mr-3 spinner_icon"
                                aria-hidden="true"
                              ></i>
                            )}
                            Burn Token <i className='fas fa-fire pl-1'></i>
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={BurnState}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
})