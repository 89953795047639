import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Changepricemodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
    <div className="modal fade primary_modal" id="change_price_modal" tabIndex="-1" role="dialog" aria-labelledby="change_price_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="change_price_modalLabel">Change Price</h5>
       <div className="change_price_img_div">
       <img src={require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" />
       </div>
       <p className="text-gray font_we_600 font_14">You are about to change price for Ants Net for 0xb6631a76645...b7de</p>             
       
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body px-0 pt-0">
       <form className="px-4 bid_form">
     <label for="bid">Actual Price - 2.3B OPEN</label>
     <div className="input-group mb-2 input_grp_style_1">
   <input type="text" id="bid" className="form-control" placeholder="Enter new price" aria-label="bid" aria-describedby="basic-addon2" />
   <div className="input-group-append">
     <span className="input-group-text" id="basic-addon2">OPEN</span>
   </div>
 </div>
 <p className="form_note">Price must be less than the actual price</p>

 {/* <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
     <div className="mb-3 input_grp_style_1">
   <input type="text" id="qty" className="form-control" placeholder="1"  />
  
 </div> */}
          
                      
         <div className="row pb-3">
          <div className="col-12 col-sm-6">
          <p className="buy_desc_sm">Service fee</p>
          </div>
          <div className="col-12 col-sm-6 text-sm-right">
          <p className="buy_desc_sm_bold">0.3B OPEN</p>
          </div>
         </div> 
         <div className="row pb-3">
          <div className="col-12 col-sm-6">
          <p className="buy_desc_sm">You will get</p>
          </div>
          <div className="col-12 col-sm-6 text-sm-right">
          <p className="buy_desc_sm_bold">0.3B OPEN</p>
          </div>
         </div>           
                     
         <div className="text-center">
           <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Change Price</Button>
           <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

         </div>
       
       </form>
     </div>
   </div>
 </div>
</div>

    </div>
  );
}
