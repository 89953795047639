import React from 'react'
import isEmpty from 'lib/isEmpty'
import config from 'lib/config'
import { NFTnameValidation } from 'actions/v1/token'
import $ from 'jquery'
import NumisEmpty from 'lib/NumIsEmpty'

export const NFTValidation = async(valdata,Onsale,Saletype) =>{
    //console.log("validateion dta ",valdata,Onsale,Saletype)
    var data =  valdata;
// check.forEach((e) => (e in Formdata ? delete Formdata[e] : false));
    var validationerr={}
    var DBnamecheck = null
    if(Saletype == "Auction"){
        delete data.TokenPrice;
        //console.log("Auction validation i/p data ",data)
    }else if(Saletype == "Fixed"){
        delete data.MinimumBid;
        delete data.AuctionEnd;
        delete data.AuctionStart;
        //console.log("Fixed validation i/p data ",data)
    }else{
        delete data.TokenPrice;
        delete data.MinimumBid;
        delete data.AuctionEnd;
        delete data.AuctionStart;
        //console.log("Auction validation i/p data ",data)
    }
    var arr = ['name','NFTCategory','royalties','TokenPrice']
    for (const property in data) {
        if(arr.includes(property) && isEmpty(data[property]))
        {
            validationerr[property] = `${property} validation failed`
        }
        if(property === 'File' && data[property] === null)
                validationerr[property] = `${property} is Required`
        if(property === 'royalties')
        {
            if(data[property] <= 0 || data[property] > 20)
                validationerr.royalties = "Royalty Must be between 0 to 20"
            else if(data[property].includes('.') || isNaN(data[property]))
                validationerr.royalties = "Royalty Must be a Decimal (or) whole value"
        }
        if(Onsale)
            //  if((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))

            //     if(data[property] < 0.0001 || data[property] >= 1e12)
            //        validationerr[property] = `${property} Must be between 0.0001 to 10^12`
        // if(Saletype !== true)
        // if(Saletype === true)
            if(Saletype === 'Fixed' && (!data.TokenPrice || isNaN(data.TokenPrice)) )
                validationerr.TokenPrice = "Price cannot be Empty"
            if(Saletype === 'Auction' && (!data.MinimumBid || isNaN(data.MinimumBid)))
                validationerr.MinimumBid = "Minimumbid cannot be Empty"
            if(Saletype === 'Auction' && (data.AuctionStart === undefined || data.AuctionEnd === undefined))
                validationerr.Auctiondates = "Start & End date cannot be Empty"
            if((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))
                if(data[property] < 0.0001 || data[property] >= 1e12)
                   validationerr[property] = `${property} Must be between 0.0001 to 10^12`
            if(Saletype === 'Auction' || Saletype === 'Fixed')
                if(data.Coinname === '')
                   validationerr.Coinname = "Pick Token Type"
    }
    //console.log("validationcheck",validationerr)
    if(isEmpty(validationerr))
    {
        DBnamecheck = await NFTnameValidation(data.name) 
        //console.log("jkbjbbfjnejknka",DBnamecheck)
        if(!(DBnamecheck.Success))
        {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else
        {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else
    {
        validationerr.Success = false
        return validationerr
    }
}

export const isEmail = (email) =>{
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
}

export const isCustomURL = (cusurl) =>{
    let nameRegex = /^[:]+(\s{0,1}[a-zA-Z-, ])*$/;
    var istrue = ((config.nameFormat).test(cusurl)) || 
                ((nameRegex).test(cusurl)) || 
                ((cusurl).includes('.')) || 
                ((cusurl).includes(' ')) || 
                ((cusurl).match(/^(?:.*[|,/,\\])/))
    return isEmpty(istrue)
}

export const UserValidation = (Formdata) =>{
    var valError = {}
    valError.Email = ((Formdata.Email) ? isEmail(Formdata.Email) : true)? true : "Enter Valid Email"
    valError.CustomUrl = ((Formdata.CustomUrl)? isCustomURL(Formdata.CustomUrl) : true)?true : "CustomUrl Cannot have Smiley, ' . ' , ' / ' or Spaces "
    valError.Name = ((Formdata.Name)? !((config.nameFormat).test(Formdata.Name)) : true)?true: "Smiley's are Not allowed"
    if(valError.Email === true && valError.CustomUrl === true && valError.Name === true)
        return true
    else
        return valError
}

export const ImageValidation = (file,Page) =>{
    if(Page === 'Edit')
        var validExtensions = ["png","gif","webp","jpeg","PNG","jpg","JPEG","JPG"];
    else
        var validExtensions = ["png","gif","webp","jpeg","mp4","PNG","jpg","JPEG","JPG"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1) 
        return {Success:false,Message:"Only these file types are accepted : " + validExtensions.join(", ")};
    if (30000000 < file.size)
        return {Success:false,Message:"File size exceeds 30 MB"};
    else
        return {Success:true}
}

export const PopupValidation = (Arg)=>{
    //console.log("arg for validatoon ",Arg)
    var Resp = null
    if((!Arg.TokenPrice) || (Arg.TokenPrice < 0.0001) || (Arg.TokenPrice > 1e12))
        Resp = {Success:false,Message:"Enter valid price between 0.0001 to 1e12"}
    if(Arg.type === 1155)
    {
        if((!Arg.NoofToken) || (Arg.NoofToken < 1) || (Arg.NoofToken > Arg.MaxToken))
            Resp = {Success:false,Message:"Enter valid Quantity between 1 to available"}
    }
    if(!Resp)
        Resp = {Success:true}
    return Resp
}

