import React, { useEffect ,useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import $ from 'jquery'
import   prof  from 'assets/images/small-profile.png'
import   masonary1  from 'assets/images/masonary_04.png'



//Functionality Imports
import emojiRegex from "emoji-regex";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { UserValidation, ImageValidation } from "actions/controller/validation";
import config from 'lib/config'
import isEmpty from "lib/isEmpty";
import { EditProfiledata, GetProfiledata } from 'actions/controller/userControl'
toast.configure();



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const history = useHistory()
  const { ...rest } = props;

  const initialForm = {
    Bio:"",
    CustomUrl:"",
    Twitter:"",
    Instagram:"",
    Facebook:"",
    Youtube:"",
    Email:"",
    Name:"",
    Site:"",
    reddit:""
  }

  const [Formdata, setFormdata] = useState({initialForm})
  const [valError, setvalError] = useState({})
  const emoji = emojiRegex()
  const [UpdateState,setUpdateState] = useState(false)
  const [Preview, setPreview] = useState('')

  useEffect(() =>{
    if(Wallet_Details.UserAccountAddr == ""){ 
        toast.warning("Please Connect Your Wallet")
        setPreview(require("../assets/images/profile_img.png"))
    }
    else
      GetProfile()
  },[Wallet_Details.UserAccountAddr])


  const GetProfile = async() =>{
    var Payload = {user:{curraddress:Wallet_Details.UserAccountAddr}}
    var Resp = await GetProfiledata(Payload)
    if(!isEmpty(Resp))
    {
    setFormdata(Resp)
    if(Resp.photo != "")
      setPreview(`${config.Back_URL}/Users/${Wallet_Details.UserAccountAddr}/profileImg/${Resp.photo}`)
    else  
    setPreview(require("../assets/images/profile_img.png"))
    }
  }

  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var Resp = ImageValidation(file,"Edit")
      if(Resp.Success){
        Formdata.File = file
        setPreview(URL.createObjectURL(file));
      }
      else
        toast.error(Resp.Message)
    }
  };

  const HandleChange = (e) =>{
		e.preventDefault();
		const { id, value } = e.target;
		let formData = { ...Formdata, ...{ [id]: value } }
		setFormdata(formData)
  }

  const Submit = async() =>{
    setUpdateState(true)
    Formdata.curraddress = Wallet_Details.UserAccountAddr
    var check = UserValidation(Formdata)
    if(check === true && Formdata.curaddress != "")
    {
        const id = toast.loading("Updating Data Please Wait")
        var resp = await EditProfiledata(Formdata)
        //console.log("Editprofiledetail",resp)
        if(resp.Success)
        {
        setTimeout(() => {
          toast.update(id, {render: resp.Message,type: "success",isLoading: false,autoClose: 2000});
        }, 500);

        setTimeout(()=>{
          history.push('/my-items')
        },2500)
      }
      else{
        setUpdateState(false)
        setTimeout(() => {
          toast.update(id, {render: resp.Message,type: "error",isLoading: false,autoClose: 2000});
        }, 500);
        $('html, body').animate({scrollTop:0}, 'slow');
      }
    }
    else{
        setUpdateState(false)
        setvalError(check)
        toast.error("Form validation failed",{autoClose:2500})
        $('html, body').animate({scrollTop:0}, 'slow');
    }
  }

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>
        <div className="container container_custom">
        <section className="page-head body-top pb-0">
        <div>
        <div className="row">
          <div className="col-12">
            <h2 className="page-heading">Edit Profile</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Edit Profile</a></li>
            </ul>
            </div>
            </div>
        </div>
    </section>
        </div>
        <div className="container container_custom mt-5 pb-5">
          <div className="row mt-5">
          <div className="col-lg-3 col-sm-12">
          <div class="holder">
                {/* <img src={require("../assets/images/profile_img.png")} alt="logo" id="imgPreview" className="img-fluid"/> */}
                <img src={Preview} alt="logo" id="imgPreview" className="img-fluid"/> 
              </div>
              <div className="profile_edit_panel">
                <div className="profile_edit_icon">
                  <i class="fas fa-pencil-alt"></i>
                </div>
                {/* <input type="file" name="photograph" id="photo" required="true" className="photo" /> */}
                <input type="file" onChange={(e) => Handlefile(e)}  name="photograph" id="photo" className="photo" />
              </div>
            </div>
            <div className="col-lg-9">
            <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Display Name</label>
                    <input type="text" onChange={(e)=>{HandleChange(e)}} value={Formdata.Name} className="form-control primary_inp" id="Name" placeholder="Enter your display name"/>
                    {valError !== true && valError.Name &&
                    <p className="Warning_label">{valError.Name}</p>
                    }
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Wallet Address</label>
                    <input type="text" disabled className="form-control primary_inp" id="name" defaultValue={Wallet_Details.UserAccountAddr}/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Custom URL</label>
                    <input type="text" onChange={(e)=>{HandleChange(e)}} value={Formdata.CustomUrl} className="form-control primary_inp" id="CustomUrl" defaultValue="e.g metaneum.ch/"/>
                    {valError !== true && valError.CustomUrl &&
                    <p className="Warning_label">{valError.CustomUrl}</p>
                    }
                  </div>
                  {/* <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Personal site or portfolio</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="http://"/>
                  </div> */}
                </div>

                <div className="form-row">
                 
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text" onChange={(e)=>{HandleChange(e)}} value={Formdata.Email} className="form-control primary_inp" id="Email" placeholder="Your email for marketplace notifications"/>
                    {valError !== true && valError.Email &&
                    <p className="Warning_label">{valError.Email}</p>
                    }
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Bio</label>
                    <input type="text"onChange={(e)=>{HandleChange(e)}} value={Formdata.Bio}  className="form-control primary_inp" id="Bio" placeholder="Tell about yourself in a few words"/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Personal site or portfolio</label>
                    <input type="text" className="form-control primary_inp" onChange={(e)=>{HandleChange(e)}} id="Site" value={Formdata.Site} placeholder="your own webite link"/>
                  </div>
                </div>
               

                {/* <div className="form-row"> */}
                 
                  {/* <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Verification</label>
                    <div className="d-flex align-items-start mt-2 flex_items_block">
                      <Button className="primary_btn w-100 small_btn">Get verified</Button>
                      <p className="text-muted mb-0 ml-sm-3 ml-md-0 ml-lg-3 mt-3 mt-sm-0 mt-md-3 mt-lg-0 text_desc_sm">Procceed with verification proccess to get more visibility and gain trust on Fayre Marketplace. Please allow up to several weeks for the process.</p>
                    </div>
                  </div> */}
                {/* </div> */}
                <div className="form-row">
                  <div className="form-group col-md-12 mb-0">
                    <label className="primary_label" htmlFor="name">Social Media Links</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control primary_inp" onChange={(e)=>{HandleChange(e)}} value={Formdata.Twitter}  id="Twitter" placeholder="Twitter account address"/>
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control primary_inp" onChange={(e)=>{HandleChange(e)}} value={Formdata.Youtube}  id="Youtube" placeholder="Youtube channel address"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control primary_inp"  onChange={(e)=>{HandleChange(e)}} value={Formdata.Facebook}  id="Facebook" placeholder="Facebook account address"/>
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control primary_inp"  onChange={(e)=>{HandleChange(e)}} value={Formdata.reddit}  id="reddit" placeholder="reddit account address"/>
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" className="form-control primary_inp" onChange={(e)=>{HandleChange(e)}} value={Formdata.Instagram} id="Instagram" placeholder="Instagram address"/>
                  </div>
                </div>
                <div className="mt-3">
                  <Button className="create_btn" disabled={UpdateState} onClick={()=>{Submit()}}>
                    Update Profile</Button>
                </div>
              </form>
              </div>
           
          </div>
       
        </div>
      </div>
      <Footer/>
    </div>
  );
}
