import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";
import {FaAngleDown} from "react-icons/fa";
import Modal from 'react-modal';

// @material-ui/icons
import { AccountBalanceWallet } from '@material-ui/icons';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import CreatorsCard from "./separate/itemcard.js";
import CreatorsCard from "./separate/ourcreatorscard.js";
import Itemcard from "./separate/itemcard.js";



import { v1_TopCreators  } from "actions/v1/token";
import { MyItemsData } from "actions/controller/tokenControl";
import { MyUserData } from "actions/controller/userControl";
import config from "lib/config";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import { Audio, TailSpin } from "react-loader-spinner";


import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  






const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Ourcreators(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    
  };

  var state = {
    nav: true,
    dot: true,
    responsive:{
      0: {
          items: 2,
      },
      575: {
        items: 4,
    },
      992: {
        items: 6,
    },
      1200: {
          items: 9,
      },
  },
  };

  const classes = useStyles();
  const { ...rest } = props;

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};


const [topCreators, settopCreators] = useState([]);
const [Days, setDays] = useState(10);
const [ ShowModal,setShowModal] = useState(false);
const [Loader, setLoader] = useState(false);
const [MyItemNFTs, setMyItemNFTs] = useState({});
const [TabName, setTabName] = useState();
const [Address, setAddress] = useState();
const [userName, setUserName] = useState();


const customStyles = {
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
    borderRadius: '30px',
    border: 'none !important',
  },
  overlay: {
   backgroundColor:'rgba(255,255,255,255)',
   zIndex:2
  },
};


useEffect(() => {
  TopCreators();
}, [Days]);




const TopCreators = async () => {
  var Payload = {
    Days: Days,
  };
  var v1_topCreators = await v1_TopCreators(Payload);
  if (v1_topCreators.data.Success) {
    //console.log("returned data ---",v1_topCreators)
    settopCreators(v1_topCreators.data.records);
    //console.log("returned data set in state ",topCreators)

    MyItemTab("created",v1_topCreators.data.records[0].curraddress,v1_topCreators.data.records[0].name)

  }
};


 

const MyItemTab = async (tab, addr,name, filter,Pageno) => {
  //console.log("addres df,jsadfjsadjf",tab,addr)
  setAddress(addr);
  setUserName(name);
  
  if(tab && !MyItemNFTs[tab])
    {
      setLoader(true);
      MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
      setMyItemNFTs(MyItemNFTs);
    }
  var curraddress = addr ? addr : addr;
  var Payload = {
    curraddress: curraddress,
    Tab: tab ? tab : "created",
    Action: "Click",
    Limit: 16,
    Page: Pageno || 1,
    filter: filter,
  };
  if (tab === "activity" || tab === "following" || tab === "followers") {
    // var myUser = await MyUserData(Payload);
    // if (myUser.Success) {
    //   // setTabName(Payload.Tab);
    //   var temp = MyUserFollow;
    //   temp[myUser.Tab] = myUser.records;
    //   // setMyUserFollow([]);
    //   // setMyUserFollow(temp);
    // } else {
    //   // setTabName(Payload.Tab);
    // }
  } else {
    var myNFT = await MyItemsData(Payload);
    if (myNFT.Success) {
      if(Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page)
      {
        MyItemNFTs[myNFT.Tab].page = Pageno
        MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
        MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
        setMyItemNFTs([]);
        setMyItemNFTs(MyItemNFTs);
        
      }
      else{
        if(MyItemNFTs[myNFT.Tab].page === 1){
          MyItemNFTs[myNFT.Tab].list = myNFT.records;
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
          setTimeout(() => {
            setShowModal(false)
          }, 1500);
        }
      }
      setTabName(Payload.Tab);
    } else {
      setTabName(Payload.Tab);
    }
  }
  setTimeout(() => {
    setLoader(false);
  }, 1000);
};






  return (
    <div>

    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
  
  
    <section className="page-head body-top">
       <div className="container container_custom">
        <h2 className="page-heading">Our Creators</h2>
        <ul className="breadcrums">
            <li><a href="#">Home</a></li>
            <li><a href="#"  className="active">Our Creator</a></li>
        </ul>
        
        </div>
    </section>


      <section  className="recentcoll pt-0">
           <div className="container container_custom">
             <div className="top-head">
                 <p className="heading_grey subhead mt-2 text-center mb-4">Top Creators</p>
                <p className="text-center banner-parg">
                    Top Creators of Metaneum NFT
                </p>

             </div>
             
             <div className="notable_sec carousel-nav masonry m-0 mt-4">
               
               <OwlCarousel items={topCreators.length}  
               className="owl-theme home-carousel"  
              //  loop={topCreators.length > 1} 
               center={topCreators.length === 1} nav={true} margin={20} autoplay ={false} 
               responsive={state.responsive} dots={false}>

               {topCreators&&
                  topCreators.length >0 &&
                      topCreators.map((Creator)=>{
                     
                        return(
                        <>
                     <div className="item">
                   
                        <div className="carousel-img">
                            <img src={Creator.image
                                    ? `${config.Back_URL}/Users/${Creator.curraddress}/profileImg/${Creator.image}`
                                    : require("../assets/images/user_img_1.png")}  alt="Collections" className="img-fluid"  onClick={() => {
                                      MyItemTab("created",Creator.curraddress,Creator.name);
                                    }}/>
                        </div> 
                        <div className="cont">
                            <p className="name">{Creator.name?Creator.name:Creator.curraddress.slice(0,5)}</p>
                            <p className="coinowned">{Creator.count} Creations</p>
                        </div>
                      
                                         </div></>)})}
                       </ OwlCarousel>
                     </div>
           </div>
      </section>

      <section className="popularcoll pt-0">
         <div className="container container_custom">
         <p className="heading_grey text-center subhead mt-2 mb-4">User Creations
         </p>
         
        
         </div>
         <p className="text-center" >Click the Top Creator to see their Creations</p>
          
         {/* <p4 className="page-heading">creations of {userName?userName:Address}</p4> */}
         <p className="heading_grey subhead mt-2 text-center mb-4">creations of {userName?userName:Address}</p>       
               

        
         {MyItemNFTs &&
            MyItemNFTs[TabName] &&
            MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {MyItemNFTs[TabName].list.map((NFT) => {
                  return (
                    <div className="col-md-6 col-lg-4 col-xl-3">
                    <Itemcard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                    </div>
                  );
                })}
              </div>
            ) : (
              // <BrowseMarketPlace />
              <></>
            )}
             {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
            <div className="loadmore mt-4">
                      <button className="theme-btn"  onClick={()=>MyItemTab(TabName, Address,userName, null,MyItemNFTs[TabName].page+1)} >Load more</button>
                    </div>
}
      </section>


      
    
    
      
      
        <div id="logo_overlay" className="logo_ovelay">
        
      <Footer />

    </div>
    </div>
    <Modal
          isOpen={ShowModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-body text-center">
          <div className="item_prof_img2">
            <img src={require("../assets/images/polygon.svg")} alt="Profile" className="img-fluid items_profile"/>
          </div>
          </div>
        </Modal>
    </div>
  );
}
