import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import Select from "react-select";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";


import masonary1 from 'assets/images/masonary_04.png'

import Calender from "../components/Calender/Calender";
import config from "../lib/config";
import { NFTValidation, ImageValidation } from "actions/controller/validation";
import MintingCard from "./separate/Minting";

import {getusers} from "../actions/v1/user.js";

import axios from "axios";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useSelector } from "react-redux";
toast.configure();



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const classes = useStyles();
  const { ...rest } = props;


  const [Preview, setPreview] = useState(
    require("../assets/images/notable_img_1.png")
  );

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const CreatePage = useLocation();


  const CreateMultiple = CreatePage.pathname === "/create-single";

  const InitialForm = {
    name: "",
    description: "",
    quantity: 1,
    properties: "",
    royalties: "",
    curraddress: Wallet_Details.UserAccountAddr,
    type: CreateMultiple ? 721 : 1155,
    contractaddress: CreateMultiple ? config.singleContract : config.multipleContract,
    NFTCategory: "",
    TokenPrice: "",
    Coinname: "",
    MinimumBid: ""
  };




  const [Formdata, setFormdata] = useState(InitialForm);
  const [NFTFilename, setNFTFilename] = useState(null);
  const [NFTFile, setNFTFile] = useState(null);
  const [PutonSale, setPutonSale] = useState(false);
  const [FixedPrice, setFixedPrice] = useState(false);
  const [Auction, setAuction] = useState(false);
  const [AucCalendar, setAucCalender] = useState("Start");
  const [PicStartselected, setPicStartselected] = useState(false);
  const [AuctionStart, setAuctionStart] = useState(null);
  const [AuctionEnd, setAuctionEnd] = useState(null);
  const [Unlockable, setUnlockable] = useState(false);
  const [bidTokenOptions, SetBidTokenOptions] = useState([])

  const [ValidationError, setValidationError] = useState(null)

  const [blockuser, setblockuser] = useState({});

  const [block, setblock] = useState(false);

  useEffect(() => {
    //console.log("calender data", AuctionEnd, AuctionEnd)

  }, [AuctionStart, AuctionEnd,])

  useEffect(() => {
    if(Wallet_Details.UserAccountAddr){
      console.log("user address check sdfsdf ",Wallet_Details.UserAccountAddr);
      getAllUsers();}

  }, [Wallet_Details.UserAccountAddr])


  useEffect(() => {

    //console.log("curradderss --- ", Wallet_Details.UserAccountAddr)
    //console.log("category collection ---", Wallet_Details.category);
    //console.log("tokenlist in useeffedt --- ", Wallet_Details.TokenList)
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    if (Wallet_Details.TokenList.length > 0) {
      //console.log("wallet dtails toke list ", Wallet_Details.TokenList)
      var toklist = Wallet_Details.TokenList
      var bidTokenArr = toklist.filter((token) => token.name != "BNB")
      //console.log("array of tokens ", bidTokenArr)
      SetBidTokenOptions(bidTokenArr)
    }
  }, [Wallet_Details.TokenList])



  const ChildReply = (ChildData, Calstate) => {
   
    if (Calstate === "Start") {
      setAuctionStart(ChildData)
      Formdata.AuctionStart = ChildData;
    }
    else {
      setAuctionEnd(ChildData);
      Formdata.AuctionEnd = ChildData;
    }
  };

  const CalAction = (a, day) => {
    var myDate = new Date();
    var newdat = myDate.setDate(myDate.getDate() + parseInt(day));
    var newdate = new Date(newdat)
    if (a === "now") {
      setAuctionStart(newdate);
      Formdata.AuctionStart = newdate;
    } else {
      setAuctionEnd(newdate);
      Formdata.AuctionEnd = newdate;
    }
  };

  const OnChangehandle = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let Form = Formdata;
    Form = { ...Form, ...{ [id]: value } };
    setFormdata(Form);
    Form.curraddress = Wallet_Details.UserAccountAddr
    //console.log("form datas ---", Formdata)
  };



  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var fileName = file.name.split(".")[0];
      //console.log("handlefile file name ---", file, fileName)
      setNFTFilename(fileName);
      Formdata.ImageName = fileName;
      var Resp = ImageValidation(file)
      if (Resp.Success) {
      // console.log("url ",file);
        setNFTFile(file);
         setPreview(URL.createObjectURL(file));
       
      }
      else
        toast.error(Resp.Message)
    }
  };


  const getAllUsers= async()=>{
  
      var resp = await getusers();
      if(resp){
        console.log("user add sfsf", Wallet_Details.UserAccountAddr);
      var filtereduser =  resp.filter((item)=>item.curraddress == Wallet_Details.UserAccountAddr)
      var blockstatus = filtereduser[0].blocked
      console.log("fsffdsdf user ",filtereduser);
      setblockuser(filtereduser[0]);
      }
  }

  const Validate = async () => {


    var blockdata = blockuser.blocked;
    console.log("block data status ",blockdata);
    if(blockdata){
      setblock(blockdata)
      return toast.error("invalid user")
    }else{

    const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
    //var SaleType = (PutonSale)?(FixedPrice)?"Fixed":(Auction)?"Auction":"Unlimited":PutonSale
    var SaleType = (FixedPrice) ? "Fixed" : (Auction) ? "Auction" : "Unlimited"
    Formdata.File = NFTFile
    //console.log(Formdata)
    var result = await NFTValidation(Formdata, PutonSale, SaleType)
    Formdata.PutonSale = PutonSale
    Formdata.SaleType = SaleType
    //Formdata.Coinname = Coinname
    setTimeout(() => {
      if (result.Success) {
        toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500 });
        setValidationError(result)
        $('#MintcardClick').trigger("click");
      }
      else {
        toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });
        setValidationError(result)
      }
    }, 1000);
    }
  }


  const options = [
    { value: 'photo', label: 'photo' },
    { value: 'art', label: 'art' },
  ];

  const options1 = [
    { value: 'ETH', label: 'ETH' },
    { value: 'CAKE', label: 'CAKE' },
  ];

  const options2 = [
    { value: 'Right after listing', label: 'Right after listing' },
    { value: '1 day', label: '1 day' },
  ];

  const options3 = [
    { value: '1 day', label: '1 day' },
    { value: '2 days', label: '2 days' },
  ];
  const handleChange = () => {
    // this.setState({ selectedOption });
    // //console.log(`Option selected:`, selectedOption);
  };


  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };
  return (
    <div className="home_header create-single-page">
     
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <section className="page-head body-top">
        <div className="container container_custom">
          <h2 className="page-heading">Create Item</h2>
          <ul className="breadcrums">
            <li><a href="#">Home</a></li>
            <li><a href="#" className="active">Create Item</a></li>
          </ul>

        </div>
      </section>
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>

        <div className="container container_custom">
          <div className="row">
            <div className="col-lg-4 col-xl-3 col-sm-12">
              <label className="heading_sm_blk_new">Preview</label>
              <div className="single_collectible  mx-0">
                <div className="grid-card">
                  <div className="showcard-img">
                    {/* <img src={require("../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" /> */}
                    {NFTFilename &&
                      NFTFilename.split(".").pop() == "mp4" ? (
                      <video
                        src={Preview}
                        alt="collections"
                        autoPlay={true}
                        muted={true}
                        controls
                      > <source src={Preview}  type="video/mp4" />
                      </video>
                    ) :
                      <img src={Preview} alt="Collections" className="img-fluid" />}
                    <div class="likes-count"><span class="fa fa-heart mr-1">
                    </span>350
                    </div>
                    <div className="status">
                      <p className="yellow">Coming soon</p>
                    </div>
                  </div>
                  <div className="showcard-det">
                  
                    <h3 className="pro-name">{Formdata.name}</h3>
                    <div class="d-flex mb-3">
                      <div class="carousel-img mr-2">
                        {NFTFilename &&
                          NFTFilename.split(".").pop() == "mp4" ? (
                          <video
                            src={Preview}
                            alt="collections"
                            autoPlay={true}
                            muted={true}
                            controls
                          />
                        ) :
                          <img src={Preview} alt="Collections" className="img-fluid" />}
                      </div>
                      <div class="profdata">
                        <p class="name mb-2 mt-0"><span> Owned by</span></p>
                        <p>{(Formdata.curraddress).slice(0, 5)}...</p>
                      </div>
                      <div class="profdata ml-auto">
                        <p class="name mb-2 mt-0"><span>{FixedPrice ? "price" : "Current Bid"}</span></p>
                        {FixedPrice ? <p>{Formdata.TokenPrice}{Formdata.Coinname}</p> : <p>{Formdata.MinimumBid}{Formdata.Coinname}</p>}

                      </div>
                    </div>
                    <div className="placebid d-flex justify-content-between align-items-center">
                      <button className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> Place bid</button>
                      <a href="#" className=""><span className="fa fa-recycle"></span> View History</a>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-lg-8 col-xl-9 mt-2">
              <form className="formCls pb-5">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="inputEmail4">Upload</label>
                    <div className="up-boder loadmore">

                      <div className="d-flex justify-content-between align-items-center">
                        <div>

                          <p className="form_note mb-0">PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</p>


                        </div>

                        <div className="file_btn btn theme-btn">Upload
                          <input className="inp_file " type="file" name="file" onChange={(e) => Handlefile(e)} />
                        </div>

                      </div>
                    </div>
                  </div>
                  {ValidationError && ValidationError.File &&
                    <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                  }
                  <div className="form-group col-md-12">
                    <div className="">
                      {/* <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put On Sale</label>
                        <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          onChange={()=>{
                            setPutonSale((prevState) => !prevState);
                            setFixedPrice(false);
                            setAuction(false);
                          }}
                        />
                        <span className="slider"></span>
                      </label>
                      {//console.log("put on sale state ---",PutonSale)}
                      </div> */}

                      <div className="connect_row mt-3 putonsale_sec connect_row_blk justify-content-between"

                      >
                        {/* <div className="create_box create_sing_bx card_user_bg_1 d-flex active"  data-toggle="modal" data-target="#choose_collection_modal" > */}
                        <div className={(FixedPrice) ? "create_box create_sing_bx card_user_bg_1 d-flex active" : "create_box create_sing_bx card_user_bg_2 d-flex mx-sm-1"}   >



                          <p
                            onClick={
                              () => setFixedPrice(
                                (prevState) => !prevState,
                                setAuction(false),
                                setPutonSale(true),
                                //setCoinname(config.currencySymbol)
                                //console.log("setfixed price state--", FixedPrice, Auction)
                              )
                            }
                          > <span className="fa fa-tag mr-2"></span>Fixed</p>
                        </div>
                        {CreateMultiple && (
                          <div className={(Auction) ? "create_box create_sing_bx card_user_bg_1 d-flex active" : "create_box create_sing_bx card_user_bg_2 d-flex mx-sm-1"} >


                            <p onClick={() =>
                              setAuction(
                                (prevState) => !prevState,
                                setFixedPrice(false),
                                setPutonSale(true),
                                //setCoinname(config.tokenSymbol)
                                //console.log("fixed and auction states ---- ", FixedPrice, Auction)
                              )
                            }> <span className="fa fa-clock mr-2"></span>Timed Auctions</p>
                          </div>)}
                        <div className={(!FixedPrice && !Auction && PutonSale) ? "create_box create_sing_bx card_user_bg_1 d-flex active" : "create_box create_sing_bx card_user_bg_2 d-flex"} onClick={() => {
                          setFixedPrice(false);
                          setAuction(false);
                          setPutonSale(true);
                        }}>


                          <p>  <span className="fa fa-users mr-2"></span>Unlimited Auction</p>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12 mb-0">


                    {FixedPrice ?
                      <div className="row mx-0 mt-3 fixed_price_sec">

                        <div className="form-row w-100">

                          <div className="col-12 mb-3">
                            <label className="primary_label" htmlFor="price_new">Price</label>

                            <div class="input-group mb-1">
                              <input type="text" id="TokenPrice" class="form-control selct_form_input_h mr-2" onChange={(e) => OnChangehandle(e)} placeholder="Enter Price" aria-label="Recipient's username" aria-describedby="basic-addon3" />
                              <div class="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"

                                  id="tokenCategory"
                                  name="tokenCategory"
                                  // onChange={handleChange}
                                  // options={options1}
                                  onChange={(e) => Formdata.Coinname = e.name}
                                  options={Wallet_Details.TokenList}
                                  label="Single select"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                />
                              </div>

                              {ValidationError && ValidationError.Coinname &&
                                <p className="Warning_label">{ValidationError && ValidationError.Coinname}</p>
                              }
                            </div>
                            {ValidationError && ValidationError.TokenPrice &&
                              <p className="Warning_label">{ValidationError && ValidationError.TokenPrice}</p>
                            }
                            <p className="buy_desc_sm">Service fee {Wallet_Details.Service_Fee.Seller} %</p>

                          </div>

                          {/* <div className="form-group col-md-12">
                  <div className="input-group input_grp_style_1">
           
                    <input type="text" id="bid" className="form-control" placeholder="Enter Price for one time" aria-label="bid" aria-describedby="basic-addon2" />
                    <div class="input-group-append">
                    <Select
                        className="yes1 form-control primary_inp select1 selxet_app"

                        id="tokenCategory"
                        name="tokenCategory"
                        // onChange={handleChange}
                        // options={options1}
                        onChange={(e)=>Formdata.TokenName = e.name}
                        options={Wallet_Details.TokenList}
                        label="Single select"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                </div>
                  
                    </div>
                  </div> */}
                        </div>
                        {/* <p className="form_note">Service fee <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee}</span><br/> You will receive <span className="font_we_700_note_txt">0B OPEN</span></p> */}

                      </div> : <></>}
                    {Auction && (
                      <div className="row mt-3 timed_sec">

                        <div className="col-12 mb-3">
                          <label className="primary_label" htmlFor="price_new">Minimum bid</label>

                          <div class="input-group mb-1">
                            <input type="text" id="MinimumBid" class="form-control selct_form_input_h mr-2" onChange={(e) => OnChangehandle(e)} placeholder="Enter minimum bid" aria-label="Recipient's username" aria-describedby="basic-addon3" />
                            <div class="input-group-append">

                              <Select
                                className="yes1 form-control primary_inp select1 selxet_app"

                                id="tokenCategory"
                                name="tokenCategory"
                                // onChange={handleChange}
                                // options={options1}
                                onChange={(e) => Formdata.Coinname = e.name}
                                options={(FixedPrice) ? Wallet_Details.TokenList : bidTokenOptions}
                                label="Single select"
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </div>
                            {ValidationError && ValidationError.Coinname &&
                              <p className="Warning_label">{ValidationError && ValidationError.Coinname}</p>
                            }
                          </div>
                          <p className="form_note">Bids below this amount won't be allowed.</p>
                          {ValidationError && ValidationError.MinimumBid &&
                            <p className="Warning_label">{ValidationError && ValidationError.MinimumBid}</p>
                          }
                          <p className="buy_desc_sm">Service fee {Wallet_Details.Service_Fee.Seller} %</p>
                        </div>


                        <div className="col-12 col-lg-6  mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Starting Date</label>
                            <div class="dropdown">
                              <button class="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}<i class="fas fa-chevron-down"></i>
                              </button>
                              <div class="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div onClick={() => { setPicStartselected(false); CalAction("now", 0) }}>Right after listing</div>
                                <div data-toggle="modal" data-target="#calendar_modal" onClick={() => { setAucCalender("Start"); setPicStartselected(true) }}
                                >Pick specific date</div>

                              </div>
                            </div>
                          </div>
                          {ValidationError && ValidationError.Auctiondates &&
                            <p className="Warning_label">{ValidationError && ValidationError.Auctiondates}</p>
                          }
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                            <div class="dropdown">
                              <button class="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}<i class="fas fa-chevron-down"></i>
                              </button>
                              <div class="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                {!PicStartselected && <>
                                  <div onClick={() => CalAction("end", 1)}>
                                    1 day
                                  </div>
                                  <div onClick={() => CalAction("end", 2)}>
                                    2 days
                                  </div></>
                                }
                                <div data-toggle="modal" data-target="#calendar_modal" onClick={() => setAucCalender("End")}>Pick specific date</div>

                              </div>
                            </div>
                          </div>
                          {ValidationError && ValidationError.Auctiondates &&
                            <p className="Warning_label">{ValidationError && ValidationError.Auctiondates}</p>
                          }
                        </div>
                        <div className="col-12">
                          <p className="form_note">Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>
                          <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                        </div>

                      </div>)}


                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" onChange={() =>
                          setUnlockable((prevState) => !prevState)
                        } />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {Unlockable && (
                      <div className="form-group unlock_sec ">
                        <input type="text" className="form-control primary_inp mb-1" id="digitalkey"
                          onChange={(e) => {
                            Formdata.UnlockContent = e.target.value;
                          }} placeholder="Digital key, code to redeem or link to a file.." />
                        <p class="form_note">Tip: Markdown syntax is supported</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text" className="form-control primary_inp" id="name"
                      onChange={(e) => OnChangehandle(e)} placeholder="Item" />
                    {/* <p class="error_text">Name is required</p> */}
                    {ValidationError && ValidationError.name &&
                      <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                    }
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Description</label>
                    <input type="text" className="form-control primary_inp" id="description"
                      onChange={(e) => OnChangehandle(e)} placeholder="e.g. After purchasing..." />
                    {/* <p class="error_text">Description is required</p> */}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text" className="form-control primary_inp" id="royalties" onChange={(e) => OnChangehandle(e)} placeholder="e.g:1,5,20 ..." />
                      <p className="inp_append">%</p>

                    </div>
                    {ValidationError && ValidationError.royalties &&
                      <p className="Warning_label">{ValidationError && ValidationError.royalties}</p>
                    }
                    {/* <p class="error_text">Royalties is required</p> */}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="primary_label" htmlFor="properties">Properties</label>
                    <input type="text" className="form-control primary_inp" id="properties"
                      onChange={(e) => OnChangehandle(e)} placeholder="e.g. size" />
                    {/* <p class="error_text">Properties is required</p> */}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="primary_label" htmlFor="category">Category</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"

                      id="NFTCategory"
                      name="tokenCategory"
                      // onChange={handleChange}
                      // options={options}
                      onChange={(e) => Formdata.NFTCategory = e.name}
                      options={Wallet_Details.Category}
                      label="Single select"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {ValidationError && ValidationError.NFTCategory &&
                      <p className="Warning_label">{ValidationError && ValidationError.NFTCategory}</p>
                    }
                    {/* <input type="text" className="form-control primary_inp" id="category" placeholder="e.g. catogory"/> */}
                    {/* <p class="error_text">Properties is required</p> */}

                  </div>
                  {!CreateMultiple &&
                    <div className="form-group col-md-4">
                      <label className="primary_label" htmlFor="properties">Quantity</label>
                      <input type="Number" className="form-control primary_inp" id="quantity"
                        onChange={(e) => OnChangehandle(e)} placeholder="e.g. 2,5 ..." />
                      {/* <p class="error_text">Properties is required</p> */}
                    </div>}
                </div>

                <div>
                  {/* <Button className="create_btn">Create item</Button> */}
                  {/* <Button className="create_btn d-none" id="createitempopup" data-toggle="modal" data-target="#create_item_modal">Create item</Button> */}
                  {(Wallet_Details.UserAccountAddr !== "") ?
                    <Button
                      className="create_btn"
                      onClick={() => Validate()}
                    >
                      Create item
                    </Button>
                    :
                    <Link to="/connect-wallet">
                      <Button className="create_btn">Connect Wallet</Button>
                    </Link>
                  }
                </div>
              </form>
            </div>

          </div>
        </div>
        <button id="MintcardClick" className="d-none" data-target="#create_item_modal" data-toggle="modal"></button>
      </div>
      <MintingCard NFTDetails={Formdata} />
      <Calender CalenderState={AucCalendar} ParentCall={ChildReply} />
      <Footer />
      {/* Choose Collection Modal */}
      <div class="modal fade primary_modal" id="choose_collection_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex flex_block_mob">
                <div class="hex center">
                  <div class="hex__shape">
                    <div class="hex__shape">
                      <div class="hex__shape">
                        <img src={require("../assets/images/notable_img_1.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="metaneum.ch/" />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
      {/* create_item Modal */}
      <div class="modal fade primary_modal" id="create_item_modal" tabindex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text" >Approve</p>
                    <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload files & Mint token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block">Start</Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign sell order</p>
                    <p className="mt-0 approve_desc">Sign sell order using tour wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled>Start</Button>
                </div>

                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign lock order</p>
                    <p className="mt-0 approve_desc">Sign lock order using tour wallet</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>Start</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div class="modal fade primary_modal" id="learn_modal" tabindex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="learn_modalLabel">How timed auction work</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid or it is at least 2.3B OPEN higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      {/* <div class="modal fade primary_modal" id="calendar_modal" tabindex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="calendar_modalLabel">Choose date</h5>
       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">  
     <div className="pb-3">
                           
     <Datetime input={false} />
     </div>  
   </div>
 </div>
</div>
</div> */}
      {/* end calendar modal */}
    </div>
  );
}
