import axios from "axios";
import config from "../../lib/config";
//import Multiple from "../../ABI/userContract1155.json";
//import Single from "../../ABI/userContract721.json";
import { toast } from "react-toastify";
import isEmpty from "lib/isEmpty";
toast.configure();
let toasterOption = config.toasterOption;

export const NFTnameValidation = async (NFTname) =>{
  try{
    var respdata = await axios({
      method:"get",
      url: `${config.vUrl}/token/validation/namevalidate/${NFTname}`
    })
    return respdata.data
  }
  catch(e)
  {
    //console.log("Error in NFTnameValidation Function",e)
    return respdata.data
  }
}

export const v1_ipfsImageHashGet = async (formData) => {
  try {
   var respdata = await axios({
      method: "post",
      url: `${config.vUrl}/token/create/ipfsdatum`,
      data: formData,
      'headers': {
        'Authorization': localStorage.user_token
    },
    })
      return respdata.data
  } 
  catch (e) {
     //console.log("Error in ipfsImageHashGet Function",e)
      return respdata.data
  }
};

export const v1_NFTDetails2DB = async (formData) => {
  try {
    var respdata = await axios({
        method: "post",
        url: `${config.vUrl}/token/add/NFT`,
        data: formData,
        'headers': {
          'Authorization': localStorage.user_token
      },
      })
        return respdata.data
  } 
  catch (e) {
     //console.log("Error in NFTDetails2DB Function",e)
      return respdata.data
  }
};

export const ExplorePage = async (payload) => {
  //console.log("payload in ExplorePage ",payload)
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/explore/NFT`,
      params: payload
    });
    //console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("ErrorinExplorePageFunction",err)
  }
}

export const v1_MyItemsData = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/myitems/NFT`,
       params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("ErrorinMyitemsDataFunction",err)
  }
}

export const LikeAction = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/mylike/NFT`,
       data: payload,
       'headers': {
        'Authorization': localStorage.user_token
    },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("ErrorinLikeActionFunction",err)
  }
}

export const v1_NFTInfo = async (payload) =>{
  //console.log("ajax call i/p for nftinfo ",payload)
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/NFTInfo`,
      params: payload
    });
    //console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_NFTinfoFunction",err)
  }
}

export const v1_UpdateNFTOwners = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdateNFTOwners`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    //console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_UpdateNFTOwnersFunction",err)
  }
}

export const v1_UpdateNFTState = async (payload) =>{
  //console.log("v1_UpdateNFTState data ",payload)
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdateNFTState`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    //console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_UpdateNFTOwnersFunction",err)
  }
}

export const v1_BidAction2DB = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BidAction2DB`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_UpdateNFTOwnersFunction",err)
  }
}

export const v1_CancelBid = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/cancelBid`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_CancelBidFunction",err)
  }
}

export const v1_BurnToken = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/burntoken`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_BurnTokenFunction",err)
  }
}

export const v1_Search = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/SearchQuery`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_SearchBidFunction",err)
  }
}

export const v1_TopCreators = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/topCreators`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_TopCreatorsFunction",err)
  }
}

export const FollowingNFTs = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getfollowingNFTs`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_TopCreatorsFunction",err)
  }
}

export const v1_Featured = async () =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/Featured`,
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_FeaturedFunction",err)
  }
}

export const v1_ReportToken = async (Payload) =>{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/Report`,
      data: Payload,
      'headers': {
        'Authorization': localStorage.user_token
    },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_ReportTokenFunction",err)
  }
}


export const getTokenListItem = async () =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getTokenList`,
    });

    //console.log("list of token returned ---",resp.data.data)
    return {
      data: resp.data.data
    }
  }
  catch (err) {
      //console.log("error in getting token list",err)
  }
}

export const popularcoll = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/popularCollections`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log(" error in popularCollections",err)
  }
}


export const HelpSearch = async (payload) =>{
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/HelpSearch`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
      //console.log("Errorinv1_SearchBidFunction",err)
  }
}


export const ContactDrop = async () =>{
	try {
	  let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/token/ContactDrop`,
	  });
  
	  //console.log("list ofdgfhgthtghtdfghfd token returned ---",resp.data.Drop)
	  return {
		data: resp.data.Drop
	  }
	}
	catch (err) {
		//console.log("error in getting token list",err)
	}
  }

  export const sociallinksfunction = async (postdata) => {
    try {
      let resp = await axios({
        'method': 'get',
        'url'  :`${config.vUrl}/token/social/sociallinksfunction`,
       
       
      });
     
      return {
        data: resp.data
      }
    }
    catch (err) {
    }
  
  }