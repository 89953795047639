import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import Select from "react-select";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import   prof  from 'assets/images/small-profile.png'
import   masonary1  from 'assets/images/masonary_04.png'


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const options = [
    { value: 'WBNB', label: 'WBNB' },
  { value: 'BTC', label: 'BTC' },
  ];

  const options1 = [
    { value: 'WETH', label: 'WETH' },
  { value: 'BTC', label: 'BTC' },
  ];

  const options2 = [
    { value: 'Right after listing', label: 'Right after listing' },
  { value: '1 day', label: '1 day' },
  ];

  const options3 = [
    { value: '1 day', label: '1 day' },
  { value: '2 days', label: '2 days' },
  ];
  const handleChange =()=>{
    // this.setState({ selectedOption });
    // //console.log(`Option selected:`, selectedOption);
  };
  
  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};
  return (
    <div className="home_header">
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <p class="heading_big_blk">Create Multiple Collectible</p>
  
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
              <div className="item">
              <div className="card_main_iner">
                        <div className="card_inner_item item_multiple pos-reltv">
                         
                    <div className="item_inner_img">
                      <div className="like_div_round">
                      <i className="far fa-heart liked"></i>
                      </div>
                    <img src={require("../assets/images/my_items_1.png")}  alt="Collections" className="img-fluid" />
                    </div> 
                    <div className="img_desc_nft px-3 py-3">
                    <img src={require("../assets/images/logo_big_iocn.png")} alt="User" className="img-fluid profiel_img_pos" />
                    <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                    <div className="d-flex justify-content-between mt-2">
                                      <h2>Pet Dragon | Spirit image</h2>
                                      <div>
                        <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                      </div>
                                      {/* <div className="d-flex creators_details">
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      </div> */}

                                      </div>
                                  
                                      <div className="d-flex justify-content-between align-items-center">
                      <div>
                      <p className="like_count">5 likes</p>
                      </div>
                      <div className="timeleft">
                        <span> <i class="far fa-clock mr-1"></i></span>
                              <span>
                              <Countdown 
                                    date={Date.now()+100000000000}
                                  
                                        renderer={renderer}
                                    >
                                    </Countdown>
                                {/* 03h 56mm 06s left */}
                                </span>
                            </div>
                    </div>
                  
                  
                          </div>

                       
                 
                                    </div>
                  </div>
                      </div>
                     
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <form className="formCls pb-5">
                <div className="form-row">
                  <div className="form-group col-md-12">
                   <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note">PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</p>
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input className="inp_file" type="file" name="file"/>
                      </div>
                   </div>
                  </div>
                  <div className="form-group col-md-12">
                  <div className="">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Choose Collection</label>
                      </div>
                      <div className="connect_row mt-3 putonsale_sec connect_row_blk">
                        <div className="create_box create_sing_bx card_user_bg_1"  data-toggle="modal" data-target="#choose_collection_modal">
                        
                        <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />

                          <p>Create ERC-721</p>
                        </div>
                     
                        <div className="create_box create_sing_bx card_user_bg_2">
                        <img src={require("../assets/images/loader.png")} alt="Unlimited Auction" className="img-fluid" />

                          <p>OPEN</p>
                        </div>
                    </div>

                      
                    
                   </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                    <div className="connect_row mt-3 putonsale_sec connect_row_blk">
                        <div className="create_box create_sing_bx card_user_bg_1">
                         {/* <i class="fas fa-tag"></i> */}
                        <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />

                          <p>Fixed Price</p>
                        </div>
                      {/* <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx">
                        <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                          <p>Timed Auction</p>
                        </div>
                      </div> */}
                        <div className="create_box create_sing_bx card_user_bg_2">
                        <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                          <p>Unlimited Auction</p>
                        </div>
                      </div>


                    <div className="row mx-0 mt-3 fixed_price_sec">
                    <label className="primary_label" htmlFor="price_new">Price</label>

<div className="form-row w-100">
<div className="form-group col-md-6">
<div className="input-group input_grp_style_1">

<input type="text" id="bid" className="form-control" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" />
<div className="input-group-append">
<span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">USD</span>
</div>
</div>
</div>
<div className="form-group col-md-6">
<div className="input-group input_grp_style_1 min_h_45">

<input type="text" id="bid" className="form-control" value="0.01B" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" disabled/>
<div className="input-group-append">
<span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">OPEN</span>
</div>
</div>
</div>
</div>

{/* <div class="input-group mb-1">
<input type="text" id="price_new" class="form-control selct_form_input_h" placeholder="Enter price for one piece" aria-label="Recipient's username" aria-describedby="basic-addon2" />
<div class="input-group-append">
<Select
    className="yes1 form-control primary_inp select1 selxet_app"

    id="tokenCategory"
    name="tokenCategory"
    onChange={handleChange}
    options={options}
    label="Single select"
    formControlProps={{
      fullWidth: true
    }}
  />
</div>

</div> */}
<p className="form_note">Service fee <span className="font_we_700_note_txt">2.5%</span><br/> You will receive <span className="font_we_700_note_txt">0B OPEN</span></p>

                    </div>
                  


                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                    <div className="form-group unlock_sec d-none">
                    <input type="text" className="form-control primary_inp mb-1" id="digitalkey" placeholder="Digital key, code to redeem or link to a file.."/>
                    <p class="form_note">Tip: Markdown syntax is supported</p>
                  </div>
                  </div>
                </div>
                {/* <div className="form-row">
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Instant Sale Price</label>
                        <p className="form_note">Enter the price for which the item<br/> will be instantly sold</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="e.g. Redeemable"/>
                    {/* <p class="error_text">Name is required</p> */}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="e.g. After purchasing..."/>
                    {/* <p class="error_text">Description is required</p> */}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text" className="form-control primary_inp" id="royalties" placeholder="0.00"/>
                      <p className="inp_append">%</p>
                    </div>
                    {/* <p class="error_text">Royalties is required</p> */}
                  </div>
                  <div className="form-group col-md-6">
                  <label className="primary_label" htmlFor="category">Category</label>
                  <Select
                        className="yes1 form-control primary_inp select1 selxet_app"

                        id="tokenCategory"
                        name="tokenCategory"
                        onChange={handleChange}
                        options={options}
                        label="Single select"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    {/* <input type="text" className="form-control primary_inp" id="category" placeholder="e.g. catogory"/> */}
                    {/* <p class="error_text">Properties is required</p> */}
                  
                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="qty">Quantity</label>
                      <input type="text" className="form-control primary_inp" id="qty" placeholder="1"/>
                    {/* <p class="error_text">Royalties is required</p> */}
                  </div>
                <div className="form-group col-md-6">
                <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="properties" placeholder="e.g. size"/>
                    {/* <p class="error_text">Properties is required</p> */}
                  </div>
                </div>
                <div>
                  {/* <Button className="create_btn">Create item</Button> */}
              <Button className="create_btn" data-toggle="modal" data-target="#create_item_modal">Create item</Button>

                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
        {/* Choose Collection Modal */}
        <div class="modal fade primary_modal" id="choose_collection_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div class="hex center">
                  <div class="hex__shape">
                    <div class="hex__shape">
                      <div class="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file"/>
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name"/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection"/>                  
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="metaneum.ch/"/>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
       {/* create_item Modal */}
 <div class="modal fade primary_modal" id="create_item_modal" tabindex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="create_item_modalLabel">Follow Steps</h5>
       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">                         
       <form> 
       <div className="media approve_media">
       <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
       {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
       <div className="media-body">
         <p className="mt-0 approve_text">Approve</p> 
         <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="btn_outline_red btn-block">Done</Button>                
       </div>
       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Upload files & Mint token</p> 
         <p className="mt-0 approve_desc">Call contract method</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="create_btn btn-block">Start</Button>                
       </div>

       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Sign sell order</p> 
         <p className="mt-0 approve_desc">Sign sell order using tour wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="create_btn btn-block" disabled>Start</Button>                
       </div>

       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Sign lock order</p> 
         <p className="mt-0 approve_desc">Sign lock order using tour wallet</p>
       </div>
     </div>
       <div className="text-center mt-3">
       <Button className="create_btn btn-block" disabled>Start</Button>                
       </div>
       </form>
     </div>
   </div>
 </div>
</div>
{/* end create_item modal */}


  {/* learn Modal */}
  <div class="modal fade primary_modal" id="learn_modal" tabindex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="learn_modalLabel">How timed auction work</h5>
       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">                         
      <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

      <p>The bidder can only place a bid which satisfies the following conditions:</p>
      <ol>
        <li>
        It is at least minimum bid set for the auction
        </li>
        <li>
        It is at least 5% higher than the current highest bid or it is at least 2.3B OPEN higher than the current highest bid

        </li>
      </ol>

<p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

<p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

<p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>

    
   </div>
 </div>
</div>
</div>
{/* end learn modal */}

 {/* calendar Modal */}
 <div class="modal fade primary_modal" id="calendar_modal" tabindex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="calendar_modalLabel">Choose date</h5>
       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">  
     <div className="pb-3">
                           
     <Datetime input={false} />
     </div>  
   </div>
 </div>
</div>
</div>
{/* end calendar modal */}
    </div>
  );
}
