import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";
import {FaAngleDown} from "react-icons/fa";

// @material-ui/icons
import { AccountBalanceWallet } from '@material-ui/icons';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Slider from "react-slick";
import {contactform} from '../actions/controller/userControl';
import {ContactDrop} from '../actions/v1/token'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import Select from "react-select";
import isEmpty from '../lib/isEmpty'
import contactimg from "../assets/images/contact.png"
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useSelector } from "react-redux";
toast.configure();









const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Contactus(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    
  };


  const classes = useStyles();
  const { ...rest } = props;

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};



  const initData = {
    "name":"",
    "email":"",
    "dropmsg":"",
    "msg":""
  }
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [formData,setFormData] = useState(initData);
const [Drop , SetDrop] = useState();
  const [errors,setErrors] = useState({});


  const handlechange = (e)=>{
    e.preventDefault();
    const { id, value } = e.target;
    // //console.log("tfrgjkhnrfitjgh",e.target);
    let Form = formData;
    Form = { ...Form, ...{ [id]: value } };
    setFormData(Form);
    Form.curraddress = Wallet_Details.UserAccountAddr
    //console.log("form datas ---",formData)

  }

  const DropDown = async() =>{
   
    var TokenList = await ContactDrop()
    //console.log("rrtyjytrjytjytj",TokenList)
    var drop = TokenList.data
    //console.log("bgfbgfbgrbg",drop);
    var fill = drop.map((item)=>item.name)
    //console.log("fgbnkgkjbgnhgkfdjbn",fill); 
    
    SetDrop(drop)
  }

  const handleclick = async()=>{
   //console.log("formData ",formData)
   const resp = validate(formData)
   //console.log("returnde val errors ",resp)
   setErrors(resp)
   if(Object.keys(resp).length == 0){
     toast.success("validation success")
     var response = await contactform(formData);
     //console.log("response object ",response)
     if(response.Success){
      toast.success(" successfully submitted")
     }else{toast.error("submission failed")}
            
   }else{toast.error("Enter valid data")}

   
  }
   const isEmail = (email) =>{
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const validate = (data)=>{
    //console.log("validaton i/p data ",data)
    var vailderr = {}

    if(isEmpty(data.name)){vailderr.name = "Enter a name "}

    if(isEmpty(data.email)){vailderr.email = "Enter a email "}
    else if(!isEmail(data.email)){vailderr.email = "Enter a valid email "}

    if(isEmpty(data.msg)){vailderr.msg = "please fill data  "}

     if(isEmpty(data.curraddress)){vailderr.curraddress = "address not found"}

    //console.log("validation error in function ",vailderr)
    return vailderr;
  }


  useEffect(() => {
    DropDown()
 },[]);



  return (
    <div>
  
    <div className="home_header contact">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
  

       <section className="page-head body-top">
            <div className="container container_custom">
                <h2 className="page-heading">Contact</h2>
                <ul className="breadcrums">
                    <li><a href="#">Home</a></li>
                    <li><a href="#"  className="active">Contact</a></li>
                </ul>
            </div> 
     </section>
      <section className="contact pb-5">
         <div className="container container_custom">
             <div className="row align-items-center">
                <div className="col-md-6 d-none d-md-block text-center">
                    <img src={contactimg} className="img-fluid"/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <h2 className="page-heading text-capitalize">Drop up a message</h2>
                    <p className="banner-parg">Connect With Us</p>
                    <input type="text" id="name" placeholder="Full name" className="form-control" onChange={(e)=>{handlechange(e)}}/>
                    <p>{errors.name}</p>
                    <input type="text" id="email" placeholder="Your Email" className="form-control" onChange={(e)=>{handlechange(e)}}/>
                    <p>{errors.email}</p>
                    <div class="form-group">
                     
                      <Select class="form-control" id="dropmsg" 
                        placeholder="Pick an Option "
                        onChange={(e)=>formData.dropmsg = e.name}
                        options={Drop}
                        label="Single select"
                        formControlProps={{
                          fullWidth: true
                        }}
                        
                        >
                      </Select>
                      {/* <select class="form-control" id="exampleFormControlSelect1">
                        <option>Select subject</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select> */}
                    </div>
                    <textarea id="msg" className="form-control"  placeholder="Message" onChange={(e)=>{handlechange(e)}} >
                    </textarea>
                    <p>{errors.msg}</p>
                    <button className="create_btn w-full" onClick={()=>{handleclick()}}>Send message</button>
                </div>
             </div>
        </div>
      </section>



      
    
    
      
      
        <div id="logo_overlay" className="logo_ovelay">
        
      <Footer />

    </div>
    </div>
    </div>
  );
}
