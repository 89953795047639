import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";
import {FaAngleDown} from "react-icons/fa";

// @material-ui/icons
import { AccountBalanceWallet } from '@material-ui/icons';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Itemcard from "./separate/itemcard.js";
import Gridcard from "./separate/gridcard.js";


import Slider from "react-slick";


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";


import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import { ExplorePage } from "actions/v1/token";
import { connectAdvanced, useSelector } from "react-redux";
//import ExploreCard from "./separate/explore_card";
import config from "lib/config";
import isEmpty from "lib/isEmpty";
import { usePrevious } from "hooks/useMethods";
import { Audio, TailSpin } from "react-loader-spinner";
import { v1_TopCreators ,popularcoll } from "actions/v1/token";
import { v1_Featured } from "actions/v1/token";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-modal';

import Explore from './Explore';






const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Home(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    
  };

  var state = {
    nav: true,
    responsive:{
      0:{
        items:3,
      },
      425: {
          items: 3,
      },
      575: {
        items: 4,
    },
      992: {
        items: 6,
    },
      1200: {
          items: 9,
      },
  },
  };

  

  const classes = useStyles();
  const { ...rest } = props;

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};

  const [ExploreAuctionNFTs, setExploreAuctionNFTs] = useState([]);
  const [CatName, setCatName] = useState("All");
  const [ExploreTrendCat, setExploreTrendCat] = useState([]);
  const [FeaturenNFT, setFeaturenNFT] = useState([]);
  const [ExploreDrops, setExploreDrops] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [Days, setDays] = useState(10);
  const [topCreators, settopCreators] = useState([]);
  const [HomeCms,setHomeCms]=useState({})
  const [HomeMid,setHomeMid] = useState({})
  const [Footercms,setFootercms] = useState("")
  const [ ShowModal,setShowModal] = useState(false)
  const [ popular,setPopular] = useState([])

  useEffect(() => {
    TopCreators();
  }, [Days]);

  useEffect(() => {
    TrendCat();
  }, [CatName]);

  useEffect(() => {
    popCollection();
  }, [Days]);

  const TopCreators = async () => {
    var Payload = {
      Days: Days,
    };
    var v1_topCreators = await v1_TopCreators(Payload);
    if (v1_topCreators.data.Success) {
      console.log("returned data ---",v1_topCreators)
     
      settopCreators(v1_topCreators.data.records);
      //console.log("returned data set in state ",topCreators)
    }
  };

  const popCollection = async () => {
    var Payload = {
      Days: Days,
    };
    var popularcolls = await popularcoll(Payload);
    if (popularcolls.data.Success) {
      //console.log("returned data2 ---",popularcolls)
      //console.log("popular records ",popularcolls.data.records)
      setPopular(popularcolls.data.records);
      //console.log("returned data set in state ",popular)
    }
  };

  const TrendCat = async () => {
    var Payload = {
      curraddress: Wallet_Details.UserAccountAddr,
      Category: CatName,
      Page: 1,
      limit: 5,
      Card: "Cat",
    };
    var NFT = await ExplorePage(Payload);
    //console.log("djnkjfsknkjngjkt", NFT);
    if (NFT.data.Success) {
      setExploreTrendCat([]);
      setExploreTrendCat(NFT.data.records);
      if (ExploreTrendCat.length > 0) {
        setExploreTrendCat(ExploreTrendCat);
      }
    }
  };
  


  const Featured = async () => {
    var Resp = await v1_Featured();
    if (Resp.data && Resp.data.Success) {

      var filterhomw = (Resp.data.Homecms).filter((cms) => cms.question === 'home')
      var filtermid = (Resp.data.Homecms).filter((cms) => cms.question === "MidSec")
      filtermid[0].answer = JSON.parse(filtermid[0].answer)
      //console.log("gfjhgfjgfhfghsgh", filterhomw);
      setHomeCms(filterhomw[0])
      setHomeMid(filtermid[0])
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    }
    else { toast.warning("Error Detected Refresh Page"); }
  };

  useEffect(() => {
    setShowModal(true)
    Featured();
  }, []);




  return (
    <div>
 
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <section className="body-top banner_sec_bg">
          <div className="container container_custom">
            <div className="bannerimgsec pt-md-5">
             
              {isEmpty(HomeCms) ? <>
                  <h1 className="banner-head mt-5">
                    Blockchain Arts for <br />
                    Digital Assets<br />
                    Metaneum </h1>
                  <p className="banner-parg">
                    "Once 100% of The Artist Become NFT Artist and Start Buying, <br />
                    Selling and Creating NFTs, Nfts Will Become The Most Useful Digital <br />
                    Asset on The Internet."</p>
                </>
                  :
                  <div className="CMS">{ReactHtmlParser(HomeCms.answer)}</div>
                }
                <div>
                  <button className="create_btn  mr-3"><Link to="explore"><span className="fa fa-rocket mr-2"></span>Explore</Link></button>

                  <button className="create_btn whitebtn"><Link to="create"><span className="fa fa-copy mr-2"></span>Create</Link></button>
                </div>

                {/* <p className="mt-4"><a href="#" className="play_a" target="_blank">
                    <i className="fa fa-play-circle mr-2" aria-hidden="true"></i>Learn more about OpenSale</a></p> */}

              

            </div>
          </div>
        </section>


      <section  className="recentcoll pt-0 mt-5">
           <div className="container container_custom">
           
             <p className="heading_grey subhead mt-2 mb-4">Top Creators</p>

             <div className="notable_sec carousel-nav masonry m-0 mt-4">
               
               <OwlCarousel items={topCreators.length}  
               className="owl-theme home-carousel"  
              //  loop={topCreators.length > 1} 
               center={topCreators.length === 1} nav={true} margin={20} autoplay ={false} 
               responsive={state.responsive} dots={false}>

               {topCreators&&
                  topCreators.length >0 &&
                      topCreators.map((Creator)=>{
                    
                        return(
                        <>
                     <div className="item">
                     <Link
                        to={
                          Creator.customurl
                            ? `/my-items/user/${Creator.customurl}`
                            : `/my-items/${Creator.curraddress}`
                        }
                      >
                        <div className="carousel-img">
                            <img src={Creator.image
                                    ? `${config.Back_URL}/Users/${Creator.curraddress}/profileImg/${Creator.image}`
                                    : require("../assets/images/user_img_1.png")}  alt="Collections" className="img-fluid" />
                        </div> 
                        <div className="cont">
                            <p className="name">{Creator.name?Creator.name:Creator.curraddress.slice(0,5)}</p>
                            <p className="coinowned">{Creator.count} Creations</p>
                        </div>
                        </Link>
                                         </div></>)})}
                       </ OwlCarousel>
                     </div>
           </div>
      </section>

      <section className="popularcoll">
         <div className="container container_custom">
         <p className="heading_grey subhead explorebtn mt-2 mb-4">Popular Collection
         <Link to="/explore">Explore more</Link>
           {/* <a href="#">Explore more</a> */}
         </p>
           <div className="row justify-content-center">
             
                {popular && popular.length > 0 &&
                  popular.map((collection)=>{
                    
                    // console.log("indiv collection",collection.NFT)
                    
                    return(
                      collection.NFT.length >= 4 &&
                      <>
                     <div className="col-md-6 col-lg-4">
                    <Gridcard det={collection} />
                    </div>
                    </>)
                  })
                   
                  }
                
            </div>
        </div>
      </section>

      <section className="createnft pt-1">
          <div className="container container_custom">
            <p className="heading_grey subhead mt-2 mb-5">Create and Sell Your Nfts</p>
            <div className="row">
              {HomeMid && HomeMid.answer && HomeMid.answer.length > 0 ?
                HomeMid.answer.map((Midesec) => {
                  //console.log("djbfjksbnfjburng", Midesec)
                  return (
                    <div className="col-md-6 col-lg-3">

                      <div className="sell-steps">
                        {/* <i className="fa fa-folder stepicon purple" aria-hidden="true"></i> */}
                        <img
                          src={Midesec.Image}
                          className="img-fluid"
                        />

                        <h2>{Midesec.ImageTitle}</h2>
                        <p>{Midesec.Desc}</p>
                      </div>

                    </div>
                  )
                })
                :
                <>
                  <div className="col-md-6 col-lg-3">
                    <div className="sell-steps">
                      <i className="fa fa-folder stepicon purple" aria-hidden="true"></i>


                      <h2>Set Up Your Wallet</h2>
                      <p>Wallet that is functional for NFT purchasing. You may have a Coinbase
                        account at this point, but very few are actually set up to buy an NFT.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="sell-steps">
                      <i className="fa fa-th-large stepicon green" aria-hidden="true"></i>


                      <h2>Create your collection</h2>
                      <p>Setting up your NFT collection and creatinf NFTs on NFTs easy! This guide explains how to set up your first collection.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="sell-steps">
                      <i className="fa fa-file-image stepicon violet" aria-hidden="true"></i>



                      <h2>Add your NFTs</h2>
                      <p>Wallet that is functional for NFT purchasing you may have a Coinbase
                        account at this point, buttonvery few are actually set up to buy an NFT.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="sell-steps">
                      <i className="fa fa-bookmark stepicon red" aria-hidden="true"></i>


                      <h2>List them for sale</h2>
                      <p>Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!</p>
                    </div>
                  </div>
                </>
              }
            </div>

          </div>
        </section>





          <Explore/>

      
        <div id="logo_overlay" className="logo_ovelay">
        
      {/* <Footer /> */}

    </div>
    </div>
    </div>
  );
}
