import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import Itemcard from "./separate/itemcard.js";
//import Sharemodal from "./separate/sharemodal.js";

import {Report} from "./separate/reportmodal.js";
import Cancelordermodal from "./separate/cancelordermodal.js";
//import Placeordermodal from "./separate/placeordermodal.js";
import {PlaceandCancel} from "./separate/placeordermodal.js";
import { Share } from "./separate/ShareNow.js";
import Changepricemodal from "./separate/changepricemodal.js";
import Acceptmodal from "./separate/acceptmodal.js";
import Proceedbidmodal from "./separate/proceedbidmodal.js";
import Placebidmultiplemodal from "./separate/placebidmultiplemodal.js";
// import Placebidmodal from "./separate/placebidmodal.js";
import {BidActions} from "./separate/placebidmodal.js";
// BidActions
import Proceedmodal from "./separate/proceedmodal.js";
import { BurnToken } from "./separate/BurnToken";
import {PurchaseNow} from "./separate/buymodal.js";
import { toast, Flip } from "react-toastify";

import config from "lib/config";
import { NFTInfo } from "actions/controller/tokenControl";





import { Audio, TailSpin } from "react-loader-spinner";

import { v1_CancelBid } from "actions/v1/token";

import { v1_MyComment } from "actions/v1/user";
import Modal from "react-modal";

import Explore from './Explore';
import { ExplorePage } from "actions/v1/token";















import styles from "assets/jss/material-kit-react/views/landingPage.js";
// import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import Countdown from "react-countdown";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}




var state = {
  responsive:{
    0: {
        items: 1, nav: true,dots: false,
    },
    575: {
      items: 1, nav: true,dots: false,
  },
  768: {
    items: 2, nav: true,dots: false,
},
    992: {
      items: 3,
      dots: true,
      nav: true,
  },
    1200: {
        items: 4,
        dots: true,
        nav: true,
    },
},
};

export default function Info(props) {
  const classes = useStyles();
  const { ...rest } = props;
  function hideDetail()
  {
    document.getElementById("image_div").classList.toggle('expand_img');
    document.getElementById("img_des").classList.toggle('show_des');    
    document.getElementById("detai_div").classList.toggle('hide_detail');
    document.getElementById("arrow_icon").classList.toggle('fa-shrink');
    
  }

  function hideDetailowner()
  {
    document.getElementById("image_div_owner").classList.toggle('expand_img');
    document.getElementById("img_des_owner").classList.toggle('show_des');    
    document.getElementById("detai_div_owner").classList.toggle('hide_detail');
    document.getElementById("arrow_icon_owner").classList.toggle('fa-shrink');
  }

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days} : {hours} : {minutes} : {seconds}</span>;
    }
};

const Wallet_Details = useSelector((state) => state.wallet_connect_context);
const { conaddr, owneraddr, tokenID } = useParams();
//console.log("useparams data ",owneraddr,tokenID)
const history = useHistory();
const [ CatName, setCatName] = useState("All")
const [RateFilter, SetRateFilter] = useState(1);
const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });

useEffect(() => {
  setShowModal(true);
  NFTInfoDetails();
  //UrComment();
}, [Wallet_Details.UserAccountAddr, Wallet_Details.nftaction,tokenID]);

useEffect(()=>{

  ExploreCardNFT()
},[CatName,Wallet_Details.UserAccountAddr,RateFilter])

const ExploreCardNFT = async (Catpage) => {
  var Payload = {curraddress:Wallet_Details.UserAccountAddr,Category:CatName,Page:Catpage || 1,limit:16,ratefilter:RateFilter}
  var NFT = await ExplorePage(Payload);
  //console.log("nft data in info page ",NFT.data.records);
  
  if (NFT.data.Success) {

    if(NFT.data.records.length > 0)
    { //console.log("cpndition check ",ExploreCardNFTs[CatName].list.length,CatName === NFT.data.cat, ((Catpage?Catpage:ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page),ExploreCardNFTs[CatName].page)
      if(((ExploreCardNFTs[CatName].list.length !== 0) && (CatName === NFT.data.cat)  ))
      {
        ExploreCardNFTs[CatName].onmore = true
        ExploreCardNFTs[CatName].page = NFT.data.page
        if(!Catpage && RateFilter){
          ExploreCardNFTs[CatName].list = NFT.data.records  
        }else if(Catpage){
          ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT.data.records)
        }
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs);
      }
      else if(ExploreCardNFTs[CatName].list.length === 0)
      {
        ExploreCardNFTs[CatName].onmore = true
        ExploreCardNFTs[CatName].list = NFT.data.records
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs);
       }
    
    }
    if(NFT.data.records.length === 0)
    {   
        ExploreCardNFTs[CatName].onmore = false
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs)
    }
    // setTimeout(() => {
    //   setShowLoader(false)
    // }, 1500);
  }
  else{
    toast.error("Oops..! Error Detected Refresh Page")
  }
};




const [NFTImage, setNFTImage] = useState("");
const PurchaseNowRef = useRef();
const PlaceandCancelRef = useRef();
const BidActionsRef = useRef();
const BurnTokenRef = useRef();
const ReportRef = useRef();
const ShareNowRef = useRef();
const [ShowModal,setShowModal] = useState(false);
const [NFT, setNFT] = useState({
  AllBids: [],
  CurrentOwner: { CurrentOwner: {} },
  History: [],
  NFTCreator: {},
  NFTDetail: {},
  NFTOwners: [],
  Mybid: [],
});



const NFTInfoDetails = async () => {
  //console.log("nft payload data",Wallet_Details.UserAccountAddr,tokenID,owneraddr)
  var Payload = {
    curAddr: Wallet_Details.UserAccountAddr,
    tokenID: tokenID,
    queryaddress: owneraddr,
  };
  var Resp = await NFTInfo(Payload);
  //console.log("resp data test after bidding ",Resp)
  if (Resp.NFTOwners.length === 0) {
    toast.error("NFT Not Found", { autoClose: 2500, transition: Flip });
    history.push("/explore/All");
  }
  if (!Resp.CurrentOwner) {
    toast.error(`${(owneraddr).slice(0,12).concat('...')} does not own this NFT currently`, { autoClose: 3500, transition: Flip });
    history.push("/explore/All");
  }
  setNFT(Resp);
  if (Resp.NFTDetail.image == "")
    setNFTImage(`${config.IPFS_IMG}/${Resp.NFTDetail.ipfsimage}`);
  else
    setNFTImage(`${config.Back_URL}/nftImg/${Resp.NFTDetail.tokenCreator}/${Resp.NFTDetail.image}`);
  setTimeout(() => {
    setShowModal(false);
  }, 1500);
};

const CancelBid = async (Biddet) => {
  const id = toast.loading("Bid Cancelling On Progress");
  var CanBiddet = {
    Biddet: Biddet,
    ActBody: {
      tokenName: NFT.NFTDetail.tokenName,
    },
  };
  var Resp = await v1_CancelBid(CanBiddet);
  if (Resp.data.Success) {
    toast.update(id, {
      render: Resp.data.Message,
      type: "success",
      isLoading: false,
      autoClose: 2500,
    });
    setTimeout(() => {
      NFTInfoDetails();
    }, 2500);
  } else {
    toast.update(id, {
      render: Resp.data.Message,
      type: "error",
      isLoading: false,
      autoClose: 2500,
    });
  }
};


const timediff = (a) => {
  var date1 = new Date(Date.now());
  var date2 = new Date(a);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.ceil(Difference_In_Days);
};


const Ipfsimageerr = (e) =>{
  //console.log("jfndfkjgrjkntkgn",e.target)
  if(NFT.NFTDetail.ipfsimage)
      e.target.src = `${config.IPFS_IMG}/${NFT.NFTDetail.ipfsimage}`
}




  return (

    <div className="home_header item-details">
     
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
       <section className="page-head body-top">
        <div className="container container_custom">
            <h2 className="page-heading">Item Details</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Item Details</a></li>
            </ul>
            
        </div>
    </section>
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader info_header inner_pagh_bg_2 pt-0"}>
        <div className="container container_custom">
        {/* info row */}
      <div className="row info_row buyer_div mt-5">
        <div className="col-sm-12 col-lg-6" id="image_div">
         
          <div className="">
          {/* <div className="float-right arrow_expand" onClick={hideDetail}>
          <i className="fas fa-arrows-alt" aria-hidden="true" id="arrow_icon"></i>
          </div> */}
          <div className="img_info_rad">
          {NFT &&
                    NFT.NFTDetail &&
                    NFT.NFTDetail.image &&
                    (NFT.NFTDetail.image.split(".").pop() == "mp4" ? (
                      <video
                        id="my-video"
                        className="img-fluid"
                        autoPlay
                        playsInline
                        loop
                        muted
                        controls
                        preload="auto"
                        onError={(e)=>Ipfsimageerr(e)}
                      >
                        <source src={NFTImage}  type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={NFTImage}
                        onError={(e)=>Ipfsimageerr(e)}
                        alt="Collections"
                        className="img-fluid"
                      />
                    ))}
        {/* <img src={require("../assets/images/info_img.png")} alt="Collections" className="img-fluid" /> */}
        </div>
        <div className="img_des" id="img_des">
        <p className="info_title info_title_big">The Fantasy Flower</p>
        <h3 className="info_h3">by<span className="px-2">Aqueento</span>on<span className="pl-2">NFT</span></h3>

        </div>
        </div>
       
        </div>
        <div className="col-sm-12 col-lg-6 bg_pink mt-3 mt-lg-0" id="detai_div">
        <span className="info_title info_title_big">{NFT.NFTDetail.tokenName}</span>
          <div className="d-flex justify-content-between align-items-center mt-2">
            
              <div className="d-flex">
              {/* <div className="likes-count badge_grey_round"><span className="fa fa-eye mr-1"></span>225</div> */}
              <div className="likes-count badge_grey_round" ><span className="fa fa-heart mr-1"></span>{NFT.NFTDetail.likecount}</div>
              </div>
              <div className="d-flex">
               <button className="circlebtn" data-toggle="modal" data-target="#share_modal"
               onClick={() =>
                ShareNowRef.current.ShareSocial_Click(
                  window.location.href,
                  NFT.NFTDetail.tokenName,
                  "info"
                )
              }>
               <i className="far fa-paper-plane"></i>
                  </button>

                
                       <div className="dropdown circlebtn ml-2">
                            <button className="drop-down create_btn btn_rect btn_icon_only" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right menu_more_dd" aria-labelledby="dropdownMenuButton">
                              {/* <div className="dropdown-item">

                              <span data-toggle="modal" data-target="#accept_modal">Accept Bid</span>
                              </div> */}
                          {Wallet_Details.UserAccountAddr ===
                            NFT.CurrentOwner.CurrentOwner.curraddress && (
                    <>
                              {!(
                              new Date(NFT.NFTDetail.auctionstart).getTime() <
                                Date.now() &&
                              new Date(NFT.NFTDetail.auctionend).getTime() >
                                Date.now()
                              ) && (
                              <div className="dropdown-item">
                              <span data-toggle="modal" data-target="#change_price_modal"  onClick={() =>
                            PlaceandCancelRef.current.PlaceandCancelClick(
                              NFT.CurrentOwner,
                              NFTImage,
                              NFT.NFTDetail
                            )
                          }>Change Price</span>
                              </div>)}

                              <div className="dropdown-item">
                              <span data-toggle="modal" data-target="#burn_order_modal" onClick={() =>
                                  BurnTokenRef.current.BurnTokenClick(
                                    NFT.CurrentOwner,
                                    NFTImage,
                                    NFT.NFTDetail
                                  )
                                }>Burn Token</span>

                              </div>
                              </>)}

                              {/* <div className="dropdown-item">
                              <span data-toggle="modal" data-target="#place_order_modal">Place Order</span>

                              </div>
                              <div className="dropdown-item">
                              <span data-toggle="modal" data-target="#cancel_order_modal">Cancel Order</span>

                              </div> */}

                        {Wallet_Details.UserAccountAddr !==
                            NFT.CurrentOwner.CurrentOwner.curraddress && (

                              <div className="dropdown-item">
                              <span data-toggle="modal" data-target="#report_page_modal"
                               onClick={() =>
                                ReportRef.current.ReportClick(
                                  NFT.CurrentOwner,
                                  NFT.NFTDetail
                                )
                              }>Report</span>

                              </div>)}
                            

                            </div>
                          </div>
          </div>
          </div>
          <div className="d-flex flex_block_mob">
              <div className="grid-card w-full mr-2 p-2">
                   <div className="d-flex">
                   <Link to={(NFT.CurrentOwner.CurrentOwner.customurl)?`/my-items/user/${NFT.CurrentOwner.CurrentOwner.customurl}`:`/my-items/${NFT.CurrentOwner.CurrentOwner.curraddress}`}>
                      <div className="carousel-img mr-2">
                        
                          <img src={
                          NFT.CurrentOwner.CurrentOwner.image
                            ? `${config.Back_URL}/Users/${NFT.CurrentOwner.CurrentOwner.curraddress}/profileImg/${NFT.CurrentOwner.CurrentOwner.image}`
                            : require("../assets/images/notable_img_1.png")
                        }  alt="Collections" className="img-fluid" />
                      </div>
                      </Link>
                      <div className="profdata prof_center_flex">
                        <p className="name mb-2 mt-0"><span>Owned by</span></p>
                        <p className="mb-0">{NFT.CurrentOwner.CurrentOwner.name?
                                              NFT.CurrentOwner.CurrentOwner.name:NFT.CurrentOwner.CurrentOwner.curraddress&&
                                                (NFT.CurrentOwner.CurrentOwner.curraddress).slice(0,14).concat("....")}</p>
                      </div> 
                    </div>
              </div>
              <div className="grid-card w-full p-2">
              <div className="d-flex">
              <Link to={(NFT.NFTCreator.customurl)?`/my-items/user/${NFT.NFTCreator.customurl}`:`/my-items/${NFT.NFTCreator.curraddress}`}>
                      <div className="carousel-img mr-2">
                          <img src={
                                    NFT.NFTCreator.image
                                      ? `${config.Back_URL}/Users/${NFT.NFTCreator.curraddress}/profileImg/${NFT.NFTCreator.image}`
                                      : require("../assets/images/notable_img_1.png")
                                  }  alt="Collections" className="img-fluid" />
                      </div>
                      </Link>
                      <div className="profdata prof_center_flex">
                        <p className="name mb-2 mt-0"><span>Created by</span></p>
                        <p className="mb-0">{NFT.NFTCreator.name
                                                ? NFT.NFTCreator.name
                                                : NFT.NFTCreator.curraddress &&
                                                  NFT.NFTCreator.curraddress
                                                .slice(0, 25)
                                                .concat("...")}</p>
                      </div> 
                    </div>
              </div>
          </div>
          <p className="banner-parg font_14 mb-0">Token Description : {NFT.NFTDetail.tokenDesc}</p>

             <div className="d-flex flex_block_mob">
              <div className="grid-card w-full mr-2 border_rad_sm">
                   <div className="d-flex justify-content-between">
                      <div className="profdata">
                        <p className="name mt-0"><span>{NFT.CurrentOwner.Onsale?"price":(NFT.NFTDetail.auctionend)?"minimum bid":""}</span></p>
                      </div> 
                      <div className="profdata">
                        <p>{NFT.CurrentOwner.Onsale
                        ? `${NFT.CurrentOwner.tokenPrice} ${NFT.CurrentOwner.coinname}`
                        :(NFT.NFTDetail.auctionend)?NFT.NFTDetail.minimumBid:"open for bid"}</p>
                      </div> 
                  </div>
              </div>
              {NFT.NFTDetail.auctionend && 
                new Date(NFT.NFTDetail.auctionstart).getTime() <= Date.now() &&
                new Date(NFT.NFTDetail.auctionend).getTime() > Date.now() &&
              <div className="grid-card w-full mr-2 border_rad_sm">
                   <div className="d-flex justify-content-between">
                      <div className="profdata">
                        <p className="name mt-0"><span>Countdown</span></p>
                      </div> 
                      <div className="profdata">
                      <p>
                        <Countdown
                            // date={Date.now()+100000000000}
                            // autoStart={true}
                          
                            // renderer={renderer} 
                            date={new Date(NFT.NFTDetail.auctionend).getTime()}
                            renderer={renderer}
                          />
                          </p>
                      </div> 
                  </div>
              </div>}
    
          </div>
          {/* <Button className="create_btn mb-2 mt-3 w-full" data-toggle="modal" data-target="#place_bid_modal"><span className="fa fa-shopping-bag mr-2"></span> Place a Bid</Button> */}
          <div className="btn_div_info mt-5">
                  {Wallet_Details.UserAccountAddr ? (
                    <>
                      {NFT.CurrentOwner.tokenOwner !==
                      Wallet_Details.UserAccountAddr ? (
                        NFT.CurrentOwner.Onsale ? (
                          <Button
                            className="create_btn mb-2 mt-3 w-full"
                            data-toggle="modal"
                            data-target="#buy_modal"
                            onClick={() =>
                              PurchaseNowRef.current.PurchaseNowClick(
                                NFT.CurrentOwner,
                                NFT.NFTDetail.tokenName
                              )
                            }
                          >
                            Buy Now
                          </Button>
                        ) : (
                          <>
                            {NFT.Mybid && NFT.Mybid.length !== 0 ? (
                              <Button
                                className="create_btn mb-2 mt-3 w-full"
                                data-toggle="modal"
                                data-target="#place_bid_multiple_modal"
                                onClick={() =>
                                  BidActionsRef.current.PlaceandAcceptBidClick(
                                    NFT,
                                    NFT.NFTDetail
                                  )
                                }
                              >
                                Edit Bid
                              </Button>
                            ) : (
                              <>
                                {new Date(
                                  NFT.NFTDetail.auctionstart
                                ).getTime() > Date.now() ? (
                                  <Button
                                    className="create_btn mb-2 mt-3 w-full"
                                    data-toggle="modal"
                                    disabled
                                    data-target="#place_bid_multiple_modal"
                                  >{`Starts in ${timediff(
                                    NFT.NFTDetail.auctionstart
                                  )} Days`}</Button>
                                ) : (
                                  <Button
                                    className="create_btn mb-2 mt-3 w-full"
                                    data-toggle="modal"
                                    data-target="#place_bid_multiple_modal"
                                    onClick={() =>
                                      BidActionsRef.current.PlaceandAcceptBidClick(
                                        NFT,
                                        NFT.NFTDetail
                                      )
                                    }
                                  >
                                    Bid
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : NFT.CurrentOwner.Onsale ? (
                        <Button
                          className="create_btn mb-2 mt-3 w-full"
                          data-toggle="modal"
                          data-target="#cancel_order_modal"
                          onClick={() =>
                            PlaceandCancelRef.current.PlaceandCancelClick(
                              NFT.CurrentOwner,
                              NFTImage,
                              NFT.NFTDetail
                            )
                          }
                        >
                          Cancel Order
                        </Button>
                      ) : new Date(NFT.NFTDetail.auctionstart).getTime() <
                          Date.now() &&
                        new Date(NFT.NFTDetail.auctionend).getTime() >
                          Date.now() ? (
                        <Button
                          className="create_btn mb-2 mt-3 w-full"
                          data-toggle="modal"
                          disabled
                          data-target="#place_bid_multiple_modal"
                        >{`Auction Ends in ${timediff(
                          NFT.NFTDetail.auctionend
                        )} Days`}</Button>
                      ) : (
                        <Button
                          className="create_btn mb-2 mt-3 w-full"
                          data-toggle="modal"
                          data-target="#change_price_modal"
                          onClick={() =>
                            PlaceandCancelRef.current.PlaceandCancelClick(
                              NFT.CurrentOwner,
                              NFTImage,
                              NFT.NFTDetail
                            )
                          }
                        >
                          Put On Sale
                        </Button>
                      )}
                    </>
                  ) : (
                    <Link to="/connect-wallet">
                      <Button className="create_btn mb-2 mt-3 w-full">
                        Connect Wallet
                      </Button>
                    </Link>
                  )}
                </div>

        
      
       
          <div className="mt-3">
          <nav className="masonry_tab_nav items_tab_outer info_tab_ouert mb-3 pt-0">
            <div className="nav nav-tabs masonry_tab primary_tab items_tab d-block items_tab_new pb-0 pl-0" id="nav-tab" role="tablist">
              <a className="nav-link active" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false"><div className="tab_head p-2">Bid History</div></a>
              <a className="nav-link" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false"><div className="tab_head p-2">Info</div></a>
              <a className="nav-link" id="provenance-tab" data-toggle="tab" href="#provenance" role="tab" aria-controls="provenance" aria-selected="true"><div className="tab_head p-2">Provenance</div></a>
            </div>
          </nav>
          <div className="tab-content item-details-scroll explore_tab_content mt-4" id="nav-tabContent">

            <div className="tab-pane fade show active" id="history" role="tabpanel" aria-labelledby="history-tab">
              <div className="proposal_panel_overall">
                  <div className="">
                  {NFT.AllBids.length > 0 &&
                          NFT.AllBids.map((Bids, index) => {
                            return (

                      // <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders border_card_flex">
                      //   <div className="d-flex align-items-start flex_table_div">
                      //     <div className="d-flex">
                      //     <div className="tabimg mr-2 tab_image_tick">
                      //     <img src={require("../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" />
                      //   </div>
                      //     <div className="profdata ml-2">
                      //       <p className=" mb-2 mt-0"><span>Mason</span></p>
                      //       <p className="hours_des_prof">8 hours</p>
                      //     </div>
                      //     </div>
                      //     <div className="profdata ml-2">
                      //       <p className=" mb-2 text_blue_grey"><span>Bid Accepted</span></p>
                      //     </div>
                      //     <p>4.5 Eth</p>
                      // </div>
                      // </div>
                      <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                      <div className="card-body px-3">
                        <div className="media follow_media icon_img flex_mob_blck">
                        <Link
                            to={
                              !Bids.Bidderdet.customurl
                                ? `/my-items/${Bids.Bidderdet.curraddress}`
                                : `/my-items/user/${Bids.Bidderdet.customurl}`
                            }
                          >
                          <div className="icon-img-div">
                            <div className="img_media_new  mr-3">
                              <img
                                src={
                                  Bids.Bidderdet.image
                                    ? `${config.Back_URL}/Users/${Bids.Bidderdet.curraddress}/profileImg/${Bids.Bidderdet.image}`
                                    : require("../assets/images/notable_img_1.png")
                                }
                                alt="User"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </Link>
                          <div className="media-body flex_body flex_mob_blck">
                          <Link
                            to={
                              !Bids.Bidderdet.customurl
                                ? `/my-items/${Bids.Bidderdet.curraddress}`
                                : `/my-items/user/${Bids.Bidderdet.customurl}`
                            }
                          >
                            <div>
                              <p className="mb-2 media_num">
                                Placed a Bid on {Bids.pending}{" "}
                                edition Each for {Bids.tokenBidAmt}{" "}
                                {Bids.coinname}
                              </p>
                              <p className="media_num mb-0">
                                by @
                                <span className="media_text">
                                  {Bids.Bidderdet.name
                                    ? Bids.Bidderdet.name
                                    : Bids.Bidderdet.curraddress
                                        .slice(0, 15)
                                        .concat("...")}
                                </span>
                              </p>
                            </div>
                        </Link>
                            <>
                              {
                                <div className="d-flex">
                                  {Wallet_Details.UserAccountAddr ===
                                    NFT.CurrentOwner.CurrentOwner
                                      .curraddress &&
                                    (NFT.NFTDetail.auctionend
                                      ? new Date(
                                          NFT.NFTDetail.auctionend
                                        ).getTime() < Date.now()
                                      : true) && (
                                      <Button
                                        className="create_btn mb-2 mr-2"
                                        data-toggle="modal"
                                        data-target="#accept_modal"
                                        disabled={
                                          !Wallet_Details.UserAccountAddr
                                        }
                                        onClick={() =>
                                          BidActionsRef.current.PlaceandAcceptBidClick(
                                            NFT,
                                            NFT.NFTDetail,
                                            Bids
                                          )
                                        }
                                      >
                                        Accept
                                      </Button>
                                    )}
                                  {NFT.Mybid.length > 0 &&
                                    Wallet_Details.UserAccountAddr ===
                                      Bids.Bidderdet
                                        .curraddress && (
                                      <Button
                                        className="create_btn bg-white mb-2"
                                        onClick={() => {
                                          CancelBid(Bids);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                </div>
                              }
                            </>
                          </div>
                        </div>
                      </div>
                  </div>
                      
                      
                      
                      )})}
          
            
            </div>
            
          </div>
          </div>



          <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
          <div className="proposal_panel_overall">
                  <div className="">
                      <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders border_card_flex">
                        <div className="d-flex align-items-start flex_table_div">
                          <div className="d-flex">
                          <div className="tabimg mr-2 tab_image_tick">
                          <img src={
                                    NFT.NFTCreator.image
                                      ? `${config.Back_URL}/Users/${NFT.NFTCreator.curraddress}/profileImg/${NFT.NFTCreator.image}`
                                      : require("../assets/images/notable_img_1.png")
                                  } alt="Collections" className="img-fluid" />
                        </div>
                          <div className="profdata ml-2">
                            <p className=" mb-2 mt-0"><span>Creator</span></p>
                            <p className="hours_des_prof">{NFT.NFTCreator.name
                                                              ? NFT.NFTCreator.name
                                                              : NFT.NFTCreator.curraddress &&
                                                                NFT.NFTCreator.curraddress
                                                              .slice(0, 25)
                                                              .concat("...")}</p>
                          </div>
                          </div>
                        
                          <p>{NFT.NFTDetail.tokenRoyality}% Royalty to the creator</p>
                      </div>
                      </div>

                      {/* <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders border_card_flex">
                        <div className="d-flex align-items-start flex_table_div">
                          <div className="d-flex">
                          <div className="tabimg mr-2 tab_image_tick">
                          <img src={require("../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" />
                        </div>
                          <div className="profdata ml-2">
                            <p className=" mb-2 mt-0"><span>Creator</span></p>
                            <p className="hours_des_prof">Querebin John</p>
                          </div>
                          </div>
                        
                          <p>10% Sales to the creator</p>
                      </div>
                      </div>

                      <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders border_card_flex">
                        <div className="d-flex align-items-start flex_table_div">
                          <div className="d-flex">
                          <div className="tabimg mr-2">
                          <img src={require("../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" />
                        </div>
                          <div className="profdata ml-2">
                            <p className=" mb-2 mt-0"><span>Creator</span></p>
                            <p className="hours_des_prof">Querebin John</p>
                          </div>
                          </div>
                        
                          <p>10% Sales to the creator</p>
                      </div>
                      </div>
                   */}

                 
                   
            
            </div>
            
          </div>
          </div>
          <div className="tab-pane fade" id="provenance" role="tabpanel" aria-labelledby="provenance-tab">
         
          <div className="proposal_panel_overall">
                 
                 
                 <div className="">{
                     NFT.NFTOwners.length > 0 &&
                     NFT.NFTOwners.map((Owns) => {
                          return (

                      <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders border_card_flex">
                        <div className="d-flex align-items-start flex_table_div">
                          <div className="d-flex">
                          <div className="tabimg mr-2 tab_image_tick">
                          <img src={
                                              Owns.OwnerDetail.image
                                                ? `${config.Back_URL}/Users/${Owns.OwnerDetail.curraddress}/profileImg/${Owns.OwnerDetail.image}`
                                                : require("../assets/images/notable_img_1.png")
                                            }  alt="Collections" className="img-fluid" />
                        </div>
                          <div className="profdata ml-2">
                            <p className=" mb-2 mt-0"><span>owned by</span></p>
                            <p className="hours_des_prof"> {Owns.OwnerDetail.name
                                                ? Owns.OwnerDetail.name
                                                : Owns.OwnerDetail.curraddress
                                                    .slice(0, 15)
                                                    .concat("...")}</p>
                          </div>
                          </div>
                        
                          
                          <p>{Owns.balance}/{Owns.quantity}</p> 
                          {/* <div className="btn_div_info mt-5">
                  {Wallet_Details.UserAccountAddr ? (
                    <>
                      {NFT.CurrentOwner.tokenOwner !==
                      Wallet_Details.UserAccountAddr ? (
                        NFT.CurrentOwner.Onsale ? (
                          <Button
                            className="create_btn ml-3 mb-2"
                            data-toggle="modal"
                            data-target="#buy_modal"
                            onClick={() =>
                              PurchaseNowRef.current.PurchaseNowClick(
                                NFT.CurrentOwner,
                                NFT.NFTDetail.tokenName
                              )
                            }
                          >
                            Buy Now
                          </Button>
                        ) : (
                          <>
                            {NFT.Mybid && NFT.Mybid.length !== 0 ? (
                              <Button
                                className="create_btn ml-3 mb-2"
                                data-toggle="modal"
                                data-target="#place_bid_multiple_modal"
                                onClick={() =>
                                  BidActionsRef.current.PlaceandAcceptBidClick(
                                    NFT,
                                    NFT.NFTDetail
                                  )
                                }
                              >
                                Edit Bid
                              </Button>
                            ) : (
                              <>
                                {new Date(
                                  NFT.NFTDetail.auctionstart
                                ).getTime() > Date.now() ? (
                                  <Button
                                    className="create_btn ml-3 mb-2"
                                    data-toggle="modal"
                                    disabled
                                    data-target="#place_bid_multiple_modal"
                                  >{`Starts in ${timediff(
                                    NFT.NFTDetail.auctionstart
                                  )} Days`}</Button>
                                ) : (
                                  <Button
                                    className="create_btn ml-3 mb-2"
                                    data-toggle="modal"
                                    data-target="#place_bid_multiple_modal"
                                    onClick={() =>
                                      BidActionsRef.current.PlaceandAcceptBidClick(
                                        NFT,
                                        NFT.NFTDetail
                                      )
                                    }
                                  >
                                    Bid
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : NFT.CurrentOwner.Onsale ? (
                        <Button
                          className="create_btn ml-3 mb-2"
                          data-toggle="modal"
                          data-target="#cancel_order_modal"
                          onClick={() =>
                            PlaceandCancelRef.current.PlaceandCancelClick(
                              NFT.CurrentOwner,
                              NFTImage,
                              NFT.NFTDetail
                            )
                          }
                        >
                          Cancel Order
                        </Button>
                      ) : new Date(NFT.NFTDetail.auctionstart).getTime() <
                          Date.now() &&
                        new Date(NFT.NFTDetail.auctionend).getTime() >
                          Date.now() ? (
                        <Button
                          className="create_btn ml-3 mb-2"
                          data-toggle="modal"
                          disabled
                          data-target="#place_bid_multiple_modal"
                        >{`Auction Ends in ${timediff(
                          NFT.NFTDetail.auctionend
                        )} Days`}</Button>
                      ) : (
                        <Button
                          className="create_btn ml-3 mb-2"
                          data-toggle="modal"
                          data-target="#change_price_modal"
                          onClick={() =>
                            PlaceandCancelRef.current.PlaceandCancelClick(
                              NFT.CurrentOwner,
                              NFTImage,
                              NFT.NFTDetail
                            )
                          }
                        >
                          Put On Sale
                        </Button>
                      )}
                    </>
                  ) : (
                    <Link to="/connect-wallet">
                      <Button className="create_btn ml-3 mb-2">
                        Connect Wallet
                      </Button>
                    </Link>
                  )}
                </div> */}
                      </div>
                      </div>)})}

                   
                 
                   
            
            </div>
            
          </div>
          </div>
        

          </div>
        </div>
        </div>

      </div>
      <section>
          <div className="notable_sec carousel-nav m-0 mt-4">
          {/* <Explore/> */}
           <p className="heading_grey subhead mt-2 mb-4">Live<a href="/explore">Explore</a></p> 
               
                  <OwlCarousel     className="owl-theme creator-car" margin={20} autoplay ={false} 
               responsive={state.responsive}>  
               <>
         {
          ExploreCardNFTs[CatName] &&
          ExploreCardNFTs[CatName].list && 
          ExploreCardNFTs[CatName].list.length > 0 &&
          ExploreCardNFTs[CatName].list.map((NFT) => {
            return (
              <div className="item">
              <Itemcard Cardclass={"item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"} NFTDetails={NFT}/>
              </div>
            );
          })}
        </>
 
                 
                       </ OwlCarousel>  
                       
           </div>
      </section>
      {/* end info row */}

      {/* info owner row */}
      {/* end info owner row*/}

</div>
      </div>
      {/* <Footer/> */}
      <PurchaseNow ref={PurchaseNowRef} />
      <PlaceandCancel ref={PlaceandCancelRef} />
      <BidActions ref={BidActionsRef} />
      <BurnToken ref={BurnTokenRef} />
      <Report ref={ReportRef} />
      <Share ref={ShareNowRef} />

      {/* <BidActions ref={BidActionsRef} /> */}

      {/* buy Modal */}
  {/* <Buymodal /> */}
      {/* end buy modal */}

       {/* proceed Modal */}
      <Proceedmodal />
      {/* end proceed modal */}

      {/* place_bid Modal */}
     {/* <Placebidmodal /> */}
      {/* end place_bid modal */}


      {/* place_bid multiple */}
      <Placebidmultiplemodal />
      {/* end place_bid modal multiple */}

       {/* proceed_bid Modal */}
 <Proceedbidmodal />
{/* end proceed_bid modal */}

 {/* accept bid Modal */}
<Acceptmodal />
      {/* end accept bid modal */}

       {/* change_price Modal */}
       <Changepricemodal />
{/* end change_price modal */}
 {/* place_order Modal */}
 {/* <Placeordermodal /> */}
{/* end place_order modal */}

{/* cancel_order Modal */}
<Cancelordermodal />

{/* end cancel_order modal */}
      {/* report Modal */}
     {/* <Reportmodal /> */}
      {/* end report modal */}
      {/* SHARE Modal */}
{/* <Sharemodal /> */}
      {/* end SHARE modal */}
 
    </div>
    
  );
}
