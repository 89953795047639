import axios from "axios";
import config from '../../lib/config';
import Web3 from 'web3';

import { toast } from 'react-toastify';
import isEmpty from "lib/isEmpty";
toast.configure();
let toasterOption = config.toasterOption;


export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/address/details/getorsave`,
			'data': Payload
		})
		localStorage.setItem('user_token', Resp.data.token);
		return {
			data: Resp
		}
	}
	catch (err) {
		//console.log("Error in AddressUserDetails_GetOrSave_Action Function",err)
		return {
			// error: err.response.data
		}
	}
}

export const v1_GetProfile = async (Payload) => {
	//console.log("getprofile ajax i/p ",Payload)
	try {
		let Resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/profile`,
			params: Payload
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const v1_EditProfile = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/edit/profile`,
			'data': Payload,
			'headers': {
                'Authorization': localStorage.user_token
            },
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const v1_EditCover = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/edit/coverphoto`,
			'data': Payload,
			'headers': {
                'Authorization': localStorage.user_token
            },
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const FollowAction = async (payload) =>{
try {
	let resp = await axios({
	'method': 'post',
	'url': `${config.vUrl}/user/follow`,
	data: payload,
	'headers': {
		'Authorization': localStorage.user_token
	},
	});
	return {
	data: resp.data
	}
}
catch (err) {
	//console.log("ErrorinFollowActionFunction",err)
}
}

export const v1_MyUSerData = async (payload) =>{
	//console.log("i/p data payload in axios call ",payload)
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/myitem/data`,
		params: payload
		});
		return {
		data: resp.data
		}
	}
	catch (err) {
		//console.log("Errorinv1_MyUSerDataFunction",err)
	}
}

export const v1_MyComment = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'post',
		'url': `${config.vUrl}/user/info/mycomment`,
		data: payload,
		'headers': {
			'Authorization': localStorage.user_token
		},
		});
		return {
		data: resp.data
		}
	}
	catch (err) {
		//console.log("Errorinv1_MyCommentFunction",err)
	}
}

export const v1_Sub = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'post',
		'url': `${config.vUrl}/user/sub/newsletter`,
		data: payload,
		'headers': {
			'Authorization': localStorage.user_token
		},
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_SubFunction",err)
	}
}

export const v1_Faq = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/faq`,
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_FaqFunction",err)
	}
}

export const v1_GetPrivacy = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/privacy`,
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_GetPrivacyFunction",err)
	}
}

export const v1_AboutUs = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/AboutUs`,
		params : payload
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_FaqFunction",err)
	}
}

export const v1_Terms = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/Terms`,
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_TermsFunction",err)
	}
}

export const v1_Social = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/Social`,
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_SocialFunction",err)
	}
}

export const v1_Category = async (payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/Category`,
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_CategoryFunction",err)
	}
}

export const v1_Ranks = async (Payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/Ranks`,
		'params':Payload
		});
		return resp.data
	}
	catch (err) {
		//console.log("Errorinv1_RanksFunction",err)
	}
}

export const v1_Activity = async (Payload) =>{
	try {
		let resp = await axios({
		'method': 'get',
		'url': `${config.vUrl}/user/get/Activity`,
		'params':Payload
		});
		return resp.data.Record
	}
	catch (err) {
		//console.log("Errorinv1_ActivityFunction",err)
	}
}

export const contact = async (Payload) => {
	//console.log("contact form axios call data ",Payload)
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/contactdet`,
			'data': Payload,
			'headers': {
                'Authorization': localStorage.user_token
            },
		})
		//console.log("contact response data ",Resp)
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}


export const getusers = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/getAllUsersCollections`,
			
		})
		console.log("user dataa ",Resp.data.data);
		return Resp.data.data;
		
	}
	catch (err) {
		//console.log("Error in AddressUserDetails_GetOrSave_Action Function",err)
		return {
			// error: err.response.data
		}
	}
}


