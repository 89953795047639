import WBNB_ABI from '../ABI/token.json';
import Loder from '../assets/images/loader.png'
import axios from 'axios';

let fee1 = '';
let networkVersion = '';
var chainId = '0x61';
var chainIds = 1;
const singleType = 721;
const multipleType = 1155;
let currencySymbol = '';
let tokenSymbol = '';
let maxLength = 6;
var WenlamboConvertVal = 0;
let PurchaseTransferType = 'currency';

var tokenABI = {
    WENLAMBO: WBNB_ABI,
}
let BNBPROVIDER = "";
let Front_URL = '';
let Back_URL = '';
let Users_URL = '';
let v1Url = '';
let limit = '8';
let Lod = Loder;
let limitMax = '3010';
let decimalvalues = 1e18;
let toFixed = 6;
var tokenFee = 0;
let IPFS_IMG = "https://ipfs.io/ipfs";
let Bscscan = null;
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

var shareTag = [];
var toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    limit: 1
}
var providercon = null;
var buyerfee = 0;
var sellerfee = 0;
const exchangeAddress = "";

var singleContract = "";
var multipleContract = "";

let EnvName = 'production';
let fee = fee1;
var currAdrress = '';
if (EnvName === "production") {
    Front_URL = 'https://metaneum.ch';
    Back_URL = 'https://api.metaneum.ch';
    v1Url = 'https://api.metaneum.ch/v1';
    Users_URL = 'https://api.metaneum.ch';
    singleContract = ("0xD62294eA4E7fb21099e1B7b66dB9C9C272730887").toLowerCase();
    multipleContract = ("0x91240fDf3B6be76ad352488Fd5a405c298d9F199").toLowerCase();
    networkVersion = '56'
    Bscscan = 'https://bscscan.com/';
    BNBPROVIDER = "https://bsc-dataseed1.binance.org/";
    tokenSymbol = "WBNB"
    currencySymbol = "BNB"
}

else if (EnvName === "demo") {
    Front_URL = 'http://nftdemo.bimaticz.com/MetaneumNFT ';
    //Front_URL = 'http://localhost:3000/OpenSaleTestnet';
    Back_URL = 'http://nftdemo.bimaticz.com:2053';
    v1Url = 'http://nftdemo.bimaticz.com:2053/v1';
    Users_URL = 'http://nftdemo.bimaticz.com:2053/user';
    singleContract = ("0xe4e253c23C95b11Bad28D056FA62F8Dd59EE79f3").toLowerCase();
    multipleContract = ("0x3FB480BbD72d5bA73E63cE8Bf5093133702E2e4f").toLowerCase();
    networkVersion = '97'
    Bscscan = 'https://testnet.bscscan.com/tx';
    BNBPROVIDER = "https://data-seed-prebsc-1-s1.binance.org:8545/";
    tokenSymbol = "WBNB"
    currencySymbol = "BNB"
}
else {
    Front_URL = 'http://localhost:3000';
    Back_URL = 'http://localhost:7002';
    v1Url = 'http://localhost:7002/v1';
    Users_URL = 'http://localhost:3010/user';
    // singleContract = ("0xc0f3Ab42c52edC7f97c88c2Cb2b63cdDB99704D7").toLowerCase();
    // multipleContract = ("0xF59012f3bf852660215604c61DA24d5A8472d87b").toLowerCase();
    singleContract = ("0xe4e253c23C95b11Bad28D056FA62F8Dd59EE79f3").toLowerCase();
    multipleContract = ("0x3FB480BbD72d5bA73E63cE8Bf5093133702E2e4f").toLowerCase();
    networkVersion = '97'
    // Bscscan = "https://rinkeby.etherscan.io/tx";
    // BNBPROVIDER = "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
    Bscscan = 'https://testnet.bscscan.com/tx';
    BNBPROVIDER = "https://data-seed-prebsc-1-s1.binance.org:8545/";
    tokenSymbol = "WBNB"
    currencySymbol = "BNB"
}



// async function tag() {
//     var id = "0000";
//     await axios.get(v1Url + '/admin/panel/getnfttag/' + id)
//     .then((data) => {
//         if (data && data.data && data.data.userValue) {
//             (data.data.userValue).map((item) => {
//                 (shareTag).push(item.nfttag)
//                 return item
//             })
//         }
//     })
// }
// tag();

let key = {
    Front_URL: Front_URL,
    Back_URL: Back_URL,
    v1Url: v1Url,
    vUrl: v1Url,
    fee: fee,
    decimalvalues: decimalvalues,
    toFixed: toFixed,
    networkVersion: networkVersion,
    currencySymbol: currencySymbol,
    tokenSymbol: tokenSymbol,
    toasterOption: toasterOption,
    limit: limit,
    limitMax: limitMax,
    singleContract: singleContract,
    multipleContract: multipleContract,
    singleType: singleType,
    multipleType: multipleType,
    IPFS_IMG: IPFS_IMG,
    BNBPROVIDER: BNBPROVIDER,
    tokenABI: tokenABI,
    PurchaseTransferType: PurchaseTransferType,
    maxLength: maxLength,
    Users_URL: Users_URL,
    Lod: Lod,
    Bscscan: Bscscan,
    chainId: chainId,
    WenlamboConvertVal: WenlamboConvertVal,
    currAdrress: currAdrress,
    tokenFee: tokenFee,
    sellerfee: sellerfee,
    buyerfee: buyerfee,
    shareTag: shareTag,
    providercon: providercon,
    nameFormat: nameFormat,
    chainIds: chainIds
};

export default key;