import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { v1_GetPrivacy } from "actions/v1/user";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { toast } from "react-toastify";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [PrivacyPol,setPrivacyPol] = useState({})

  const GetPrivacy = async()=>{
    var Resp = await v1_GetPrivacy()
    //console.log("bhvbkjifgkojfn",Resp);
    if(Resp.Success){
        setPrivacyPol(Resp.userValue)}
    else
      toast.error("Error on Server",{autoClose:2000})
  }

  useEffect(()=>{
    GetPrivacy()
  },[])

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div>
            <h2 className="page-heading">Privacy Policy</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Privacy Policy</a></li>
            </ul>
            
        </div>
        </div>
        </div>
        <div className="container container_custom mt-5 pb-5">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            {PrivacyPol.answer && ReactHtmlParser(PrivacyPol.answer)}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
