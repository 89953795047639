import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Proceedbidmodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
   <div className="modal fade primary_modal" id="proceed_bid_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body">                         
       <form> 
       <div className="media approve_media">
       <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
       {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
       <div className="media-body">
         <p className="mt-0 approve_text">Approve</p> 
         <p className="mt-0 approve_desc">Checking balance and approving</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="btn_outline_red btn-block">Done</Button>                
       </div>
       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Signature</p> 
         <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
       </div>
     </div>
       <div className="text-center mt-3">
       <Button className="create_btn btn-block" disabled>Start</Button>                
       </div>
       </form>
     </div>
   </div>
 </div>
</div>

    </div>
  );
}
