import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Itemcard from "./separate/itemcard.js";
import { v1_Search } from "actions/v1/token";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams, useHistory } from "react-router-dom";
import config from "lib/config";
import { usePrevious } from "./my-items";
import { Receipt } from "@material-ui/icons";
import Modal from 'react-modal';





const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const { q } = useParams();
  const prevQ = usePrevious(q);
  const [Page, setPage] = useState(1);
  const prevPage = usePrevious(Page)
  const [uPage, setuPage] = useState(1); 
  const prevuPage = usePrevious(uPage)
  const [Load, setLoad] = useState(true);
  const [uLoad, setuLoad] = useState(true);
  const [ ShowModal,setShowModal] = useState(false)

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [SearchRes, setSearchRes] = useState({
    users: [],
    items: {},
    collections: [],
  });

  useEffect(() => {
    if (prevQ !== q) {
      setLoad(true);
      setPage(1);
    }
    Search(q);
  }, [q, Page, uPage]);

  const Search = async (query) => {
    var useclass1 = document.getElementsByClassName("searchmneu_dd");
    for (var c = 0; c < useclass1.length; c++) {
      useclass1[c].classList.add("d-none");
    }
    let postData = {
      limit: 16,
      page: Page,
      upage:uPage,
      keyword: query,
    };
    var resp = await v1_Search(postData);
    if (resp.data && resp.data.Success) {
      var Result = resp.data.Resp;
      //console.log("Searchecs", resp);
      if (prevQ !== q) {
        setShowModal(true)
        SearchRes.items.records = [];
        SearchRes.users = [];
        setSearchRes([]);
        setSearchRes(Result);
      } else {
        if(prevPage !== Page){
        if (Result.items.records.length > 0) {
          SearchRes.items.records = SearchRes.items.records.concat(
            Result.items.records
          );
          setSearchRes([]);
          setSearchRes(SearchRes);
        } else 
          setLoad(false);
        }
        if(prevuPage !== uPage){
        if(Result.users.length > 0)
        {
          SearchRes.users = SearchRes.users.concat(Result.users);
          setSearchRes([]);
          setSearchRes(SearchRes);
        } else 
          setuLoad(false);
      }
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    }
  };

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
      <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div>
            <h2 className="page-heading">Search</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Search</a></li>
            </ul>
            
        </div>
    </div>
        </div>
        <div className="container container_custom explorepage mt-5">
        <GridContainer>
  <GridItem xs={12} sm={12} md={12}>                                       
    <nav className="explore-tab justify-content-start py-0 search_tab">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <a className="nav-link active" id="items-tab" data-toggle="tab" href="#items" role="tab" aria-controls="all" aria-selected="true">
        <span className="tab_head p-2">Items</span>
          </a>                 
        
          <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="following" aria-selected="false">
          <span className="tab_head p-2">Users</span>
          </a>
          {/* <a className="nav-link" id="collections-tab" data-toggle="tab" href="#collections" role="tab" aria-controls="activity" aria-selected="false">
          <span className="tab_head p-2"> Collections</span>
          </a> */}
      </div>
    </nav>
    <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
      <div className="tab-pane fade show active" id="items" role="tabpanel" aria-labelledby="items-tab">
      <div className="proposal_panel_overall">
      {SearchRes.items &&
                    SearchRes.items.Success &&
                    SearchRes.items.records &&
                    SearchRes.items.records.length > 0 ?(
                      <>
                        <div className="row">
                      {SearchRes.items.records.map((Searched) => {
                        return (
                          <>
                        
                          <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                           <Itemcard Cardclass={
                            "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                          }
                          NFTDetails={Searched}/>
                           </div>
                         
                          </>
                        );
                      })}</div></>
                    ):<div className="text-center py-5 ">
                    <p className="not_found_text">No items found</p>
                    <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                    <div className="mt-3">
                    <Link to="/explore/All">
                    <Button className="create_btn">Browse Marketplace</Button>
                    </Link>
                  </div>             
                  </div>}

                  <div className="col-12 pb-5 text-center pt-4">
                  {SearchRes.items &&
                    SearchRes.items.Success &&
                    SearchRes.items.records &&
                    SearchRes.items.records.length > 0 && Load && (
                  <button className="create_btn"  onClick={() => setPage(Page + 1)} >Load More</button>
                  )}
                </div>
                  </div>
      </div>

        
      <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
      <div className="proposal_panel_overall pb-4">
      {SearchRes.users &&
                          SearchRes.users.length > 0?
                          <div className="row mt-0">
                            {
                              SearchRes.users.map((Searched) => {
                                return( <> <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0 acti_card rounded-corners-gradient-borders">
                                  <Link to={(Searched.customurl)?`/my-items/user/${Searched.customurl}`:`/my-items/${Searched.curraddress}`}>
                                  <div className="card-body px-3">
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">
                                        <img
                                          src={
                                            Searched.image
                                              ? `${config.Back_URL}/Users/${Searched.curraddress}/profileImg/${Searched.image}`
                                              : require("../assets/images/user_img_1.png")
                                          }
                                          alt="User"
                                          className="img-fluid"
                                        />
                                      </div>

                                      <div className="media-body flex_body">
                                        <div>
                                          <p className="mt-0 media_num font_14 mb-0">
                                            {(Searched.customurl)?`@${Searched.customurl}`:(Searched.curraddress).slice(0,10).concat('...')}
                                          </p>
                                          <p className="mt-0 media_text">
                                            {Searched.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </Link>
                                </div>
                              </div>
                              </>
                              
                                )
                              })
                            }
                          </div>:<><div className="text-center py-5 ">
                  <p className="not_found_text">No users found</p>
                  <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                  <div className="mt-3">
                  <Link to="/explore/All">
                  <Button className="create_btn">Browse Marketplace</Button>
                  </Link>
                </div>             
                </div>
                </>}

                
                <div className="col-12 pb-5 text-center pt-4">
                {SearchRes.users &&
                        SearchRes.users.length > 0 && uLoad && (
                        <button
                          className="create_btn"
                          onClick={() => setuPage(uPage + 1)}
                        >
                          Load more items
                        </button>
                      )}
                </div>

                  </div>
      </div>
      {/* <div className="tab-pane fade" id="collections" role="tabpanel" aria-labelledby="collections-tab">
      <div className="proposal_panel_overall pb-4">
                    <div className="text-center py-5 d-none">
                  <p className="not_found_text">No collections found</p>
                  <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                  <div className="mt-3">
                  <Button className="create_btn">Browse Marketplace</Button>
                </div>             
                </div>
                <div className="followers_overall py-1">
                  <div className="row mt-0">
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                    <Itemcard />

                    </div>
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                    <Itemcard />

                    </div>
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                    <Itemcard />

                    </div>
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                    <Itemcard />

                    </div>
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                    <Itemcard />

                    </div>
                     <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6 mb-0">
                     <Itemcard />

                    </div>
                  </div>

                </div>


                  </div>
      </div> */}
    </div>
  </GridItem>
</GridContainer>
        </div>
      </div>
      <Footer/>
   
    </div>
  );
}
