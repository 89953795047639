/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Notifications, AccountBalanceWallet, AccountCircle } from '@material-ui/icons';

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { Scrollbars } from 'react-custom-scrollbars';
import { Account_disConnect } from "../../actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { toast,Flip } from "react-toastify";
import { v1_Search } from "actions/v1/token";
import { Audio, TailSpin } from "react-loader-spinner";
import config from "lib/config";
//import { TailSpin, Oval } from  'react-loader-spinner'
//import CopyToClipboard from "react-copy-to-clipboard";
toast.configure();

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
 
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ key, setkey] = useState('')
  const [SearchRes, setSearchRes] = useState({
    users: [],
    items: {},
    collections: [],
  });
  const [cat ,setcat] = useState('')
  const [Loader, setLoader] = useState(false)
  // const toggletheme = () => {

	// 	document.getElementById("root").classList.toggle('dark_theme');
	// 	var usebody = document.getElementsByClassName("mobile_nav");
	// 	var roottheme = document.getElementById("root");
  //   if(roottheme.classList.contains("dark_theme"))
  //   {
    
    
  //     localStorage.setItem("theme",'dark_theme');
     
    
  //   }
  //   else
  //   {
  //     localStorage.setItem("theme",'light_theme');
    
  //   }
	// 	for (var j = 0; j < usebody.length; j++) {
	// 		usebody[j].classList.toggle('dark_theme')
	// 	}



	// 	var usebody = document.getElementsByClassName("mobile_nav");
	// 	for (var j = 0; j < usebody.length; j++) {
	// 		usebody[j].classList.toggle('dark_theme')
	// 	}




	


  //   if(localStorage.getItem("theme") == "light_theme")
  //   {
    
  //     setTimeout(function(){
  //       var usebody = document.getElementsByClassName("mobile_nav");
  //       for(var j=0;j<usebody.length;j++)
  //       {
  //         usebody[j].classList.remove('dark_theme')
  //       }
  //     },100);
    
    
  //   }
  //   else
  //   {
    
  //     setTimeout(function(){
  //       var usebody = document.getElementsByClassName("mobile_nav");
  //       for(var j=0;j<usebody.length;j++)
  //       {
  //         usebody[j].classList.add('dark_theme')
  //       }
  //     },100);
    
  //   }


	// };
 
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for(var i=0;i<useclass.length;i++)
    {
      useclass[i].classList.toggle('d-none')
    }
   


    
  }


  
  const toggleUsermenuWallet = () => {
    var useclasswallet = document.getElementsByClassName("usemneu_dd_wallet");
    for(var k=0;k<useclasswallet.length;k++)
    {
      useclasswallet[k].classList.toggle('d-none')
    }
   


    
  }


  const toggleUsermenuRes = () => {
    var useclassres = document.getElementsByClassName("usemneu_dd_res");
    for(var l=0;l<useclassres.length;l++)
    {
      useclassres[l].classList.toggle('d-none')
    }
   


    
  }
  
  

  // const toggleSearchmenu = () => {
  //   var useclass1 = document.getElementsByClassName("searchmneu_dd");
  //   for(var j=0;j<useclass1.length;j++)
  //   {
  //     useclass1[j].classList.toggle('d-none')
  //   }
   


    
  // }
  const toggleSearchmenu = async (e) => {
    e.persist();
    var useclass1 = document.getElementsByClassName("searchmneu_dd");
    // for (var j = 0; j < useclass1.length; j++) {
    //   useclass1[j].classList.toggle("d-none");
    // }
    if (e.target.value.length == 0) {
      for (var c = 0; c < useclass1.length; c++) {
        useclass1[c].classList.add("d-none");
      }
    }
    if (e && e.target && e.target.value && e.target.value) {
      if (e.target.value.length == 1) {
        for (var c = 0; c < useclass1.length; c++) {
          useclass1[c].classList.remove("d-none");
        }
      }
      let keywordVal = e.target.value;
      setkey(keywordVal)
      let postData = {
        limit: 3,
        keyword: keywordVal,
      };
      if(keywordVal){
      setLoader(true)
      var resp = await v1_Search(postData);
      if (resp.data && resp.data.Success) {
        //console.log("Searchecs", resp);
        setSearchRes([]);
        setSearchRes(resp.data.Resp);
        setTimeout(() => {
          setLoader(false)
        }, 1000);
      }
    }
    }
  };

  useEffect(() => {
    if (isEmpty(localStorage)) {
      Disconnect();
    }

    //notification(Wallet_Details.UserAccountAddr);
  }, []);

  //console.log("dsjnkjdsnjkfnjknferjf",Wallet_Details.AddressUserDetails)
  const Disconnect = async (a) => {
    dispatch({
      type: Account_disConnect,
      Account_Disconnect: {
        UserAccountAddr: "",
        UserAccountBal: 0,
        providerss: null,
        Service_Fee: 0,
        Wen_Bln: 0,
        Accounts: "",
        WalletConnected: "",
        AddressUserDetails: null,
        tokenAddress: null,
        swapFee: 0,
      },
    });
    if (a === "click")
      toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
    if (
      localStorage.walletconnect != null &&
      localStorage.walletConnectType == "wc"
    ) {
      await Wallet_Details.providerss.disconnect();
      localStorage.removeItem("walletconnect");
    }
    localStorage.removeItem("walletConnectType");
  };


  return (
    <List className={classes.list + " main_navbar"}>
    

      <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul ml-cust-search ml-auto"}>
     
      <div className="search_inp_group">
      
        <input type="text" className="search_inp" placeholder="Search" onChange={(e) => toggleSearchmenu(e)} />
        <div className="search_inp_group_prepend">
          <i className="fas fa-search"></i>
        </div>
      </div>
<div className="noti_parent noti_parnt_user searchmneu_dd d-none" id="searchmneu_dd">
<Scrollbars autoHeight autoHeightMax={350} className="nto_scrol_div">
            {Loader ?
          <>
          
           <div className="text-center py-5 col-12 tail_spin_div">
          <TailSpin wrapperClass="scrreactloader react_loder_modal" color="#00BFFF" height={100} width={70} /></div></>
            :
            <>
            <p className="font_we_700 px-3 pt-3">Items</p>
            <ul className="noti_ul_dd">
              {SearchRes.items &&
                SearchRes.items.Success &&
                SearchRes.items.records &&
                SearchRes.items.records.length > 0 &&
                SearchRes.items.records.map((Searched) => {
                  return (
                    <Link to={`/info/${Searched.contractAddress}/${Searched.tokenowners_current.tokenOwner}/${Searched.tokenID}`}>
                    <li className="px-3">
                      <div className="media">
                        {Searched.image &&
                        Searched.image.split(".").pop() !== "mp4" ? (
                          <img
                            src={
                              Searched.additionalImage
                                ? `${config.Back_URL}/compressedImage/${Searched.tokenCreator}/${Searched.additionalImage}`
                                : require("../../assets/images/collections_01.png")
                            }
                            alt="User"
                            className="img-fluid mr-2 img_user_noti align-self-center"
                          />
                        ) : (
                          <video
                            src={`${config.Back_URL}/compressedImage/${Searched.tokenCreator}/${Searched.additionalImage}`}
                            autoPlay
                            muted
                            className="img-fluid mr-2 img_user_noti align-self-center"
                          />
                        )}
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">
                              {Searched.tokenName}
                            </p>
                            {(Searched.tokenowners_current.tokenPrice)?
                            <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                              {Searched.tokenowners_current.tokenPrice} {config.currencySymbol}
                            </p>
                            :
                            <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                              by {(Searched.tokenOwnerInfo.customurl)?`@${Searched.tokenOwnerInfo.customurl}`:Searched.tokenOwnerInfo.curraddress.slice(0,6).concat('..')}
                            </p>
                            }
                          </div>
                        </div>
                      </div>
                    </li>
                    </Link>
                  );
                })}
            </ul>

            <p className="font_we_700 px-3 pt-3">Users</p>
            <ul className="noti_ul_dd">
              {SearchRes.users &&
                SearchRes.users.length > 0 &&
                SearchRes.users.map((Searched) => {
                  return (
                    <Link to={(Searched.customurl)?`/my-items/user/${Searched.customurl}`:`/my-items/${Searched.curraddress}`}>
                    <li className="px-3">
                      <div className="media">
                        <img
                          src={Searched.image
                            ? `${config.Back_URL}/Users/${Searched.curraddress}/profileImg/${Searched.image}`
                            :require("../../assets/images/logo_big_iocn.png")}
                          alt="User"
                          className="img-fluid mr-2 user_ul_new align-self-center"
                        />
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">
                              {Searched.name}
                            </p>
                            <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                              {(Searched.customurl)&&`@${Searched.customurl}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    </Link>
                  );
                })}
            </ul>
            </>
            }
          </Scrollbars>
{/* <Scrollbars style={{ height: 350 }} className="nto_scrol_div">
  <p className="font_we_700 px-3 pt-3">Items</p>
            <ul className="noti_ul_dd">
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_01.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Medication Time</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">2.3B bn($100) received</p>
                    </div>
                   
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_02.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Autumn</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">Auctions</p>
                    </div>
                   
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_03.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Qilin - Little Red Cap</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">2.3B OPEN received</p>
                    </div>
                    
                  </div>
                </div>
               
              </li>
          
          
            </ul>

            <p className="font_we_700 px-3 pt-3">Users</p>
            <ul className="noti_ul_dd">
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_02.png")} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Qi</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">169 followers</p>
                    </div>
                   
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_02.png")} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Qi</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">169 followers</p>
                    </div>
                   
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/collections_02.png")} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Qi</p>
                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">169 followers</p>
                    </div>
                   
                  </div>
                </div>
               
              </li>
          
          
            </ul>
           
            </Scrollbars> */}
            <div className="text-center">
            <button class="btn new_btn_grad" tabindex="0" type="button"><span><Link to={`/search/query/${key}`}>Search</Link></span></button>
            </div>
            </div>
   
</ListItem>
  
                             
      <ListItem className={classes.listItem}>
        {/* <Link className={classes.navLink} to="/explore">Explore</Link> */}
        <Link className={classes.navLink} to="/explore">Explore</Link>
      </ListItem>

    
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/following">Following</Link>
        {/* <Link className={classes.navLink} to="/following">Following</Link> */}
      </ListItem>
    

      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/create">Create</Link>
        {/* <Link className={classes.navLink} to="/following">Following</Link> */}
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/activity">Activity</Link>
        
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/contact">Contact Us</Link>
      
      </ListItem>
      <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"} onClick={toggleUsermenu}>
{/* <img src={require("../../assets/images/user_01.png")} alt="User" className="img-fluid user_header" /> */}
<Button color="transparent" className={classes.navLink+ " pl-cust-wal"}>
          <AccountCircle className="menu_icons"/>    
          <span className="icon_txt">Wallet</span> 
        </Button>
<div className="noti_parent noti_parnt_user usemneu_dd d-none" id="usemneu_dd">
            {/* <p className="noti_head pt-4 mb-0">
              <span>Enrico Cole</span>
            </p> */}
            <div className="px-0">
            {/* <p className="info_des">oxc4c16ab5ac7d...b21a<i className="fas fa-sticky-note notes_fa cur_pointer"></i></p> */}
            {/* <div className="media header_media pt-0">
              <img src={require("../../assets/images/eth.png")} alt="User" className="img-fluid mr-3 coin_header" />
                <div className="media-body flex_body">
                  <div>
                  <p className="mt-0 media_num">Balance</p>
                  <p className="balance_txt_header pt-0 mb-0">
                    <span>2.3B OPEN</span>
                  </p>
                  
                    </div>
                  
                        </div>
              </div> */}

             {(Wallet_Details.UserAccountAddr !== "")?
            <ul className="user_prof_ul pt-0 mt-0 mb-0">
              <li>
                <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link>
                {/* <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link> */}
                </li>
              <li>
                <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link>
                {/* <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link> */}
                </li>
              

                {/* <li> */}
                {/* <Link to="/notification"><span><i class="fas fa-bell mr-2"></i>Notifications</span></Link> */}
                {/* <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link> */}
                {/* </li> */}
              {/* <li className="swithcj_li">
              <div className="d-flex justify-content-between align-items-center heade_switch">
                <div>
                <span className="hsder_ul_spn"><i class="fas fa-lightbulb mr-2"></i>Dark theme</span>
                </div>
                <label className="switch toggle_custom">
                  <input type="checkbox" onChange={toggletheme} />
                  <span className="slider"></span>
                </label>
              </div>
                
                </li> */}
                
                
              <li>
                <span onClick={()=>Disconnect("click")}><i class="fas fa-sign-out-alt mr-2"></i>Disconnect</span>
                {/* <Link to="/home"><span><i class="fas fa-sign-out-alt mr-2"></i>Disconnect</span></Link> */}
                </li>
            </ul>:<></>}

              </div>
           
           
          
           
            </div>
   
</ListItem>
      <ListItem className={classes.listItem}>
          <Link className={classes.navLink + " wall-btn wall-btn-con"} to="/connect-wallet"> 

              <AccountBalanceWallet className="menu_icons mr-2"/> 
              {!Wallet_Details.UserAccountAddr?
              "Wallet Connect"
              :
              ""}
            
          </Link>
      </ListItem>

      {/* <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"} onClick={toggleUsermenuWallet}>
      <Link className={classes.navLink + " wall-btn wall-btn-my"}> 
              <AccountBalanceWallet className="menu_icons mr-2"/>My Wallet
            
          </Link>
        <div className="noti_parent noti_parnt_user usemneu_dd_wallet d-none" id="usemneu_dd_wallet">
            <p className="noti_head pt-4 mb-0">
              <span>Enrico Cole</span>
            </p>
            <div className="px-3">
            <p className="info_des">oxc4c16ab5ac7d...b21a<i className="fas fa-sticky-note notes_fa cur_pointer"></i></p>
            <div className="media header_media pt-2">
              <img src={require("../../assets/images/eth.png")} alt="User" className="img-fluid mr-3 coin_header" />
                <div className="media-body flex_body">
                  <div>
                  <p className="mt-0 media_num">Balance</p>
                  <p className="balance_txt_header pt-0 mb-0">
                    <span>2.3B OPEN</span>
                  </p>
                  
                    </div>
                  
                        </div>
              </div>
              <hr className="hr_grey"></hr>
              <div className="text-center pb-4">
                <p className="walt_cont_tsct">Wallet Connected</p>
                <img src={require("../../assets/images/wallet_01.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />

              </div>
            

              </div>
           
           
            </div>
   
</ListItem> */}


    </List>
  );
}
