import React, { useEffect, useState ,forwardRef ,useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import $ from 'jquery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation, YouwillGetCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal, usePrevious, useUserTokenBalance, useSignCall } from 'hooks/useMethods';
import { Audio, TailSpin } from  'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { useTokenAppReceipt, useAcceptBidReceipt } from 'hooks/useReceipts';
import { BidAction2DB, UpdateNFTOwners } from 'actions/controller/tokenControl';
import { jsonInterfaceMethodToString } from 'web3-utils';
import config from 'lib/config'
import { toast } from 'react-toastify';
import { PopupValidation } from 'actions/controller/validation';
import Select from "react-select";



export const BidActions = forwardRef((props, ref) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [ NoofToken, setNoofToken ] = useState(1)
  const [ TokenPrice, setTokenPrice ] = useState(0)
  const [ NewTokenDecimal, setNewTokenDecimal ] = useState('')
  const [ BiddingBalance, setBiddingBalance ] = useState('')
  const [bidbalance,setbidbalance] = useState('')
  const [ bidtokendecimal, setbidtokendecimal ] = useState('')
  const [ TokenBalance,SetTokenBalance] = useState(0);
  const [Coinname,setCoinname]=useState("")
  const [ Prices, setPrices] = useState({Youwillpay:0,PriceWithSerfee:"",ApprovePriceWithSerfee:"",PriceWithoutSerfee:"",Display:""})
  const [ NFT, setNFT ] = useState({CurrentOwner:{},Mybid:{}})
  const [ NFTDet, setNFTDet ] = useState({})
  const [ Bid, setBid ] = useState({})
  const [ Bidstate, setBidstate] = useState(false)
  const [ Signstate, setSignstate] = useState(true)
  const [ BidText, setBidText] = useState("Start")
  const [ AccBidstate, setAccBidstate] = useState(false)
  const [ AccBidText, setAccBidText] = useState("Accept Bid")
  const prevNoofToken = usePrevious(NoofToken)
  const prevTokenPrice = usePrevious(TokenPrice)
  const [ bidTokenOptions , SetBidTokenOptions ] = useState([])
  
  const history = useHistory()
  const dispatch = useDispatch()

  useImperativeHandle(
    ref,
    () => ({
        async PlaceandAcceptBidClick(ClickedNFT,NFT,Bids) {
          //console.log("jdsnbfufnuewrgn",ClickedNFT,NFT)
          setBid(Bids)
          setNFTDet(NFT)
          setNFT(ClickedNFT)
          var TokenDecimal = await useTokenDecimal({Coin:config.tokenSymbol},721)
          // var BiddingBal = await useUserTokenBalance({Coin:config.tokenSymbol},721,Wallet_Details)
          var BiddingBal = await useUserTokenBalance({Coin:config.tokenSymbol},721,Wallet_Details)
          setNewTokenDecimal(TokenDecimal)
          setBiddingBalance(BiddingBal)
            if(Wallet_Details.UserAccountAddr)
            {
              var data = {Price:TokenPrice,Quantity:NoofToken,Serfee:Wallet_Details.Service_Fee,Decimal:TokenDecimal}
              var Data = PurchaseCalculation(data)
              if(Bids)
              {
                var Data = YouwillGetCalculation({Price:Bids.tokenBidAmt,Serfee:Wallet_Details.Service_Fee,Quantity:(Bids.pending > ClickedNFT.CurrentOwner.balance)?ClickedNFT.CurrentOwner.balance:Bids.pending})
              }
              setPrices(Data)
              //console.log("jdsnbfufnuewrgn",ClickedNFT,NFT,Data,data,BiddingBal,TokenDecimal)
            }

           
        }
    }))

  const ValidCheck = () =>{
    var ValChk = {TokenPrice:TokenPrice,NoofToken:NoofToken,type:NFTDet.type,MaxToken:NFTDet.tokenQuantity}
    var Valid = PopupValidation(ValChk)
    if(Valid.Success)
    { //console.log("check dtaa ",bidbalance, Prices.PriceWithSerfee/10**bidtokendecimal,bidtokendecimal)
      var Check = (bidbalance < Prices.PriceWithSerfee/10**bidtokendecimal)
      if(Check)
        {
          toast.error("Insufficient Bidding Balance",{theme:"dark"})
        }
      else
        $('#hiddenbutton').trigger( "click" );
    }
    else
      toast.error(Valid.Message,{theme:"dark"})
  }

  const BidCall = async() =>{
      setBidText("OnProgress")
      var Arg = {Coinname:Coinname,NFTOwner:NFT.tokenOwner,NFTId:NFT.tokenID,PriceWithoutSerfee:Prices.PriceWithoutSerfee,PriceWithSerfee:Prices.PriceWithSerfee}
      var Type = NFTDet.type
      var Provider = Wallet_Details
      var Receipt = await useTokenAppReceipt(Arg,Type,Provider)
      if(Receipt.status)
      {
        var BidAct = {Status:Receipt.status,Hash:Receipt.transactionHash,ConAddr:Receipt.to,Quantity:NoofToken,tokenID:NFTDet.tokenID,Bidder:Wallet_Details.UserAccountAddr,BidAmt:TokenPrice,tokenName:NFTDet.tokenName,coinName:Coinname}
        var Resp = await BidAction2DB(BidAct)
        setBidstate(true)
        setSignstate(false)
        setBidText("Done")
        toast.success("Approval Successfull")
      }
  }

  const AcceptBidCall = async(coin) =>{
    setAccBidstate(true)
    setAccBidText("OnProgress")
    var Arg = {Coinname:coin,BidderAddress:Bid.tokenBidAddress,PriceWithoutSerfee:Prices.PriceWithoutSerfee,NFTId:Bid.tokenID,NoofNFTs:(NFT.CurrentOwner.balance > Bid.pending)?Bid.pending:NFT.CurrentOwner.balance}
    var Type = NFTDet.type
    var Provider = Wallet_Details
    var Receipt = await useAcceptBidReceipt(Arg,Type,Provider)
    if(Receipt.status)
    {//console.log("name of the coin ",coin)
      var NewOwnerDetail = {Status:Receipt.status,Hash:Receipt.transactionHash,ConAddr:Receipt.to,Owns:(NFT.CurrentOwner.balance > Bid.pending)?Bid.pending:NFT.CurrentOwner.balance,OldOwner:Wallet_Details.UserAccountAddr,Action:"Accept",NewOwner:Bid.tokenBidAddress,NFTDetail:NFT.CurrentOwner,Bidder:Bid.tokenBidAddress,BidAmt:Bid.tokenBidAmt,coinname:coin,tokenName:NFTDet.tokenName}
      var Resp = await UpdateNFTOwners(NewOwnerDetail)
      if(Resp)
      {
        setAccBidstate(true)
        setAccBidText("Done")
        toast.success("Transaction Successfull")
        $('#Accept_closs').trigger( "click" );
        setTimeout(() => {
          history.push('/explore/All')
        }, 1000);
      }
    }
}

  const SignCall = async()=>{
    var resp = await useSignCall("Confirm Sign",Wallet_Details.UserAccountAddr)
    if(resp)
        {
          $('#bidpro_closs').trigger( "click" );
          setTimeout(() => {
             dispatch({
                type: Account_Connect,
                Account_Detail: {
                  nftaction:"Bidded"
                }
              })
          }, 1000);
        }
  }

  useEffect(()=>{
    if(TokenPrice !== 0 && (TokenPrice !== prevTokenPrice || NoofToken !== prevNoofToken))
    {
      //console.log("jdsnbfufnuewrgn",TokenPrice)
      var data = {Price:TokenPrice,Quantity:NoofToken,Serfee:Wallet_Details.Service_Fee,Decimal:NewTokenDecimal}
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  },[NoofToken, TokenPrice])

  useEffect(()=>{
    if(Wallet_Details.TokenList.length > 0)
    {
    //console.log("wallet dtails toke list ",Wallet_Details.TokenList)
    var toklist = Wallet_Details.TokenList
    var bidTokenArr = toklist.filter((token)=>token.name != "BNB")
    //console.log("array of tokens ",bidTokenArr)
    SetBidTokenOptions(bidTokenArr)
    }
  },[Wallet_Details.TokenList])

const Bidtokenbal = async(e)=>{

  //console.log("selected biddin coin nmae ",e.name);
  setCoinname(e.name)
  var bidcoinname = e.name
  const usertokenbal = await useUserTokenBalance({Coin:bidcoinname},721,Wallet_Details);
  //console.log("bid token val ",usertokenbal)

  var TokenDecimal = await useTokenDecimal({Coin:bidcoinname},721)
  //console.log("token decimal value ",TokenDecimal)
  setbidtokendecimal(TokenDecimal)
  //console.log("uer token balance ",usertokenbal/10**TokenDecimal,bidcoinname); 
  setbidbalance(usertokenbal/10**TokenDecimal)
}

  return(
      <>
       {/* place_bid multiple */}
       <div
        className="modal fade primary_modal"
        id="place_bid_multiple_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="place_bid_multiple_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_multiple_modalLabel">
                Place a bid
              </h5>
              <p className="text-center place_bit_desc">
                You are about to {NFT.Mybid &&  NFT.Mybid.length !== 0?"Edit Your":"Place a"} bid for
              </p>
              <p className="place_bit_desc_2">
                <span className="text_red mr-2">{NFTDet.tokenName}</span>by
                <span className="text_red ml-2 text-truncate">{(NFT.CurrentOwner.CurrentOwner && NFT.CurrentOwner.CurrentOwner.name)?NFT.CurrentOwner.CurrentOwner.name:NFT.CurrentOwner.CurrentOwner && (NFT.CurrentOwner.CurrentOwner.curraddress).slice(0,10).concat('...')}</span>
              </p>
              {NFT.Mybid && NFT.Mybid.length !== 0?
              <p className="text-center place_bit_desc">
                Your Previous Bid - {NFT.Mybid[0] && NFT.Mybid[0].tokenBidAmt} {NFT.Mybid[0] && NFT.Mybid[0].coinname} for Each
              </p>:("")
              }
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
            {!(Prices.Display)?
            <><TailSpin wrapperClass="reactloader react_loder_modal mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
            :
            <>
              <form className="px-4 bid_form">
                {/* <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="bid"
                    className="form-control"
                    placeholder="Enter your bid"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text pl-0" id="basic-addon2">
                      USD
                    </span>
                  </div>
                </div> */}

                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="number"
                    onChange={(e)=>{setTokenPrice(e.target.value)}}
                    id="bid"
                    className="form-control"
                    placeholder="Enter your bid"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text pl-0" id="basic-addon2">
                      {Coinname}
                    </span>
                  </div>
                </div>
                {NFTDet.type === 1155 &&
                <>
                <label htmlFor="qty">
                  Enter quantity{" "}
                  <span className="label_muted pl-2">({NFTDet.balance} available in MarketPlace)</span>
                </label>
                <div className="mb-3 input_grp_style_1">
                  <input
                    type="number"
                    id="qty"
                    onChange={(e)=>{setNoofToken(e.target.value)}}
                    className="form-control"
                    placeholder="quantity"
                  />
                </div>
                </>
                  }
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{Wallet_Details.UserAccountBal} {config.currencySymbol}</p>
                  </div>
                </div>
                <div class="input-group-append">
                      <Select
                          className="yes1 form-control primary_inp select1 selxet_app"

                          id="tokenCategory"
                                              name="tokenCategory"
                                              // onChange={handleChange}
                                              // options={options1}
                                              // onChange={(e)=>{setCoinname(e.name)}}
                                              onChange={(e)=>Bidtokenbal(e)}
                                              options={bidTokenOptions}
                                              label="Single select"
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                        />
                      </div>
              
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your Bidding balance</p>
                  </div>
   
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{(Coinname && bidbalance > 0)?bidbalance:0} {Coinname} </p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{Prices.Display} {Coinname}</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button
                    className="d-none"
                    id = "hiddenbutton"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#proceed_bid_modal"
                  >
                    Hidden Button for validation
                  </Button>
                  <Button
                    className="create_btn btn-block"
                    onClick = {()=>ValidCheck()}
                  >
                    Place Bid
                  </Button>
                </div>
              </form>
              </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal multiple */}

      {/* proceed_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_bid_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_bid_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="bidpro_closs"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i
                    className="fas fa-check mr-3 pro_complete"
                    aria-hidden="true"
                  ></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">
                      Checking balance and approving
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button 
                  className="create_btn btn-block"
                  disabled={Bidstate}
                  onClick={()=>{BidCall()}}
                  >  {BidText==="OnProgress" && (
                    <i
                      class="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}{BidText}</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">
                      Create a signatute to place a bid
                    </p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled={Signstate} onClick={()=>{SignCall()}}>
                    Start
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}
      
       {/* accept bid Modal */}
       {Bid &&
       <div
        className="modal fade primary_modal"
        id="accept_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">
                Accept bid
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="Accept_closs"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!(Prices.PriceWithSerfee)?
            <><TailSpin wrapperClass="reactloader mt-0 mb-3 react_loder_modal" color="#00BFFF" height={100} width={70} /></>
            :
            <>
            <div className="modal-body px-0">
              <div className="change_price_img_div text-center">
                <img
                  src={NFTDet.additionalImage !=""?`${config.Back_URL}/compressedImage/${NFTDet.tokenCreator}/${NFTDet.additionalImage}`:`${config.Back_URL}/nftImg/${NFTDet.tokenCreator}/${NFTDet.image}`}
                  alt="Collections"
                  className="img-fluid"
                />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">
                  You are about to accept bid for
                </span>
                <span className="buy_desc_sm_bold pl-2">{NFTDet.tokenName}</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">
                  {(Bid.Bidderdet && Bid.Bidderdet.name ==="")?Bid.tokenBidAddress.slice(0,20).concat('...'):Bid.Bidderdet && Bid.Bidderdet.name}
                </span>
              </p>
              <p className="info_title text-center">
                {Bid.tokenBidAmt} {Bid.coinname} for {Bid.pending} edition(s)
              </p>

              <div className="row mx-0 pb-3 mt-4">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Seller}%</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{Prices.PriceWithSerfee} {Bid.coinname}</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    className="create_btn btn-block"
                    disabled={AccBidstate}
                    onClick={()=>{AcceptBidCall(Bid.coinname)}}
                  >
                    {AccBidText==="OnProgress" && (
                    <i
                      class="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}{AccBidText}
                  </Button>
                  <Button
                    className="btn_outline_red btn-block"
                    data-dismiss="modal"
                    disabled={AccBidstate}
                    aria-label="Close"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
            </>}
          </div>
        </div>
      </div>
      }
      {/* end accept bid modal */}
      </>
  )
})