import Web3 from "web3"

export const PurchaseCalculation = (data) =>{
    //console.log("purchse cal i/p data ",data)
    var MidPrice = (data.Quantity * (data.Price * 1000000))
    var PriceWithoutSerfee = (Web3.utils.toWei(String(MidPrice/1e6)))
    var SerFee = (MidPrice / 100000000) * ((data.Serfee.Buyer) * 1000000)
    var TotPrice = MidPrice + SerFee
    var PriceWithSerfee = Web3.utils.toWei(String(Number((Number(TotPrice)) / 1000000)))
    var TokenDecimal = 18 - Number(data.Decimal) 
    var ApprovePriceWithSerfee = ((PriceWithSerfee) / 10 ** TokenDecimal)
    return {Youwillpay:(TotPrice/1e6),PriceWithSerfee:PriceWithSerfee,ApprovePriceWithSerfee:ApprovePriceWithSerfee,PriceWithoutSerfee:PriceWithoutSerfee,Display:Web3.utils.fromWei(PriceWithSerfee)}
}

export const YouwillGetCalculation = (data) =>{
    var MidPrice = (data.Quantity * (data.Price * 1000000))
    var PriceWithoutSerfee = (MidPrice/1e6)
    var SerFee = (MidPrice / 100000000) * ((data.Serfee.Seller) * 1000000)
    var TotPrice = MidPrice - SerFee
    var PriceWithSerfee = (Number((Number(TotPrice)) / 1000000))
    var ApprovePriceWithSerfee = PriceWithSerfee
    return {Youwillpay:(TotPrice/1e6),PriceWithSerfee:PriceWithSerfee,ApprovePriceWithSerfee:ApprovePriceWithSerfee,PriceWithoutSerfee:Web3.utils.toWei(String(PriceWithoutSerfee))}
}