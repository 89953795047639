import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Acceptmodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
    <div className="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="change_price_img_div text-center">
              <img src={require("../../assets/images/notable_img_1.png")} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">0xb6631a76645...b7de</span>              
                </p>
                <p className="info_title text-center">0.3B OPEN for 1 edition(s)</p>
               
                            
                <div className="row mx-0 pb-3 mt-4">
                 <div className="col-12 col-sm-6 px-4">
                 <p className="buy_desc_sm">Service fee</p>
                 </div>
                 <div className="col-12 col-sm-6 px-4 text-sm-right">
                 <p className="buy_desc_sm_bold">0.3B OPEN</p>
                 </div>
                </div> 
                <div className="row mx-0 pb-3">
                 <div className="col-12 col-sm-6 px-4">
                 <p className="buy_desc_sm">You will get</p>
                 </div>
                 <div className="col-12 col-sm-6 px-4 text-sm-right">
                 <p className="buy_desc_sm_bold">0.3B OPEN</p>
                 </div>
                </div>           
              <form className="px-4">               
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Accept bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
