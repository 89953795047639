import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';

import {Provider} from 'react-redux'
import {store} from './actions/redux/store'
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'views/Routes'


ReactDOM.render(
  <BrowserRouter basename="/" >
    <Provider  store={store}>
   <Routes />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
