import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams} from "react-router-dom";
import { v1_GetSearch ,v1_AboutUs} from "actions/v1/user";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Search(props) {

  var { FaqID } = useParams();

  const classes = useStyles();
  const { ...rest } = props;
  const [PrivacyPol,setPrivacyPol] = useState({})
//console.log("jfgnbkjdfnkndkjv",FaqID);
const About = async()=>{
  var Us = await v1_AboutUs({Id :FaqID})
  //console.log("kjngfdbijhnfdgikljghfdkljghfekilj",Us);
  setPrivacyPol(Us.userValue)
  //console.log("dfghbdfghgfdh",Us.userValue);
}
  // const GetSearch = async()=>{
  //   var Resp = await v1_GetSearch()
  //   if(Resp.Success){
  //       setPrivacyPol(Resp.userValue)}
  //   else
  //     toast.error("Error on Server",{autoClose:2000})
  // }
  useEffect(()=>{
    // GetSearch()
    About()
  },[])


  useEffect(() => {
 
  }, [])
  return (
    <div className="home_header aboutUs">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_1 pb-5"}>
      <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div>
            <h2 className="page-heading">Help</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Help</a></li>
            </ul>
            
        </div>
        </div>
        </div>
        <div className="container container_custom mt-1">
          {
            PrivacyPol && PrivacyPol.length > 0 &&
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
           
            <p>{PrivacyPol[0].question}</p>
              </GridItem>
              <GridItem className =""  xs={12} sm={12} md={12}>
            {ReactHtmlParser(PrivacyPol[0].answer)}
              </GridItem>
            </GridContainer>
          }
        </div>
      </div>
      <Footer/>
    </div>
  );
}
