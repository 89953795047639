import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v1_ReportToken } from "actions/v1/token";
import config from "lib/config";
import $ from "jquery"

export const Report = forwardRef((props, ref) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [ReportState, setReportState] = useState(false);
  const [OwnDet, setOwnDet] = useState({});
  const [ReportTxt, setReportTxt] = useState('')
  const [NFT, setNFT] = useState({});
  const history = useHistory()
  useImperativeHandle(ref, () => ({
      async ReportClick(ClickedNFT, NFT) {
        if (Wallet_Details.UserAccountAddr) {
          setNFT(NFT);
          setOwnDet(ClickedNFT);
          //console.log("jdsnbfufnuewrgn", ClickedNFT, NFT);
        }
      },
    }));

  const ReportCall = async (Biddet) => {
  setReportState(true)
  var Arg = {
      Reporter:Wallet_Details.UserAccountAddr,
      ReportLink:window.location.href,
      Report:ReportTxt,
      NFTOwner: OwnDet,
      NFTId: NFT,
    }
  var Resp = await v1_ReportToken(Arg)
  //console.log("burnreceiot",Resp)
  if (Resp.data.Success) {
    setReportState(false)
    setReportTxt('')
    $('#report_closs').trigger( "click" );
      toast.success(Resp.data.Message)
  } else {
      setReportState(false)
      setReportTxt('')
      toast.error(Resp.data.Message)
  }
  };

  return(
    <>
    {/* report Modal */}
    <div
         className="modal fade primary_modal"
         id="report_page_modal"
         tabIndex="-1"
         role="dialog"
         aria-labelledby="report_page_modalCenteredLabel"
         aria-hidden="true"
         data-backdrop="static"
         data-keyboard="false"
       >
         <div
           className="modal-dialog modal-dialog-centered modal-sm"
           role="document"
         >
           <div className="modal-content">
             <div className="modal-header text-center">
               <h5 className="modal-title" id="report_page_modalLabel">
                 Report This Profile?
               </h5>
               <button
                 type="button"
                 className="close"
                 data-dismiss="modal"
                 aria-label="Close"
                 id="report_closs"
               >
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
             <div className="modal-body">
               <form>
                 <p className="font_14 font_600 line_20 mb-1 text_drk_bl">
                   Tell us how this user violates the rules of the site
                 </p>
                 <div className="form-row mt-3">
                   <div className="form-group col-md-12">
                     <label className="primary_label" htmlFor="name">
                       Message
                     </label>
                     <textarea
                       type="text"
                       className="form-control primary_inp"
                       id="name"
                       rows="3"
                       onChange={(e)=>setReportTxt(e.target.value)}
                       placeholder="Tell us some details"
                     />
                   </div>
                 </div>
                 <div className="text-center mt-2">
                   <button disabled={ReportState} className="create_btn btn-block w-100" onClick={()=>ReportCall()} type="button">
                     {(!ReportState)?"Submit":<p> <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i></p>}
                   </button>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </div>
       {/* end report modal */}
   </>
  )
})