import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Rankings(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

            <p className="heading_big_blk text-center">Top NFTs</p>
            <p className="heading_sm_blk_new  text-center">The top NFTs on OpenSea, ranked by volume, floor price and other statistics.</p>
            <div className="d-flex align-items-center justify-content-center mt-4">
            <span className="mr-4">   <div className="dropdown">
                            <button className="drop-down itemsselect_dd_new" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            last 7 days <i className="fa fa-angle-down pl-2" aria-hidden="true"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <div className="dropdown-item">last 24 hours</div>
                              <div className="dropdown-item">last 7 days</div>
                              <div className="dropdown-item">last 30 days</div>
                            </div>
                          </div></span>

                          <span>   
                       <div className="dropdown">
                            <button className="drop-down itemsselect_dd_new" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            All Categories <i className="fa fa-angle-down pl-2" aria-hidden="true"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <div className="dropdown-item">All Categories</div>
                              <div className="dropdown-item">Art</div>
                              <div className="dropdown-item">Collectibles</div>
                              <div className="dropdown-item">Domain Names</div>
                              <div className="dropdown-item">Music</div>
                              <div className="dropdown-item">Photography</div>
                              <div className="dropdown-item">Trading Cards</div>
                              <div className="dropdown-item">Utility</div>
                              <div className="dropdown-item">Virtual Worlds</div>

                            </div>
                          </div></span>
            </div>
             
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4 pb-5">
        <div className="table-responsive">
                    <table class="table activtab table_ranking">
  <thead>
    <tr>
      <th scope="col">Collection</th>
      <th scope="col">Volume</th>
      <th scope="col">24h%</th>
      <th scope="col">7d%</th>
      <th scope="col">Floor Price</th>
      <th scope="col">Owners</th>
      <th scope="col">Items</th>

    </tr>
  </thead>
  <tbody>
    <tr>
     
      <td>
          <div className="d-flex tableprofimg">
              <span className="pr-2 num_text_table"> 1 </span>
          <img src={require("../assets/images/img_03.png")} alt="User" className="img-fluid" />
         <p className="mb-0 media_text">Invisible Friends</p></div>
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>25,021.63</span>
          </div>
      </td>
      <td>
          <span className="text_red_table">-80.43%</span>
      </td>
      <td>
         
          <span className="text_none_table">---</span>
         
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>5.75</span>
          </div>
      </td>
      <td>4.2K</td>
      <td>
         50K
      </td>
    </tr>

    <tr>
     
     <td>
         <div className="d-flex tableprofimg">
             <span className="pr-2 num_text_table"> 2 </span>
         <img src={require("../assets/images/img_02.png")} alt="User" className="img-fluid" />
        <p className="mb-0 media_text">3Landers</p></div>
     </td>
     <td>
         <div className="price-tab">
             <span><i className="fab fa-ethereum mr-2"></i>13,021.63</span>
         </div>
     </td>
     <td>
         <span className="text_green_table">+109.43%</span>
     </td>
     <td>
         
         <span className="text_red_table">-10.206%</span>
         
     </td>
     <td>
         <div className="price-tab">
             <span><i className="fab fa-ethereum mr-2"></i>10.75</span>
         </div>
     </td>
     <td>2.2K</td>
     <td>
        20K
     </td>
   </tr>

   <tr>
     
      <td>
          <div className="d-flex tableprofimg">
              <span className="pr-2 num_text_table"> 3 </span>
          <img src={require("../assets/images/img_03.png")} alt="User" className="img-fluid" />
         <p className="mb-0 media_text">Invisible Friends</p></div>
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>25,021.63</span>
          </div>
      </td>
      <td>
          <span className="text_red_table">-80.43%</span>
      </td>
      <td>
         
          <span className="text_none_table">---</span>
         
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>5.75</span>
          </div>
      </td>
      <td>4.2K</td>
      <td>
         50K
      </td>
    </tr>

    <tr>
     
     <td>
         <div className="d-flex tableprofimg">
             <span className="pr-2 num_text_table"> 4 </span>
         <img src={require("../assets/images/img_02.png")} alt="User" className="img-fluid" />
        <p className="mb-0 media_text">3Landers</p></div>
     </td>
     <td>
         <div className="price-tab">
             <span><i className="fab fa-ethereum mr-2"></i>13,021.63</span>
         </div>
     </td>
     <td>
         <span className="text_green_table">+109.43%</span>
     </td>
     <td>
         
         <span className="text_red_table">-10.206%</span>
         
     </td>
     <td>
         <div className="price-tab">
             <span><i className="fab fa-ethereum mr-2"></i>10.75</span>
         </div>
     </td>
     <td>2.2K</td>
     <td>
        20K
     </td>
   </tr>
 
  </tbody>
</table>
</div>
<div className="pb-5 text-center pt-4">
                  <button className="create_btn">Load more</button>

                </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
