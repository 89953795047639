import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Itemcard from "./separate/itemcard.js";

import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams } from "react-router-dom";
import ExploreCard from "./separate/explore_card";
import { ExplorePage } from "actions/v1/token";
import { useSelector } from "react-redux";
import { usePrevious } from "hooks/useMethods";
import { ContactsOutlined } from "@material-ui/icons";
import { Audio, TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import Select from "react-select";



const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Explore(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const { cat } = useParams()
  const [expanded, setExpanded] = React.useState("panel1");
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [ExploreViralNFTs, setExploreViralNFTs] = useState([]);
  const [ CatName, setCatName] = useState("All")
  const [ updated, setupdated] = useState(false)
  const [ CatPage, setCatPage] = useState({"All":{page:1}})
  const prevCatPage = usePrevious(CatPage)
  const prevUpdated = usePrevious(updated)
  const prevCat = usePrevious(ExploreCardNFTs)
  const [Categorylist, setCategorylist] = React.useState([{name:"Art"},{name:"Collectibles"},{name:"Domain Names"},{name:"Music"},{name:"Photography"},{name:"Sports"},{name:"Trading Cards"},{name:"Utility"},{name:"Virtual Worlds"}]);
  const [ ShowModal,setShowModal] = useState(false)
  const [ ShowLoader,setShowLoader] = useState(false)
  const [RateFilter, SetRateFilter] = useState("");
  //const [ droptokenname,setdroptokenname] = useState("")
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
     backgroundColor:'rgba(255,255,255,255)',
     zIndex:2
    },
  };

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{
    setShowLoader(true)
    ExploreCardNFT()
  },[CatName,Wallet_Details.UserAccountAddr,RateFilter])

  // useEffect(()=>{
  //   setShowModal(true)
  //   ExploreViralNFT()
  // },[Wallet_Details.UserAccountAddr])

  useEffect(()=>{
    catChange(cat)
  },[cat])
  
  // const ExploreViralNFT = async () =>{
  //   var Payload = {curraddress:Wallet_Details.UserAccountAddr,Category:"",Page:1,limit:5,Card:"viral"}
  //   var NFT = await ExplorePage(Payload);
  //   if (NFT.data.Success) {
  //     //console.log("dfhbdshfbhsbhbfjh",NFT)
  //     if(NFT.data.records.length > 0)
  //     {
  //       setExploreViralNFTs([])
  //       setExploreViralNFTs(NFT.data.records)
  //     }
  //     setTimeout(() => {
  //       setShowModal(false)
  //     }, 1500);
  //   }
  // }

  const ExploreCardNFT = async (Catpage) => {
    //console.log("category nmame ",CatName)
    var name = CatName == "All"?"All":CatName
    //console.log("cat name var",name)
    var Payload = {curraddress:Wallet_Details.UserAccountAddr,Category:CatName,Page:Catpage || 1,limit:16,ratefilter:RateFilter}
    var NFT = await ExplorePage(Payload);
    
    if (NFT.data.Success) {
      if(NFT.data.records.length > 0)
      { //console.log("cpndition check ",ExploreCardNFTs[CatName].list.length,CatName === NFT.data.cat, ((Catpage?Catpage:ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page),ExploreCardNFTs[CatName].page)
        if(((ExploreCardNFTs[CatName].list.length !== 0) && (CatName === NFT.data.cat)  ))
        {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].page = NFT.data.page
          if(!Catpage && RateFilter){
            ExploreCardNFTs[CatName].list = NFT.data.records  
          }else if(Catpage){
            ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT.data.records)
          }
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
        else if(ExploreCardNFTs[CatName].list.length === 0)
        {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].list = NFT.data.records
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
         }
      
      }
      if(NFT.data.records.length === 0)
      {   
          ExploreCardNFTs[CatName].onmore = false
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs)
      }
      setTimeout(() => {
        setShowLoader(false)
      }, 1500);
    }
    else{
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const catChange = (Cat) =>{
    if(Cat)
    { 
      if(!ExploreCardNFTs[Cat])
      {
        ExploreCardNFTs[Cat] = { page: 1, list: [], onmore: true };
        setExploreCardNFTs(ExploreCardNFTs);
        SetRateFilter("")
        //console.log("reset filter ",RateFilter)
      }
      setCatName(Cat)
    }
  }

  const LoadMore = () =>{
    var Catpage = Number(ExploreCardNFTs[CatName].page) + 1
    //console.log("catpage ",Catpage);
    ExploreCardNFT(Catpage)
  }

  const BrowseMaketPlace = () => {
    return (
      <div className="text-center py-5 col-12 tail_spin_div ">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
          <Button className="create_btn">Browse Marketplace</Button>
        </div>
      </div>
    );
  };


  const Reloadble = () =>{
   
      return(
        <>
         {
          ExploreCardNFTs[CatName] &&
          ExploreCardNFTs[CatName].list && 
          ExploreCardNFTs[CatName].list.length > 0 &&
          ExploreCardNFTs[CatName].list.map((NFT) => {
            return (
              <div className="col-md-6 col-xl-3 col-lg-4">
            <Itemcard Cardclass={"item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"} NFTDetails={NFT}/>
            </div>
            );
          })}
        </>
      )
  }

  const RateexploreCard = (ratefilter)=>{
      //console.log("rate filter ",ratefilter);
      var filter = ratefilter
      SetRateFilter(filter);

      ExploreCardNFT()

  }

  // const selectToken = (e)=>{

  //   //console.log("droptoken data ",e.name)
  //   setdroptokenname(e.name)
  // }

  return (
    <div className="home_header explorepage">
    
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
         <section className="page-head body-top">
        <div className="container container_custom">
            <h2 className="page-heading">Explore</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Explore</a></li>
            </ul>
            
        </div>
    </section>
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_2"}>
      
        <div className="pb-3">
        <div className="container container_custom">
           
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}> 
            <div className="d-md-flex d-block">
                  <nav className="explore-tab justify-content-start py-0">
                                  <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                  <a className={(CatName === 'All')?"nav-link active" : "nav-link"} onClick={()=>catChange("All")} id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true">
                                      <div className="tab_head">All</div>
                                      
                                      </a>

                                      {Wallet_Details.Category && Wallet_Details.Category.length > 0&&
                                         Wallet_Details.Category.map((item) => {
                                          return (
                                               <a className={(CatName === item.name)?"nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"><div class="tab_head">{item.name}</div></a>
                                            )
                                          })
                                        }
                                   
                                  
                                  </div>
                              </nav> 
                   <div className="dropbtn d-flex mt-md-0 mt-3">
                      {/* <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          All Artworks
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="#">Action</a>
                          <a class="dropdown-item" href="#">Another action</a>
                          <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                      </div> */}
                      {/* <div class="input-group-append">
                        <Select
                            className="yes1 form-control primary_inp select1 selxet_app"

                            id="tokenCategory"
                                                name="tokenCategory"
                                                // onChange={handleChange}
                                                // options={options1}
                                                onChange={(e)=>{selectToken(e)}}
                                                options={Wallet_Details.TokenList}
                                                label="Single select"
                                                formControlProps={{
                                                  fullWidth: true
                                                }}
                          />
                        </div> */}
                     
                      <div class="dropdown ">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Sort by
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" onClick={()=>RateexploreCard(-1)}>Top rate</a>
                          {/* <a class="dropdown-item" href="#">Mid rate</a> */}
                          <a class="dropdown-item"onClick={()=>RateexploreCard(1)}>Low rate</a>
                        </div>
                      </div>
                    </div>
              </div>  
              <div className="tab-content explore_tab_content mt-4 pb-5" id="nav-tabContent">
              <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                    <div>
                    <div  className="row">
                      { ExploreCardNFTs[CatName] &&
                        ExploreCardNFTs[CatName].list && 
                        ExploreCardNFTs[CatName].list.length === 0? 
                        <>
                        {
                        ShowLoader?
                         <div className="text-center py-5 col-12 tail_spin_div">
                          <TailSpin
                            wrapperClass="searreactloader"
                            color="#00BFFF"
                            height={100}
                            width={70}
                          />
                        </div>
                        :
                      <BrowseMaketPlace />
                        }
                      </>
                      :<>
                      {
                        ShowLoader?
                            <div className="text-center py-5 col-12 tail_spin_div">
                          <TailSpin
                            wrapperClass="searreactloader"
                            color="#00BFFF"
                            height={100}
                            width={70}
                          />
                        </div>
                        :
                        
                          <Reloadble/>
                        
                        }
                    
                      </>
                      }
                    </div>
                    <div className="loadmore mt-4">
                      <div>
                        {ExploreCardNFTs[CatName] && ExploreCardNFTs[CatName].onmore &&
                        <button className="theme-btn" onClick={()=>{LoadMore()}}>Load More</button>
                        
                        }

                        </div>
                      </div>
                    </div>
                    </div>
                 
                    
              
                </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
      </div>
      <Footer/>
      
    </div>
  );
}
