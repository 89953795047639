import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
//Nimal
import { GetProfiledata, EditCoverPhoto } from "actions/controller/userControl";
import { ImageValidation } from "actions/controller/validation";
import { useSelector } from "react-redux";
import isEmpty from "lib/isEmpty";
import config from "lib/config";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast, Flip } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import Itemcard from "./separate/itemcard.js";
import ExploreCard from "./separate/explore_card";
import ActivityCard from "./separate/ActivityCard";
import { MyItemsData } from "actions/controller/tokenControl";
import { MyUserData } from "actions/controller/userControl";
import { FollowAction } from "actions/v1/user";
import { useHistory, useParams } from "react-router-dom";
import { Audio, TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { TramRounded } from "@material-ui/icons";
import {Share} from "./separate/ShareNow"
toast.configure();
//



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function Myitems(props) {
  const ShareNowRef = useRef();
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const { useradd, userurl } = useParams();
  const InitialForm = {
    Name: "",
    CustomUrl: "",
    Joined: "",
    Bio: "",
    Twitter: "",
    Facebook: "",
    Instagram: "",
    Youtube: "",
    Count: {},
    curraddress: Wallet_Details.UserAccountAddr,
  };
  const [Formdata, setFormdata] = useState(InitialForm);
  const [Pro, setPro] = useState(require("../assets/images/profile_img.png"));
  const [Cover, setCover] = useState(
    require("../assets/images/my_items_baner.png")
  );
  const [MyItemNFTs, setMyItemNFTs] = useState({ onsale: { page: 1, list: [], onmore: true } });
  const [MyUserFollow, setMyUserFollow] = useState({
    followers: { page: 1, list: [], onmore: true },
    activity: { page: 1, list: [], onmore: true },
  });
  const [FollowState, setFollowState] = useState(false);
  const [TabName, setTabName] = useState();
  const [Loader, setLoader] = useState(false);
  const [ ShowModal,setShowModal] = useState(false)
  const [ FollowButState,setFollowButState] = useState(false)
 
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
     backgroundColor:'rgba(255,255,255,255)',
     zIndex:2
    },
  };




  useEffect(() => {
    setShowModal(true)
    GetProfile();
    //console.log("useradd.uerurl in useeffect ",userurl, useradd)
  }, [userurl, useradd, Wallet_Details.UserAccountAddr]);

  //Function
  const GetProfile = async () => {
    //console.log("i/p dta to get profile my-item ",userurl,useradd,Wallet_Details.UserAccountAddr )
    setLoader(true);
    if (userurl)
    {
    //console.log("userurldfgdfg",userurl)
      var Payload = {
        user: { customurl: userurl },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    }
    if (useradd)
    {
      var Payload = {
        user: { curraddress: useradd },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    }
    if (!userurl && !useradd)
    {
      var Payload = {
        user: { curraddress: Wallet_Details.UserAccountAddr },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    }
      //console.log("ppayload data ",Payload)
    var Resp = await GetProfiledata(Payload);
    if (!isEmpty(Resp)) {
      console.log("form data profile ",Resp)
      setFormdata(Resp);
      if (Resp.photo != "")
        setPro(
          `${config.Back_URL}/Users/${Resp.curraddress}/profileImg/${Resp.photo}`
        );
      else setPro(require("../assets/images/profile_img.png"));
      if (Resp.coverimage)
        setCover(
          `${config.Back_URL}/Users/${Resp.curraddress}/coverImg/${Resp.coverimage}`
        );
      else setCover(require("../assets/images/my_items_baner.png"));
      if (Resp.Follow) {
        setFollowState(Resp.Follow);
      }
      setMyItemNFTs({ onsale: { list: [] } });
      await MyItemTab("onsale", Resp.curraddress, "Profile");
    }
  };
  //Function
  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var Resp = ImageValidation(file, "Edit");
      if (Resp.Success) {
        CoverPhoto(file);
      } else toast.error(Resp.Message);
    }
  };
  //Function
  const CoverPhoto = async (file) => {
    var Payload = { File: file, curraddress: Formdata.curraddress };
    var Cresp = await EditCoverPhoto(Payload);
    $("#coverchoose").trigger("click");
    setCover(URL.createObjectURL(file));
  };
  //Function
  const MyItemTab = async (tab, addr, filter,Pageno) => {
   
    if(tab && !MyItemNFTs[tab])
      {
        setLoader(true);
        MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
        setMyItemNFTs(MyItemNFTs);
      }
    var curraddress = addr ? addr : Formdata.curraddress;
    var Payload = {
      curraddress: curraddress,
      Tab: tab ? tab : "onsale",
      Action: "Click",
      Limit: 16,
      Page: Pageno || 1,
      filter: filter,
    };
    if (tab === "activity" || tab === "following" || tab === "followers") {
      var myUser = await MyUserData(Payload);
      if (myUser.Success) {
        setTabName(Payload.Tab);
        var temp = MyUserFollow;
        temp[myUser.Tab] = myUser.records;
        setMyUserFollow([]);
        setMyUserFollow(temp);
      } else {
        setTabName(Payload.Tab);
      }
    } else {
      var myNFT = await MyItemsData(Payload);
      if (myNFT.Success) {
        if(Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page)
        {
          MyItemNFTs[myNFT.Tab].page = Pageno
          MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
          MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
        }
        else{
          if(MyItemNFTs[myNFT.Tab].page === 1){
            MyItemNFTs[myNFT.Tab].list = myNFT.records;
            setMyItemNFTs([]);
            setMyItemNFTs(MyItemNFTs);
            setTimeout(() => {
              setShowModal(false)
            }, 1500);
          }
        }
        setTabName(Payload.Tab);
      } else {
        setTabName(Payload.Tab);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };
  //Function
  const FollowAct = async () => {
    if (Wallet_Details.UserAccountAddr !== "") {
      setFollowButState(true)
      if(FollowState){
        toast.success("Removed from Followings", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      else{
        toast.success("Added to Followings", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      var Payload = {
        userAddress: Formdata.curraddress,
        followerAddress: Wallet_Details.UserAccountAddr,
      };
      var resp = await FollowAction(Payload);
      if (resp.data.Success) {
        setFollowState(!FollowState);
        setFollowButState(false)
      } else{
        toast.warning(resp.data.Message, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
        setFollowButState(false)
      }
    } else history.push("/connect-wallet");
  };
  //Component
  const MyItemCard = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (
          <>
            {MyItemNFTs &&
            MyItemNFTs[TabName] &&
            MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {MyItemNFTs[TabName].list.map((NFT) => {
                  return (
                    <Itemcard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                  );
                })}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>
        )}
      </>
    );
  };
  //Component
  const FollowDetails = () => {
    return (
      <>
        {MyUserFollow &&
          MyUserFollow[TabName] &&
          MyUserFollow[TabName].length > 0 &&
          MyUserFollow[TabName].map((user) => {
            if (user.user.image !== "")
              var Userimg = `${config.Back_URL}/Users/${user.user.curraddress}/profileImg/${user.user.image}`;
            else var Userimg = `${require("../assets/images/user_img_1.png")}`;
            var Username = user.user.name
              ? user.user.name
              : user.user.curraddress.slice(0, 20).concat("..");
            return (
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <Link to={(user.user.customurl)?`/my-items/user/${user.user.customurl}`:`/my-items/${user.user.curraddress}`}>
                <div className="card acti_card rounded-corners-gradient-borders">
                  <div className="card-body px-3">
                    <div className="media follow_media">
                      <div className="img_media_new  mr-3">
                        <img src={Userimg} alt="User" className="img-fluid" />
                      </div>

                      <div className="media-body flex_body">
                        <div>
                        <p className="mt-0 media_text">{Username}</p>
                          <p className="mt-0 media_num font_14 mb-0">
                            {user.Followers && user.Followers.count
                              ? user.Followers.count
                              : 0}{" "}
                            followers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              </div>
            );
          })}
      </>
    );
  };
  //Component
  const ActivityDetails = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (
          <>
            {MyUserFollow &&
            MyUserFollow[TabName] &&
            TabName === "activity" &&
            MyUserFollow[TabName].length > 0 ? (
              MyUserFollow[TabName].map((act) => {
                //console.log("innnfiknsfkneinact", act);
                return (
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                      <ActivityCard Activity={act} />
                    </div>
                  </div>
                );
              })
            ) : (
              <BrowseMarketPlace />
            )}
          </>
        )}
      </>
    );
  };
  //Component
  const BrowseMarketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
        <Link to="/explore/All">
          <Button className="create_btn">Browse Marketplace</Button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <img
              src={require("../assets/images/logo.png")}
              alt="logo"
              className="img-fluid logo_img"
            />
          </Link>
        }
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader items_header"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                <div className="overlay_my_bg"></div>
                <img src={Cover} alt="profile" className="img-fluid" />
                <div className="container container_full edit_cover_container">
                  <p
                    className="edit_cover_text"
                    data-toggle="modal"
                    data-target="#edit_cover_modal"
                  >
                    Edit cover
                  </p>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="inner_pagh_bg_1">
          <div className="container container_full">
            <div className="item_prof">
              <div className="item_prof_img">
                <img
                  src={Pro}
                  alt="profile"
                  className="img-fluid items_profile"
                />
              </div>
            </div>
          </div>
          <div className="container container_full">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <p className="my_items_head">{Formdata.Name}</p>
                <p className="my_items_head_1">@{Formdata.CustomUrl}</p>

                <div className="input-group mb-2 inut_gro_stke_1">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={Formdata.curraddress}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <CopyToClipboard
                        text={Formdata.curraddress}
                        onCopy={() =>
                          toast.success("Address Copied", {
                            closeButton: true,
                            autoClose: 1500,
                            transition: Flip,
                            theme: "dark",
                          })
                        }
                      >
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="img-fluid cur_pointer"
                        />
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
                {Formdata.Site && (
                  <a href={Formdata.Site} target="_blank">
                    <p className="my_items_head_1">
                      Personal Site:{" "}
                      <span className="text-info">
                        {Formdata.Site.split("/")[2]}
                      </span>
                    </p>
                  </a>
                )}
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <p className="join_head">
                  <span>Joined {Formdata.Joined}</span>
                  {Wallet_Details.UserAccountAddr !== Formdata.curraddress && (
                    <button
                      className="create_btn btn_rect myclass"
                      disabled={FollowButState}
                      onClick={() => FollowAct()}
                    >
                      <i className="fas fa-user-plus pr-2"></i>
                      {FollowState ? "Following" : "Follow"}
                    </button>
                  )}
                  <button
                    className="create_btn btn_rect ml-2"
                    data-toggle="modal"
                    data-target="#share_modal"
                    onClick={()=>ShareNowRef.current.ShareSocial_Click(window.location.href,Formdata.Name,"myitem")}
                  >
                    <i className="fas fa-upload pr-2"></i>
                    Share
                  </button>
                </p>

                <p className="my_items_desc_1 mt-2">{Formdata.Bio}</p>
                {console.log("data check ",Formdata.Bio)}
                <p>
                  {Formdata.Twitter !== "" && (
                    <a
                      href={Formdata.Twitter}
                      target="_blank"
                      className="social_a_icon"
                    >
                      <img
                        src={require("../assets/images/twitter_icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Twitter.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Instagram !== "" && (
                    <a
                      href={Formdata.Instagram}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/insta_icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Instagram.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Facebook !== "" && (
                    <a
                      href={Formdata.Facebook}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/Face-icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Facebook.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Youtube !== "" && (
                    <a
                      href={Formdata.Youtube}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/youtube-pl.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Youtube.split("/")[3]}</span>
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="container container_full">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      onClick={() => {
                        MyItemTab("onsale");
                      }}
                      className="nav-link active"
                      id="onsale-tab"
                      data-toggle="tab"
                      href="#onsale"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                    >
                      <div className="tab_head">OnSale</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("created");
                      }}
                      className="nav-link"
                      id="created-tab"
                      data-toggle="tab"
                      href="#created"
                      role="tab"
                      aria-controls="created"
                      aria-selected="false"
                    >
                      <div className="tab_head">Created</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("collectibles");
                      }}
                      className="nav-link"
                      id="collectibles-tab"
                      data-toggle="tab"
                      href="#collectibles"
                      role="tab"
                      aria-controls="collectibles"
                      aria-selected="false"
                    >
                      <div className="tab_head">Collectibles</div>
                    </a>

                    <a
                      onClick={() => {
                        MyItemTab("liked");
                      }}
                      className="nav-link"
                      id="liked-tab"
                      data-toggle="tab"
                      href="#liked"
                      role="tab"
                      aria-controls="liked"
                      aria-selected="false"
                    >
                      <div className="tab_head">Liked</div>
                    </a>
                    {Wallet_Details.UserAccountAddr ===
                      Formdata.curraddress && (
                      <a
                        onClick={() => {
                          MyItemTab("activity");
                        }}
                        className="nav-link"
                        id="activity-tab"
                        data-toggle="tab"
                        href="#activity"
                        role="tab"
                        aria-controls="activity"
                        aria-selected="false"
                      >
                        <div className="tab_head">Activity</div>
                      </a>
                    )}
                    <a
                      onClick={() => {
                        MyItemTab("following");
                      }}
                      className="nav-link"
                      id="following-tab"
                      data-toggle="tab"
                      href="#following"
                      role="tab"
                      aria-controls="following"
                      aria-selected="false"
                    >
                      <div className="tab_head">
                        Following
                        <span className="tab_count_badge">
                          {(Formdata.Count && Formdata.Count.Followings) || 0}
                        </span>
                      </div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("followers");
                      }}
                      className="nav-link"
                      id="followers-tab"
                      data-toggle="tab"
                      href="#followers"
                      role="tab"
                      aria-controls="followers"
                      aria-selected="false"
                    >
                      <div className="tab_head">
                        Followers
                        <span className="tab_count_badge">
                          {(Formdata.Count && Formdata.Count.Followers) || 0}
                        </span>
                      </div>
                    </a>
                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content mt-2"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id={TabName}
                    role="tabpanel"
                    aria-labelledby={`${TabName}-tab`}
                  >
                    <div className="proposal_panel_overall">
                      {TabName === "onsale" ||
                      TabName === "created" ||
                      TabName === "collectibles" ||
                      TabName === "liked" ? (
                        <>
                          <MyItemCard />
                          {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
                          <div className="col-12 pb-5 text-center pt-4">
                            <button className="create_btn"
                            onClick={()=>MyItemTab(TabName, Formdata.curraddress, null,MyItemNFTs[TabName].page+1)}>
                              Load more items
                            </button>
                          </div>
                          }
                        </>
                      ) : (
                        <>
                          {TabName === "followers" ||
                          TabName === "following" ? (
                            <>
                              {Loader ? (
                                <div className="text-center py-5">
                                  <TailSpin
                                    wrapperClass="searreactloader"
                                    color="#00BFFF"
                                    height={100}
                                    width={70}
                                  />
                                </div>
                              ) : (
                                <div className="followers_overall py-1">
                                  {MyUserFollow &&
                                  MyUserFollow[TabName] &&
                                  MyUserFollow[TabName].length > 0 ? (
                                    <>
                                      <div className="row mt-3">
                                        <FollowDetails />
                                      </div>
                                      <div className="col-12 pb-5 text-center pt-4">
                                        <button className="create_btn">
                                          Load more items
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <BrowseMarketPlace />
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {TabName === "activity" && (
                                <div className="followers_overall py-1">
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                                      <p className="join_head mt-0">Filters</p>
                                      <div className="my-4 btn_filter_row">
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr
                                            );
                                          }}
                                          className="create_btn mb-3"
                                        >
                                          All
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Profile"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Profile
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Like"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Like
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Mint"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Mint
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Follow"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Followings
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <ActivityDetails />
                                  <div className="col-12 pb-5 text-center pt-4">
                                    <button className="create_btn">
                                      Load more items
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div
        className="modal fade primary_modal"
        id="edit_cover_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 className="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button
                id="coverchoose"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to
                  upload images in 1140×260 resolution
                </p>
                <form>
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input
                      className="inp_fileHandlefile"
                      onChange={(e) => Handlefile(e)}
                      type="file"
                      name="file"
                    />
                  </div>
                </form>
              </div>
              <div
                className="update_cover_div_2 d-none"
                id="update_cover_div_2"
              >
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i
                    className="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Inprogress
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      <Share ref={ShareNowRef}/>
      <Modal
          isOpen={ShowModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-body text-center">
          <div className="item_prof_img2">
            <img src={require("../assets/images/polygon.svg")} alt="Profile" className="img-fluid items_profile"/>
          </div>
          </div>
        </Modal>
    </div>
  );
}
