import React, { useEffect, useState } from "react";
import config from "lib/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { LikeAction } from "actions/v1/token";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import Badge from "components/Badge/Badge";
import LazyLoad from 'react-lazyload';
import LazyLoader from "../lazyloader";
toast.configure();

export default function ExploreCard(props) {
  const history = useHistory();
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const { NFTDetails, Cardclass } = props;
  const [Templike, setTemplike] = useState(NFTDetails.mylikes ? true : false);
  const [TempCount, setTempCount] = useState(NFTDetails.likecount);
  //console.log("frnjrengjetnn", NFTDetails);
  var NFTLink = `/info/${NFTDetails.contractAddress}/${NFTDetails.tokenOwnerInfo.curraddress}/${NFTDetails.tokenID}`;

  if (NFTDetails.additionalImage == "")
    var NFT = `${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`;
  else
    var NFT = `${config.Back_URL}/compressedImage/${NFTDetails.tokenCreator}/${NFTDetails.additionalImage}`;

  if (NFTDetails.tokenOwnerInfo.image !== "")
    var Owner = `${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.curraddress}/profileImg/${NFTDetails.tokenOwnerInfo.image}`;
  else var Owner = `${require("../../assets/images/profile_img.png")}`;

  if (NFTDetails.tokenOwnerInfo.customurl)
    var OwnerLink = `/my-items/user/${NFTDetails.tokenOwnerInfo.customurl}`;
  else var OwnerLink = `/my-items/${NFTDetails.tokenOwnerInfo.curraddress}`;

  const LikeAct = async () => {
    if (Wallet_Details.UserAccountAddr !== "") {
      if(Templike){
        toast.success("Removed from Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      else{
        toast.success("Added to Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      var Payload = {
        tokenID: NFTDetails.tokenID,
        tokenName: NFTDetails.tokenName,
        curraddress: Wallet_Details.UserAccountAddr,
      };
      var resp = await LikeAction(Payload);
      if (resp.data.Success) {
        if (Templike) {
          setTempCount(TempCount - 1);
          setTemplike(false);
        } else {
          setTempCount(TempCount + 1);
          setTemplike(true);
        }
      } else
        toast.warning(resp.data.Message, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
    } else history.push("/connect-wallet");
  };

  return (
    <div className={Cardclass}>
      <div className="">
        <div className="card_inner_item">
          <div className="item_inner_img">
            <div
              onClick={() => {
                LikeAct();
              }}
              className="like_div_round"
            >
              {Templike ? (
                <i className="far fa-heart liked"></i>
              ) : (
                <i className="far fa-heart liked2"></i>
              )}
            </div>
            <Link to={NFTLink}>
            <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
              <div>
                {NFTDetails.image != "" &&
                  (NFTDetails.image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={NFT} type="video/mp4" />
                    </video>
                  ) : (
                    <img src={NFT} alt="Collections" className="img-fluid" />
                  ))}
              </div>
              </LazyLoad>
            </Link>
          </div>
          <div className="img_desc_nft px-3 py-3">
            <Link to={OwnerLink}>
              <img
                src={Owner}
                alt="User"
                className="img-fluid profiel_img_pos"
                title={`Owner : ${
                  NFTDetails.tokenOwnerInfo.name
                    ? NFTDetails.tokenOwnerInfo.name
                    : NFTDetails.tokenOwnerInfo.curraddress
                }`}
              />
              <img
                src={require("../../assets/images/star.png")}
                alt="Collections"
                className="img-fluid star_img_blue"
              />
            </Link>
            <div className="d-flex justify-content-between mt-2">
              <h2>{NFTDetails.tokenName}</h2>
              <div>
                <h3 className="my-2">
                  <span>{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                          "You Own This"
                          :
                           new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                `${NFTDetails.minimumBid} ${config.currencySymbol}`
                                :
                                (NFTDetails.tokenowners_current.tokenPrice)?
                                    `${NFTDetails.tokenowners_current.tokenPrice} ${config.currencySymbol}` 
                                    : (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                      "You Own This"
                                      :
                                        <badge className="badge badge-dark badge-timer mb-1 make_offer_badge"> Make offer</badge>
                                    }</span>{" "}
                </h3>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="like_count">
                  <i className="far fa-heart liked mr-1" />
                  {TempCount} likes
                </p>
              </div>
              {NFTDetails.auctionend && 
              new Date(NFTDetails.auctionstart).getTime() <= Date.now() &&
              new Date(NFTDetails.auctionend).getTime() > Date.now() ?
              <div className="timeleft">
                <span>
                  {" "}
                  <i class="far fa-clock mr-1"></i>
                </span>
                <span>
                  <Countdown
                    date={new Date(NFTDetails.auctionend).getTime()}
                    renderer={renderer}
                  ></Countdown>
                  {/* 03h 56mm 06s left */}
                </span>
              </div>
              :
              <p className="like_count">
                {NFTDetails.tokenowners_current.balance} in Stock
              </p>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
