import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

import   prof  from 'assets/images/small-profile.png'
import   masonary1  from 'assets/images/masonary_04.png'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Notification(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
      <div className="container container_custom page-head body-top mt-5 pt-5">
                <h2 className="page-heading">Notification</h2>
                <ul className="breadcrums">
                    <li><a href="#">Home</a></li>
                    <li><a href="#"  className="active">Notification</a></li>
                </ul>
            </div>
        <div className="container container_custom mt-5 pb-5 noti_page">
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
            <ul className="noti_ul_dd">
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../assets/images/cat_1.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">ETH received</p>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">0.08 ETH received</p>
                    <p className="mt-0 banner_user font_12 mb-0">2 days ago</p>
                    </div>
                    <div>
                      <span className="purple_circle_dot"></span>
                    </div>
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../assets/images/cat_2.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">ETH received</p>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">0.08 ETH received</p>
                    <p className="mt-0 banner_user font_12 mb-0">2 days ago</p>
                    </div>
                    <div>
                      <span className="purple_circle_dot"></span>
                    </div>
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../assets/images/cat_3.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">ETH received</p>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">0.08 ETH received</p>
                    <p className="mt-0 banner_user font_12 mb-0">2 days ago</p>
                    </div>
                    <div>
                      <span className="purple_circle_dot"></span>
                    </div>
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../assets/images/cat_5.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">ETH received</p>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">0.08 ETH received</p>
                    <p className="mt-0 banner_user font_12 mb-0">2 days ago</p>
                    </div>
                    <div>
                      <span className="purple_circle_dot"></span>
                    </div>
                  </div>
                </div>
               
              </li>
            </ul>
            </div>
          </div>
       
        </div>
      </div>
      <Footer/>
    </div>
  );
}
