import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Gridcard from "./separate/gridcard.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";


import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Notfound(props) {
    
  var state = {
    responsive:{
      0: {
          items: 1,
      },
      575: {
        items: 2,
    },
      992: {
        items: 3,
    },
      1200: {
          items: 3,
      },
  },
  };
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="home_header">
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>
      <section className="page-head body-top pb-0 mt-5">
        <div className="container container_custom pb-5 pt-3">
     
        <div className="text-center pad_top_notfound not_fnd_paddi">
<img src={require("../assets/images/not_found_img.png")} class="img-fluid img_radius" alt="Shape"/>
<div className="row">
    <div className="col-12 col-md-10 col-lg-7 mx-auto not_fnd_paddi_1">
    <p className="not_found_text_404 mt-4">Sorry, we couldn’t find any results for this search.</p>
    <div className="text-center mt-4">
            <button class="btn create_btn coming_soon_btn" tabindex="0" type="button"><span className="text_back"><Link to="/home">Back to home</Link></span></button>
            </div>
    </div>
</div>



</div>



<section className="popularcoll pt-5 mt-5 pb-0">
         <div className="">
         <p className="heading_grey subhead mt-2 mb-4">Popular Collection
         <Link to="/explore">Explore more</Link>

           {/* <a href="#">Explore more</a> */}
         </p>
           <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4">
                 <Gridcard />
                </div>
                <div className="col-md-6 col-lg-4">
                <Gridcard />

                </div>
                <div className="col-md-6 col-lg-4">
                <Gridcard />

                </div>
            </div>
        </div>
      </section>

        </div>
        </section>
      </div>
      <Footer/>
    </div>
  );
}
