import React, { useEffect ,useState} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import config from "lib/config";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";






const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Gridcard(props) { 
//console.log("populaar  fro props ",props.det)

const [popularcollection,setPopularcollection] = useState([]);
const [popularnft,setPopularnft] = useState([]);

//   const classes = useStyles();

  const { ...rest } = props;
  console.log("props in grid card ",rest.det.NFT)


  useEffect(() => {
    setPopularcollection(rest.det);
    setPopularnft(rest.det.NFT);
  }, [popularcollection]);
  

  
  
  
  
  

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days} : {hours} : {minutes} : {seconds}</span>;
    }
};










  return (
    <div>
      
      {/* {(popularnft && popularnft.length >= 4 && popularcollection.curraddress != "0x40f5c2863d314e13840b5f756681eced18f165d1" ) && */}
      {(popularnft && popularnft.length >= 4  ) &&
   <div className="grid-card">
      <Link
              to={
                popularcollection.customurl
                   ? `/my-items/user/${popularcollection.customurl}`
                   : `/my-items/${popularcollection.curraddress}`
                        }
                      >
                   <div className="d-flex justify-content-between mb-3">
                   <div className="flex_pop_col">
                      <div className="carousel-img">
                          {/* <img src={require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" /> */}
                           <img src={popularcollection.image
                                    ? `${config.Back_URL}/Users/${popularcollection.curraddress}/profileImg/${popularcollection.image}`
                                    : require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" /> 
                      
                      </div> 
                      <div className="profdata pl-2">
                        <p>Creative Art Collect</p>
                        <p className="name"><span>Created by</span>{popularcollection.name?popularcollection.name:popularcollection.curraddress}</p>
                      </div>
                      </div>
                     
                      {/* <div className="likes-count">
                        <span className="fa fa-heart mr-1"></span>350 
                      </div> */}
                    </div>
                    <div className="grid-image">
                      <div className="row">
                        <div className="col-6 pr-0">
                           {/* <img src={require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" /> */}
                           {popularnft[0].image != "" &&
                  (popularnft[0].image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={`${config.Back_URL}/nftImg/${popularnft[0].tokenCreator}/${popularnft[0].image}`} type="video/mp4" />
                    </video>
                  ) : (
                    (popularnft[0].additionalImage == "")?
                    <img src={`${config.Back_URL}/nftImg/${popularnft[0].tokenCreator}/${popularnft[0].image}`}  alt="Collections" className="img-fluid" />
                    : <img src={`${config.Back_URL}/compressedImage/${popularnft[0].tokenCreator}/${popularnft[0].additionalImage}`}  alt="Collections" className="img-fluid" />
                    
                  ))}
                         
                        </div>
                       
                        <div className="col-6 pl-2">
                          <div className="row">
                            <div className="col-6 pr-1">
                            {popularnft[1].image != "" &&
                  (popularnft[1].image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={`${config.Back_URL}/nftImg/${popularnft[1].tokenCreator}/${popularnft[1].image}`} type="video/mp4" />
                    </video>
                  ) : (
                    (popularnft[1].additionalImage == "")?
                    <img src={`${config.Back_URL}/nftImg/${popularnft[1].tokenCreator}/${popularnft[1].image}`}  alt="Collections" className="img-fluid" />
                    : <img src={`${config.Back_URL}/compressedImage/${popularnft[1].tokenCreator}/${popularnft[1].additionalImage}`}  alt="Collections" className="img-fluid" />
                    
                  ))}
                            </div>
                            <div className="col-6 pl-1">
                              {/* <img src={require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" /> */}
                              {popularnft[2].image != "" &&
                  (popularnft[2].image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={`${config.Back_URL}/nftImg/${popularnft[2].tokenCreator}/${popularnft[2].image}`} type="video/mp4" />
                    </video>
                  ) : (
                    (popularnft[2].additionalImage == "")?
                    <img src={`${config.Back_URL}/nftImg/${popularnft[2].tokenCreator}/${popularnft[2].image}`}  alt="Collections" className="img-fluid" />
                    : <img src={`${config.Back_URL}/compressedImage/${popularnft[2].tokenCreator}/${popularnft[2].additionalImage}`}  alt="Collections" className="img-fluid" />
                    
                  ))}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 pt-2">
                            {popularnft[3].image != "" &&
                  (popularnft[3].image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={`${config.Back_URL}/nftImg/${popularnft[3].tokenCreator}/${popularnft[3].image}`} type="video/mp4" />
                    </video>
                  ) : (
                    (popularnft[3].additionalImage == "")?
                    <img src={`${config.Back_URL}/nftImg/${popularnft[3].tokenCreator}/${popularnft[3].image}`}  alt="Collections" className="img-fluid" />
                    : <img src={`${config.Back_URL}/compressedImage/${popularnft[3].tokenCreator}/${popularnft[3].additionalImage}`}  alt="Collections" className="img-fluid" />
                    
                  ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                     
                    </Link></div>
}

    </div>
  );
}
