import React, { useEffect , useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Itemcard from "./separate/itemcard.js";

import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FollowingNFTs } from "actions/v1/token";
import Modal from 'react-modal';

import   prof  from 'assets/images/small-profile.png'
import   masonary1  from 'assets/images/masonary_04.png'
import { toast } from "react-toastify";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const [ExploreFollowingNFTs, setExploreFollowingNFTs] = useState([]);
  const [Page,setPage]=useState(1)
  const [LoadMore,setLoadMore] = useState(true)
  const [ ShowModal,setShowModal] = useState(false)

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  useEffect(()=>{
    if(Page === 1)
    //setShowModal(true)
    getfollowingNFTs()
  },[Wallet_Details.UserAccountAddr,Page])

  const getfollowingNFTs = async () => {
		var resp = await FollowingNFTs({
			target: 'follower',
			addr: Wallet_Details.UserAccountAddr,
      page:Page,
      limit:16
		});
		if (resp.data && resp.data.Success) {
      if(resp.data.records && resp.data.records.length > 0)
      {
			setExploreFollowingNFTs(resp.data.records)
      if(Page > 1)
      {
        var temp = ExploreFollowingNFTs.concat(resp.data.records)
        setExploreFollowingNFTs(temp)
      }
    }
    else{
      setLoadMore(false)
    }
    //console.log("jdnfkjn",resp)
    setTimeout(() => {
      setShowModal(false)
    }, 1500);
		}
		else {
			setExploreFollowingNFTs([])
      toast.error("Error on Follow Fetch",{autoClose:2000})
		}
	}


  return (
    <div className="home_header">
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div>
        <div className="page-head body-top mt-5 pt-5">
        <div className="container container_custom">
            <h2 className="page-heading">Following</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Following</a></li>
            </ul>
            
        </div>
        </div>
        </div>
        <div className="container container_custom mt-5 pb-5">
        <div className="proposal_panel_overall">
        {ExploreFollowingNFTs &&
                ExploreFollowingNFTs.length === 0 &&
                    <div className="text-center py-5 d-none">
                  <p className="not_found_text">No items found</p>
                  <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                  <div className="mt-3">
                  <Link to="explore/All">
                  <Button className="create_btn">Browse Marketplace</Button>
                  </Link>
                </div>             
                </div>}

                
                <div className="row"> 
                {ExploreFollowingNFTs &&
                ExploreFollowingNFTs.length > 0 &&
                ExploreFollowingNFTs.map((NFT) => {
                 
                
                  return (
                    <>
                   
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                    <Itemcard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    /></div>
                      
                      </>
                  );
                
                  
                })}  </div>
                
                   
                    {LoadMore &&
                <div className="col-12 pb-5 text-center pt-4">
                  <button className="create_btn" onClick={()=>setPage(Page+1)}>Load More</button>

                </div>}

                  </div>

        </div>
      </div>
      <Footer/>
    
    </div>
  );
}
