import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import config from "lib/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { LikeAction } from "actions/v1/token";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Badge from "components/Badge/Badge";
import LazyLoad from 'react-lazyload';
import LazyLoader from "../lazyloader";
toast.configure();








const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Itemcard(props) { 

  const history = useHistory();
//   const classes = useStyles();

  const { ...rest } = props;

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days} : {hours} : {minutes} : {seconds}</span>;
    }
};


const Wallet_Details = useSelector((state) => state.wallet_connect_context);
const { NFTDetails, Cardclass } = props;
//console.log("nft details ",NFTDetails)
const [Templike, setTemplike] = useState(NFTDetails.mylikes ? true : false);
const [TempCount, setTempCount] = useState(NFTDetails.likecount);

//console.log("frnjrengjetnn", NFTDetails);
var NFTLink = `/info/${NFTDetails.contractAddress}/${NFTDetails.tokenOwnerInfo.curraddress}/${NFTDetails.tokenID}`;

if (NFTDetails.additionalImage == "")
  var NFT = `${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`;
else
  var NFT = `${config.Back_URL}/compressedImage/${NFTDetails.tokenCreator}/${NFTDetails.additionalImage}`;

if (NFTDetails.tokenOwnerInfo.image !== "")
  var Owner = `${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.curraddress}/profileImg/${NFTDetails.tokenOwnerInfo.image}`;
else var Owner = `${require("../../assets/images/profile_img.png")}`;

if (NFTDetails.tokenOwnerInfo.customurl)
  var OwnerLink = `/my-items/user/${NFTDetails.tokenOwnerInfo.customurl}`;
else var OwnerLink = `/my-items/${NFTDetails.tokenOwnerInfo.curraddress}`;

const LikeAct = async () => {
  if (Wallet_Details.UserAccountAddr !== "") {
    if(Templike){
      toast.success("Removed from Liked", {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
    }
    else{
      toast.success("Added to Liked", {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
    }
    var Payload = {
      tokenID: NFTDetails.tokenID,
      tokenName: NFTDetails.tokenName,
      curraddress: Wallet_Details.UserAccountAddr,
    };
    var resp = await LikeAction(Payload);
    if (resp.data.Success) {
      if (Templike) {
        setTempCount(TempCount - 1);
        setTemplike(false);
      } else {
        setTempCount(TempCount + 1);
        setTemplike(true);
      }
    } else
      toast.warning(resp.data.Message, {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
  } else history.push("/connect-wallet");
};



  return (
    <div>
 
  <div className="grid-card live_carousel">
                                <div className="showcard-img">
                                <Link to={NFTLink}>
            <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
              <div>
                {NFTDetails.image != "" &&
                  (NFTDetails.image.split(".").pop() == "mp4" ? (
                    <video
                      id="my-video"
                      class="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                    >
                      <source src={NFT} type="video/mp4" />
                    </video>
                  ) : (
                    <img src={NFT} alt="Collections" className="img-fluid" />
                  ))}
              </div>
              </LazyLoad>
            </Link>
                                    <div className="likes-count"  onClick={() => {
                                      LikeAct();
                                    }}>
                                       {Templike ? (
                                             <span className="fa fa-heart mr-1" />
                                          ) : (
                                            <i className="far fa-heart liked2"></i>
                                          )}
                                      <> </>
                                      {TempCount}
                                      
                                      {/* <span className="fa fa-heart mr-1" />
                                    
                                      </span> */}
                                    </div>
                                    
                                    {/* <div className="status">
                                        <p className="yellow">Coming soon</p>
                                    </div> */}
                                  {NFTDetails.auctionend && 
                                    new Date(NFTDetails.auctionstart).getTime() <= Date.now() &&
                                    new Date(NFTDetails.auctionend).getTime() > Date.now() ?

                                    <div className="live_count">
                                      <div className="d-flex align-items-center justify-content-center">
                                    <i className="fas fa-fire pr-2"></i>
                                    <Countdown
                                      date={new Date(NFTDetails.auctionend).getTime()}
                                      renderer={renderer}
                                    ></Countdown>
                                    </div>
                                    </div>
                                  :<div className="live_count">{NFTDetails.tokenowners_current.balance} in Stock</div>
                                  }
                                </div>
                                <div className="showcard-det">
                                 
                                   <h3 className="pro-name">{(NFTDetails.tokenName && (NFTDetails.tokenName).length < 40)?NFTDetails.tokenName:(NFTDetails.tokenName).slice(0,35).concat("...")}</h3>
                                   <div className="d-flex mb-3">
                                   <Link to={OwnerLink}>
                                     <div className="carousel-img mr-2">
                                        <img src={Owner}  alt="Collections" className="img-fluid" />
                                      </div>
                                    </Link>
                                        <div className="profdata">
                                          <p className="name mb-2 mt-0"><span> Owned by</span></p>
                                          <p>{(NFTDetails.tokenOwnerInfo.name)?NFTDetails.tokenOwnerInfo.name:NFTDetails.tokenOwnerInfo.curraddress.slice(0,5)}</p>
                                        </div>
                                        <div className="profdata ml-auto">
                                            
                  <span>{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                          "You Own This"
                          :
                           new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                 
                               ` minimumbid ${NFTDetails.minimumBid} ${NFTDetails.coinname}`
                                :
                                (NFTDetails.tokenowners_current.tokenPrice)?
                                    `${NFTDetails.tokenowners_current.tokenPrice} ${NFTDetails.coinname}` 
                                    : (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                      "You Own This"
                                      :
                                        <badge className="badge badge-dark badge-timer mb-1 make_offer_badge"> Make offer</badge>
                                    }</span>{" "}
                 <div>
              </div>
                                          {/* <p>4.24 ETH</p> */}
                                        </div>
                                    </div>
                                          <div className="placebid d-flex justify-content-between align-items-center">
                                          {(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                            (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr && (NFTDetails.PutOnSale && NFTDetails.PutOnSaleType == 'Fixed'))?
                                            <Link to={NFTLink} className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> On Sale</Link>:
                                             <Link to={NFTLink} className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span>  Put On Sale</Link>:
                                               new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                               <Link to={NFTLink} className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span>  Place Bid</Link>:
                                                  (NFTDetails.tokenowners_current.tokenPrice)?
                                                  <Link to={NFTLink} className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> Buy Now</Link>:
                                                    (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                                    <Link to={NFTLink}className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> Put On Sale</Link>:
                                                    <Link to={NFTLink} className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> Place Bid</Link>
                                          }
                                             {/* <Link to="info" className="theme-btn"><span className="fa fa-shopping-bag mr-1"></span> Place bid</Link> */}
                                             <Link to={NFTLink} className=""><span className="fa fa-recycle"></span> View History</Link>
                                          </div>
                                </div>

                           </div>
    </div>
  );
}
