import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Cancelordermodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
   <div className="modal fade primary_modal" id="cancel_order_modal" tabIndex="-1" role="dialog" aria-labelledby="cancel_order_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="cancel_order_modalLabel">Delete Instant Sale</h5>
       <div className="change_price_img_div">
       <img src={require("../../assets/images/notable_img_1.png")}  alt="Collections" className="img-fluid" />
       </div>
       <p className="text-gray font_we_600 font_14">You are about to delete Instant Sale for Ants Net for 0xb6631a76645...b7de</p>             
       
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body px-0 pt-0">
       <form className="px-4 bid_form">
       <label for="bid"  className="text-center">2.3B OPEN</label>
   
         
                     
         <div className="text-center mt-3">
           <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Delete Instant Sale</Button>
           <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

         </div>
       
       </form>
     </div>
   </div>
 </div>
</div>

    </div>
  );
}
