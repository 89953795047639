import React, { useEffect, useState ,forwardRef ,useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import $ from 'jquery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal, usePrevious ,useUserTokenBalance } from 'hooks/useMethods';
import { Audio, TailSpin } from  'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { usePurchaseReceipt ,useTokenAppReceipt } from 'hooks/useReceipts';
import { UpdateNFTOwners } from 'actions/controller/tokenControl';
import { PopupValidation } from 'actions/controller/validation';
import config from "lib/config"
import { toast } from 'react-toastify';

export const PurchaseNow = forwardRef((props, ref) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [ TokenBalance,SetTokenBalance] = useState(0);
  const [ApproveStatus,setApprove] = useState(false);
  const [ NoofToken, setNoofToken ] = useState(1)
  const [ NewTokenDecimal, setNewTokenDecimal ] = useState('')
  const [ Prices, setPrices] = useState({Youwillpay:0,PriceWithSerfee:"",ApprovePriceWithSerfee:"",PriceWithoutSerfee:""})
  const [ NFT, setNFT ] = useState({CurrentOwner:{}})
  const [ Buystate, setBuystate] = useState(true)
  const [ tokName, settokName] = useState("")
  const prevNoofToken = usePrevious(NoofToken)
  const history = useHistory()
  useImperativeHandle(
    ref,
    () => ({
        async PurchaseNowClick(ClickedNFT,tokenName) {
          //console.log("initial clickednft",ClickedNFT)
          if(ClickedNFT.OwnerDetail)
          {
            ClickedNFT.CurrentOwner = ClickedNFT.OwnerDetail
          }
          settokName(tokenName)
          setNFT(ClickedNFT)
          //console.log("jdsbnfshjfbrbhjtn",ClickedNFT)
          var TokenDecimal = await useTokenDecimal({Coin:ClickedNFT.coinname},721)
          //console.log("token decimal value ",TokenDecimal)
          setNewTokenDecimal(TokenDecimal)
            if(Wallet_Details.UserAccountAddr)
            {
              var data = {Price:ClickedNFT.tokenPrice,Quantity:NoofToken,Serfee:Wallet_Details.Service_Fee,Decimal:TokenDecimal}
              var Data = PurchaseCalculation(data)
              
              setPrices(Data)
              //console.log("jdsnbfufnuewrgn",Data)
            }

           const usertokenbal = await useUserTokenBalance({Coin:ClickedNFT.coinname},721,Wallet_Details);
           //console.log("uer token balance ",usertokenbal/10**TokenDecimal,ClickedNFT.coinname);
           if(ClickedNFT.coinname != "BNB" ){
            SetTokenBalance(usertokenbal/10**TokenDecimal)
           }
             
        }
    }))


   

   

  useEffect(()=>{
    if(NoofToken !== 1 && prevNoofToken !== NoofToken)
    {
      var data = {Price:NFT.tokenPrice,Quantity:NoofToken,Serfee:Wallet_Details.Service_Fee,Decimal:NewTokenDecimal}
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  },[NoofToken])

  const ValidCheck = () =>{
    var ValChk = {TokenPrice:Prices.Youwillpay,NoofToken:NoofToken,type:NFT.type,MaxToken:NFT.balance}
    var Valid = PopupValidation(ValChk)
    if(Valid.Success)
    {   // validation changed for multiple token 
      var Check
      if(NFT.coinname == "BNB"){
        Check = (Wallet_Details.UserAccountBal < Prices.Youwillpay)
      }else{
        Check = ( TokenBalance < Prices.Youwillpay)
      }
    
      if(Check)
        {
          toast.error("Insufficient Balance",{theme:"dark"})
        }
      else
        $('#hiddenbutton').trigger( "click" );
    }
    else
      toast.error(Valid.Message,{theme:"dark"})
  }

  const BuyCall = async() =>{
      setBuystate(true)
      var Arg = {NFTOwner:NFT.tokenOwner,NFTId:NFT.tokenID,PriceWithoutSerfee:Prices.PriceWithoutSerfee,PriceWithSerfee:Prices.PriceWithSerfee,NoofNFTs:NoofToken,Coinname:NFT.coinname}
      var Type = NFT.type
      var Provider = Wallet_Details
      // var isToken = false
      var isToken = null;
      if(NFT.coinname == 'BNB')
          isToken = false
      else
          isToken = true


          //console.log("NFT.coinname ,isToken ",NFT.coinname,isToken)
      var Receipt = await usePurchaseReceipt(Arg,Type,Provider,isToken)
      if(Receipt.status)
      {
        var NewOwnerDetail = {Status:Receipt.status,Hash:Receipt.transactionHash,ConAddr:Receipt.to,Owns:NoofToken,NFTDetail:NFT,Action:"Purchase",NewOwner:Wallet_Details.UserAccountAddr,OldOwner:NFT.tokenOwner,coinname:NFT.coinname,tokenName:tokName}
        var Resp = await UpdateNFTOwners(NewOwnerDetail)
        if(Resp)
        {
          $('#buy_closs').trigger( "click" );
          setTimeout(() => {
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        }
      }
        setBuystate(false)
  }


  const Approvecall = async()=>{
    setApprove(true)
     var Arg = {NFTOwner:NFT.tokenOwner,NFTId:NFT.tokenID,PriceWithoutSerfee:Prices.PriceWithoutSerfee,PriceWithSerfee:Prices.PriceWithSerfee,NoofNFTs:NoofToken,Coinname:NFT.coinname}
     var approvestatus = await useTokenAppReceipt(Arg,NFT.type,Wallet_Details)
     //console.log("approve status erceipt ",approvestatus)
     if(approvestatus.status == true){
       setApprove(true)
       setBuystate(false)
        toast.success("Approval Success",{theme:"dark"})}
     else{toast.error("Approval Error",{theme:"dark"})}
  }

  return(
      <>
       {/* buy Modal */}
       <div
        className="modal fade primary_modal"
        id="buy_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="buy_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
                {!(Prices.PriceWithSerfee)?
              <><TailSpin wrapperClass="reactloader mt-0 mb-3 react_loder_modal" color="#00BFFF" height={100} width={70} /></>
              :
              <>
              <div className="row mx-0">
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md">{(NFT.CurrentOwner.name)?NFT.CurrentOwner.name:(NFT.CurrentOwner.curraddress).slice(0,10).concat('...')}</p>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_sm text-md-right">Buyer</p>
                  <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0,10).concat('...')}</p>
                </div>
              </div>
              <div className="mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0">
                {Prices.Youwillpay} {NFT.coinname}
                </p>
              </div>
              {NFT.type === 1155 &&
              <div className="m-3">
              <label htmlFor="qty" className="buy_desc_sm">
                  Enter quantity{" "}
                  <span className="label_muted pl-2">({NFT.balance} available)</span>
                </label>
                <div className="mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="qty"
                    className="form-control"
                    placeholder="1"
                    onChange={(e)=>{setNoofToken(e.target.value)}}
                  />
                </div>
                </div>
                }
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{Wallet_Details.UserAccountBal} {config.currencySymbol}</p>
                </div>
              </div>
              {NFT.coinname != "BNB" &&
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Your Token Balance</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold"> {(NFT.coinname != "BNB" && TokenBalance>0 )?TokenBalance:0} {NFT.coinname}</p>
                </div>
              </div>}
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Price of the NFT</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{NFT.tokenPrice} {NFT.coinname}</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    className="d-none"
                    id = "hiddenbutton"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#proceed_modal"
                  >
                    {"Proceed to Payment"}
                  </Button>
                  <Button
                    className="create_btn btn-block"
                    onClick = {()=>ValidCheck()}
                  >
                    Proceed to Payment
                  </Button>
                  <Button
                    className="btn_outline_red btn-block"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
             </>
              }
             </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

        {/* proceed Modal */}
        <div
        className="modal fade primary_modal"
        id="proceed_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {(NFT && NFT.coinname != "BNB" )?
                <>
              <p className="mt-0 purchase_text text-center">Approve</p>
                <p className="mt-0 purchase_desc text-center">
                  Approve transaction with your wallet
                </p>

                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block" disabled={ApproveStatus} onClick={()=>
                    {Approvecall()}} >
                   Approve
                  </Button>
                </div>


              {ApproveStatus &&
                <>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>
              
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BuyCall()}}>
                  {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}Inprogress
                  </Button>
                </div>
                </>}




                </>:<>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>
              
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BuyCall()}}>
                  {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}Inprogress
                  </Button>
                </div>
                </>
}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}
      </>
      
  )
})