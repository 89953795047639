import { useWeb3 } from "./useWeb3";
import { useMint, useTokenApprove, usePurchase, useAcceptBid, useBurn, usePutonSale, useCancelOrder ,useApprovalFunctionCall} from "./useMethods";

export const useMintReceipt = async (Arg,Type,Provider,tokid) =>{
    const web3 = await useWeb3()
    try{
        const Hash = await useMint(Arg,Type,Provider,tokid) 
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt; 
    }
    catch(error)
    {
        //console.log("useMintReceipt-Hookerror",error)
        return false
    }
}

export const useTokenAppReceipt = async (Arg,Type,Provider) =>{
    const web3 = await useWeb3() 
    try
    {
        const Hash = await useTokenApprove(Arg,Type,Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch(error)
    {
        //console.log("useTokenAppReceipt-Hookerror",error)
        return false
    }
}

export const usePurchaseReceipt = async (Arg,Type,Provider,isToken) =>{
    const web3 = await useWeb3()
    try
    {
        const Hash = await usePurchase(Arg,Type,Provider,isToken) 
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch(error)
    {
        //console.log("usePurchaseReceipt-Hookerror",error)
        return false
    }
}

export const useAcceptBidReceipt = async (Arg,Type,Provider) =>{
    const web3 = await useWeb3() 
    try
    {
        const Hash = await useAcceptBid(Arg,Type,Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        //console.log("receipt of accept bid call ",receipt)
        return receipt;
    }
    catch(error)
    {
        //console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}

export const useBurnReceipt = async (Arg,Type,Provider) =>{
    const web3 = await useWeb3() 
    try
    {
        const Hash = await useBurn(Arg,Type,Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch(error)
    {
        //console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const usePutonSaleReceipt = async (Arg,Type,Provider) =>{
    const web3 = await useWeb3() 
    try
    {
        const Hash = await usePutonSale(Arg,Type,Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch(error)
    {
        //console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useCancelOrderReceipt = async (Arg,Type,Provider) =>{
    const web3 = await useWeb3() 
    try
    {
        const Hash = await useCancelOrder(Arg,Type,Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch(error)
    {
        //console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useApprovalReceipt = async (userAddress,Type,conAddress) =>{
        //console.log("approval data ",userAddress,Type,conAddress)
    const web3 = await useWeb3() 
    try
    {
        const Hash = await useApprovalFunctionCall(userAddress,Type,conAddress,true)
        //console.log("hash data ",Hash)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        //console.log("receipt datta",receipt)
        return receipt;
    }
    catch(error)
    {
        //console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}







