/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { isEmail } from "actions/controller/validation";
import { v1_Sub } from "actions/v1/user";
import { sociallinksfunction } from '../../actions/v1/token';
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { toast, Flip } from "react-toastify";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;


  const [EmailID, setEmailID] = useState("");
  const [sociallinks, setsociallinks] = useState({})

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });


   useEffect(() => {
   
    sociallinksfunct()
 
  }, [])

  const NewletterSub = async () => {
    if (isEmail(EmailID)) {
      var Resp = await v1_Sub({ data: EmailID });
      if (Resp.Success) {
        toast.success(Resp.Message, { autoClose: 1500, transition: Flip });
      } else {
        toast.error(Resp.Message, { autoClose: 1500, transition: Flip });
      }
    } else
      toast.error("Enter Valid Mail ID", { autoClose: 1500, transition: Flip });
  };

  const sociallinksfunct = async () => {
    var soci = await sociallinksfunction()
    //console.log("soci",soci.data.soci)
    if (soci !== undefined && soci.data.soci !== undefined) {
      setsociallinks(soci.data.soci)
    }
    else {

      setsociallinks({})
    }
  }


  return (
    <div className="footer_main">
      <footer className={footerClasses}>
        <div className="container container_custom pt-5 pb-4">
          <div className="row">



            <div className="col-12 col-md-6 col-lg-3 mb-lg-0 mb-3">
              <Link to="/">
                <img src={require("../../assets/images/footer_logo.png")} class="img-fluid foot_logo_new" alt="Shape" />
              </Link>
              <div className="mt-3">
              
                  <a href={sociallinks && sociallinks.Facebook && (sociallinks.Facebook != "" ? sociallinks.Facebook : "#")} target="_blank" className="social_blue_icons mr-2">
                    <i class="fab fa-facebook-f"></i>
                  </a>
       
                  <a href={sociallinks && sociallinks.Twitter && (sociallinks.Twitter != "" ? sociallinks.Twitter : "#")} target="_blank" className="social_blue_icons mr-2">
                    <i class="fab fa-twitter"></i>
                  </a>
           
                  <a href={sociallinks && sociallinks.Google && (sociallinks.Google != "" ? sociallinks.Google : "#")} target="_blank" className="social_blue_icons mr-2">
                    <i class="fab fa-google"></i>
                  </a>

              
                  <a href={sociallinks && sociallinks.Instagram && (sociallinks.Instagram != "" ? sociallinks.Instagram : "#")} target="_blank" className="social_blue_icons mr-2">
                    <i class="fas fa-comment-alt"></i>
                  </a>
               
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
              <p className="footer_heade">Metaneum</p>
              <ul className="footer_ul">
                <li>
                  <Link to="/explore">Explore</Link>
                </li>

                <li>
                  <Link to="/connect-wallet">Connect</Link>
                </li>


                {/* <li>
          <Link to="/authors">My Items</Link>
          </li> */}

                <li>
                  <Link to="/my-items">My Items</Link>
                </li>

                <li>
                  <Link to="/create">Create Item</Link>
                </li>
              </ul>

            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <p className="footer_heade">Info</p>
              <ul className="footer_ul">

                <li>
                  <Link to="/how-it-works">How it works</Link>
                </li>
                <li>
                  <Link to="/help">Help</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms of Service</Link>
                </li>

                {/* <Link to="/edit-profile">Profile</Link><Link to="/Following">Following</Link><Link to="/activity">Activity</Link><Link to="/">Platform Status</Link><Link to="/">Partners</Link><Link to="/">Gas-Free Marketplace</Link><Link to="/">Domain Names</Link><Link to="/">Virtual Worlds</Link><Link to="/">Trading Cards</Link><Link to="/">Collectibles</Link><Link to="/">Sports</Link><Link to="/">Utility</Link>*/}
                {/* <Link to="/">All NFTs</Link><Link to="/">New</Link><Link to="/">Art</Link><Link to="/">Music</Link> */}

              </ul>

            </div>


            <div className="col-12 col-md-6 col-lg-3  mt-lg-0 mt-3">
              <p className="footer_heade">Join Newsletter</p>
              <div class="input-group mb-3 mt-3">
                <input type="email" class="newsletter_inp form-control " placeholder="info@yourgmail.com" onChange={(e) => setEmailID(e.target.value)} />
                <div class="input-group-append">
                  <span class="input-group-text p-0">
                    <button class="footer-btn fa fa-paper-plane" type="button"
                      onClick={() => NewletterSub()}></button></span>
                </div>
              </div>

            </div>



          </div>


        </div>
      </footer>
    </div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
