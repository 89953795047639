import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";






import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
//import config from '../../lib/config';
//import SINGLE from '../../ABI/721.json'
//import { useWeb3 } from "hooks/useWeb3";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Account_Connect, Account_disConnect } from "actions/redux/action";

const dashboardRoutes = [];
var temp = 0
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Connect(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const dispatch = useDispatch();
    const Wallet =(a) =>{
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          Wall:a
        }
      })
    }
  
    return (
        <div className="home_header">
        <Header
         color="transparent"
         routes={dashboardRoutes}
         brand={<Link to="/home">
           <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
         leftLinks={<HeaderLinks />}
         fixed
         changeColorOnScroll={{
           height: 20,
           color: "white"
         }}
         {...rest}
       />
       <ScrollToTopOnMount/>
       <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
       <div className="container container_custom">
         <div className="page-head body-top mt-5 pt-5">
         <div>
             <h2 className="page-heading">Connect Wallet</h2>
             <ul className="breadcrums">
                 <li><a href="#">Home</a></li>
                 <li><a href="#"  className="active">Connect Wallet</a></li>
             </ul>
             
         </div>
         </div>
         </div>
 
         <div className="container container_custom mt-5 pb-5">
         <div className="row justify-content-center mt-4">
                 <div className="col-lg-3 col-md-6 col-lg-3 col-sm-6 text-center mb-4">
                   <div className="sell-steps grid-card">   
                   <img src={require("../../assets/images/wallet_01.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" onClick={()=>{
                       localStorage.setItem('walletConnectType','mt');
                       Wallet("mt");
                   }} />
 
                     <h2>MetaMask</h2>
                     <p>One of the most secure wallets with great flexibility</p>
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-lg-3 col-sm-6 text-center mb-4">
                 <div className="sell-steps grid-card">   
                   <img src={require("../../assets/images/wallet_02.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" onClick={()=>{
                       localStorage.setItem('walletConnectType','bcw');
                       Wallet("bcw");
                   }}/>
 
                     <h2>Binance Chain Wallet</h2>
                     <p>Official Binance cryptocurrency wallet for accessing Binance Smart Chain, Binance Chain, and Ethereum</p>
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-lg-3 col-sm-6 text-center mb-4">
                 <div className="sell-steps grid-card">   
                   <img src={require("../../assets/images/wallet_03.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" onClick={()=>{
                       localStorage.setItem('walletConnectType','wc');
                       Wallet("wc");
                   }} />
 
                     <h2>WalletConnect</h2>
                     <p>Communicate securely between Wallets and Dapps</p>
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-lg-3 col-sm-6 text-center mb-4">
                 <div className="sell-steps grid-card">   
                   <img src={require("../../assets/images/wallet_04.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" onClick={()=>{
                       localStorage.setItem('walletConnectType','tw');
                       Wallet("tw");
                   }} />
 
                     <h2>TrustWallet</h2>
                     <p>Official crypto wallet of Binance</p>
                   </div>
                 </div>
               
              
             </div>
           {/* <div className="row">
             <div className="col-12 col-lg-12 mx-auto">
             <ul className="noti_ul_dd">
               <li className="px-3">
               <div className="media align-items-center">
               
                   <img src={require("../assets/images/wallet_01.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                   <div className="media-body flex_body">
                     <div>
                     <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">MetaMask</p>
                     </div>
                     <div>
                       <span className="conet_desc">Popular</span>
                     </div>
                   </div>
                 </div>
                
               </li>
               <li className="px-3">
               <div className="media align-items-center">
               
                   <img src={require("../assets/images/wallet_02.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                   <div className="media-body flex_body">
                     <div>
                     <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">Binance Chain Wallet</p>
                     </div>
                     <div>
                       <span className="conet_desc"></span>
                     </div>
                   </div>
                 </div>
                
                
               </li>
               <li className="px-3">
               <div className="media align-items-center">
               
               <img src={require("../assets/images/wallet_03.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
               <div className="media-body flex_body">
                 <div>
                 <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">WalletConnect</p>
                 </div>
                 <div>
                   <span className="conet_desc"></span>
                 </div>
               </div>
             </div>
            
                
               </li>
               <li className="px-3">
               <div className="media align-items-center">
               
               <img src={require("../assets/images/wallet_04.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
               <div className="media-body flex_body">
                 <div>
                 <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">TrustWallet</p>
                 </div>
                 <div>
                   <span className="conet_desc"></span>
                 </div>
               </div>
             </div>
                
               </li>
             </ul>
             </div>
           </div> */}
        
         </div>
 
 
     
       </div>
       <Footer/>
     </div>
    );
  }
  