import React, { useEffect ,useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


import { MyUserData } from "actions/controller/userControl";
import { MyItemsData } from "actions/controller/tokenControl";
import config from "lib/config";






const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  const [ ShowModal,setShowModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [MyItemNFTs, setMyItemNFTs] = useState({});
  const [TabName, setTabName] = useState();
  const [Address, setAddress] = useState();
  const [userName, setUserName] = useState();
  const [MyUserFollow, setMyUserFollow] = useState({
    followers: { page: 1, list: [], onmore: true },
    activity: { page: 1, list: [], onmore: true },
  });

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  useEffect(()=>{
    
    MyItemTab("activity")
  },[])




  const MyItemTab = async (tab, addr, filter,Pageno) => {
   
    if(tab && !MyItemNFTs[tab])
      {
        setLoader(true);
        MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
        setMyItemNFTs(MyItemNFTs);
      }
    var curraddress = addr ? addr :"";
    var Payload = {
      curraddress: curraddress,
      Tab: tab ? tab : "onsale",
      Action: "Click",
      Limit: 16,
      Page: Pageno || 1,
      filter: filter,
    };
    if (tab === "activity" || tab === "following" || tab === "followers") {
      var myUser = await MyUserData(Payload);
      if (myUser.Success) {
        setTabName(Payload.Tab);
        var temp = MyUserFollow;
        temp[myUser.Tab] = myUser.records;
        setMyUserFollow([]);
        setMyUserFollow(temp);
      } else {
        setTabName(Payload.Tab);
      }
    } else {
      var myNFT = await MyItemsData(Payload);
      if (myNFT.Success) {
        if(Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page)
        {
          MyItemNFTs[myNFT.Tab].page = Pageno
          MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
          MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
        }
        else{
          if(MyItemNFTs[myNFT.Tab].page === 1){
            MyItemNFTs[myNFT.Tab].list = myNFT.records;
            setMyItemNFTs([]);
            setMyItemNFTs(MyItemNFTs);
            setTimeout(() => {
              setShowModal(false)
            }, 1500);
          }
        }
        setTabName(Payload.Tab);
      } else {
        setTabName(Payload.Tab);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  
  return (
    <div className="home_header">
     
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div>
            <h2 className="page-heading">Activity</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Activity</a></li>
            </ul>
            
        </div>
    </div>
        </div>
        <div className="container container_custom explorepage mt-5">
        <GridContainer>
  <GridItem xs={12} sm={12} md={12}>                                       
    <nav className="explore-tab justify-content-start py-0 search_tab">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <a className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
         <span className="tab_head p-2" onClick={() => {
                          MyItemTab("activity");
                        }}>All</span>
          </a>                 
        
          <a className="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false">
          <span className="tab_head p-2"   onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Follow"
                                            );
                                          }}>Following</span>
          </a>
          <a className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">
          <span className="tab_head p-2"  onClick={() => {
                          MyItemTab("activity", Wallet_Details.UserAccountAddr);
                        }}> My Activity</span>
          </a>
      </div>
    </nav>
    <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
      <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">

      <div className="proposal_panel_overall pb-4">
                 
                 <div className="followers_overall py-1">
                 <div className="row mt-3 card_grad_he_full">
                 {MyUserFollow &&
             MyUserFollow[TabName] &&
             TabName === "activity" &&
             MyUserFollow[TabName].length > 0?(
               MyUserFollow[TabName].map((Activity) => { 
                 if(Activity.From.image !=="")
                 var Image = `${config.Back_URL}/Users/${Activity.From.curraddress}/profileImg/${Activity.From.image}`;
                 else
                 var Image = `${require("../assets/images/notable_img_1.png")}`;
 
                 const timediff = (a) =>{
                   var date1 = new Date(Date.now());
                   var date2 = new Date(a);
                   var Difference_In_Time =   date2.getTime() - date1.getTime() ;
                   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                   Difference_In_Days = (Difference_In_Days < 0)?Difference_In_Days*(-1) : Difference_In_Days
                   return (Difference_In_Days).toFixed(1)
                 }
 
                 return(
             <>
                  
                     <div className="col-12 col-md-6 col-xl-4 mb-2">
                     <div className="grid-card activity_card_align">
                         <div>
                         <div className="media follow_media">
                         <div className="tabimg mr-2 tab_image_tick">
                           
                           <img src={Image}  alt="Collections" className="img-fluid" />
                         </div>
                           <div className="media-body flex_body">
                             <div>
                             <p className="mt-0 media_text mb-2">{Activity.action}</p>
                             <div className="ago_card">
                 <p className="mt-0 mb-0"> {((timediff(Activity.created)*10) === 0)? `Just Now` : (timediff(Activity.created) < 2) ? `${(timediff(Activity.created)*10)} Hours Ago` : `${(timediff(Activity.created))} Days Ago`}</p>
             </div>
           {/* <p className="mt-0 media_num mt-0">1 edition purchased by <span className="media_text px-1">0xb6631a76645...b7de</span>for <span className="media_text px-1">2.3B OPEN</span>each from <span className="media_text pl-1">Elite Syrup</span></p> */}
 
           {/* <p className="mt-0 media_num mt-0">{(Activity.action == "Mint")?`${Activity.action} ${Activity.frommsg}`:{(Activity.action == "Like")?}}</p> */}
           <p className="mt-0 media_num mt-0">
             {(Activity.action == "Mint")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.token.tokenName} ${Activity.action} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("....")}`:`${Activity.token.tokenName} ${Activity.action} by you`
               :(Activity.action == "Like")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.token.tokenName}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} liked ${Activity.token.tokenName} `
               :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,4).concat("..")} accepted your bid`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).conccat("...")} now owns ${Activity.NoofNFT} ${Activity.token.tokenName}`
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} for ${Activity.tokenPrice} ${Activity.token.coinname}`:`${Activity.frommsg} ${Activity.tokenPrice} ${Activity.token.coinname} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `  
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Unlimited" )?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} `:` ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `              
                  //:(Activity.action == "Bid" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,8).concat("...")} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.token.tokenName} `:``
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType != "Unlimited" )?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought ${Activity.frommsg}  ${Activity.tokenPrice}   ${Activity.token.coinname}`:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType == "Unlimited")?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought by you  `:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                     :(Activity.action == "Report")?`${Activity.token.tokenName} ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} `
                       :(Activity.action == "Profile")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg}`:` ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat()}`
                       :(Activity.action == "Burn")?` ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} burned ${Activity.NoofNFT} ${Activity.tokenName}`
                      //  :(Activity.action == "Follow")?(Activity.From.curraddress  != Wallet_Details.UserAccountAddr && Activity.To.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,5).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("..")} `:``  
                        // :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} ${(!Activity.tomsg)?Activity.frommsg:Activity.tomsg} `
                        :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} started following ${Activity.To.curraddress == Wallet_Details.UserAccountAddr?"you":`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("...")}`} `
                      //:(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:``
                     // :(Activity.action == "Follow")?(Activity.To.curraddress && Activity.To.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following you`:``
                      //:(Activity.action == "Follow")?( Activity.To.curraddress != Wallet_Details.UserAccountAddr &&  Activity.From.curraddress != Wallet_Details.UserAccountAddr )?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("..")}`:``
                         :(Activity.action == "Salestate")?`${Activity.From.curraddress.slice(0,8).concat("...")}  Listed ${Activity.tokenName} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} `
                         :""}</p>
                         {/* (Activity.To.name)?`${Activity.frommsg} ${Activity.To.name}`:`${Activity.frommsg} ${Activity.To.to.slice(0,5)}`:""}</p> */}
 
                           </div>
                          
                           </div>
                     
                           </div>
                          
                         </div>
                       </div>
                     </div>
                    
                  </>)})):(<>   <div className="text-center py-5 d-none">
                   <p className="not_found_text">No users found</p>
                   <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                   <div className="mt-3">
                   <Link to="/explore/All">
                   <Button className="create_btn">Browse Marketplace</Button>
                   </Link>
                 </div>             
                 </div></>)} </div>
 
                 </div>
 
 
                   </div>


     
      
      </div>                
    
      <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
      <div className="proposal_panel_overall pb-4">
                 
                 <div className="followers_overall py-1">
                 <div className="row mt-3 card_grad_he_full">
                 {MyUserFollow &&
             MyUserFollow[TabName] &&
             TabName === "activity" &&
             MyUserFollow[TabName].length > 0?(
               MyUserFollow[TabName].map((Activity) => { 
                 if(Activity.From.image !=="")
                 var Image = `${config.Back_URL}/Users/${Activity.From.curraddress}/profileImg/${Activity.From.image}`;
                 else
                 var Image = `${require("../assets/images/notable_img_1.png")}`;
 
                 const timediff = (a) =>{
                   var date1 = new Date(Date.now());
                   var date2 = new Date(a);
                   var Difference_In_Time =   date2.getTime() - date1.getTime() ;
                   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                   Difference_In_Days = (Difference_In_Days < 0)?Difference_In_Days*(-1) : Difference_In_Days
                   return (Difference_In_Days).toFixed(1)
                 }
 
                 return(
             <>
                  
                     <div className="col-12 col-md-6 col-xl-4 mb-2">
                     <div className="grid-card activity_card_align">
                         <div>
                         <div className="media follow_media">
                         <div className="tabimg mr-2 tab_image_tick">
                           
                           <img src={Image}  alt="Collections" className="img-fluid" />
                         </div>
                           <div className="media-body flex_body">
                             <div>
                             <p className="mt-0 media_text mb-2">{Activity.action}</p>
                             <div className="ago_card">
                 <p className="mt-0 mb-0"> {((timediff(Activity.created)*10) === 0)? `Just Now` : (timediff(Activity.created) < 2) ? `${(timediff(Activity.created)*10)} Hours Ago` : `${(timediff(Activity.created))} Days Ago`}</p>
             </div>
           {/* <p className="mt-0 media_num mt-0">1 edition purchased by <span className="media_text px-1">0xb6631a76645...b7de</span>for <span className="media_text px-1">2.3B OPEN</span>each from <span className="media_text pl-1">Elite Syrup</span></p> */}
 
           {/* <p className="mt-0 media_num mt-0">{(Activity.action == "Mint")?`${Activity.action} ${Activity.frommsg}`:{(Activity.action == "Like")?}}</p> */}
           <p className="mt-0 media_num mt-0">
             {(Activity.action == "Mint")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.token.tokenName} ${Activity.action} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("....")}`:`${Activity.token.tokenName} ${Activity.action} by you`
               :(Activity.action == "Like")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.token.tokenName}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} liked ${Activity.token.tokenName} `
               :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,4).concat("..")} accepted your bid`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).conccat("...")} now owns ${Activity.NoofNFT} ${Activity.token.tokenName}`
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} for ${Activity.tokenPrice} ${Activity.token.coinname}`:`${Activity.frommsg} ${Activity.tokenPrice} ${Activity.token.coinname} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `  
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Unlimited" )?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} `:` ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `              
                  //:(Activity.action == "Bid" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,8).concat("...")} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.token.tokenName} `:``
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType != "Unlimited" )?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought ${Activity.frommsg}  ${Activity.tokenPrice}   ${Activity.token.coinname}`:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType == "Unlimited")?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought by you  `:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                     :(Activity.action == "Report")?`${Activity.token.tokenName} ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} `
                       :(Activity.action == "Profile")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg}`:` ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat()}`
                       :(Activity.action == "Burn")?` ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} burned ${Activity.NoofNFT} ${Activity.tokenName}`
                      //  :(Activity.action == "Follow")?(Activity.From.curraddress  != Wallet_Details.UserAccountAddr && Activity.To.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,5).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("..")} `:``  
                        // :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} ${(!Activity.tomsg)?Activity.frommsg:Activity.tomsg} `
                        :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} started following ${Activity.To.curraddress == Wallet_Details.UserAccountAddr?"you":`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("...")}`} `
                      //:(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:``
                     // :(Activity.action == "Follow")?(Activity.To.curraddress && Activity.To.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following you`:``
                      //:(Activity.action == "Follow")?( Activity.To.curraddress != Wallet_Details.UserAccountAddr &&  Activity.From.curraddress != Wallet_Details.UserAccountAddr )?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("..")}`:``
                         :(Activity.action == "Salestate")?`${Activity.From.curraddress.slice(0,8).concat("...")}  Listed ${Activity.tokenName} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} `
                         :""}</p>
                         {/* (Activity.To.name)?`${Activity.frommsg} ${Activity.To.name}`:`${Activity.frommsg} ${Activity.To.to.slice(0,5)}`:""}</p> */}
 
                           </div>
                          
                           </div>
                     
                           </div>
                          
                         </div>
                       </div>
                     </div>
                    
                  </>)})):(<>   <div className="text-center py-5 d-none">
                   <p className="not_found_text">No users found</p>
                   <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                   <div className="mt-3">
                   <Link to="/explore/All">
                   <Button className="create_btn">Browse Marketplace</Button>
                   </Link>
                 </div>             
                 </div></>)} </div>
 
                 </div>
 
 
                   </div>
      
      </div>
      <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
      <div className="proposal_panel_overall pb-4">
                 
                 <div className="followers_overall py-1">
                 <div className="row mt-3 card_grad_he_full">
                 {MyUserFollow &&
             MyUserFollow[TabName] &&
             TabName === "activity" &&
             MyUserFollow[TabName].length > 0?(
               MyUserFollow[TabName].map((Activity) => { 
                 if(Activity.From.image !=="")
                 var Image = `${config.Back_URL}/Users/${Activity.From.curraddress}/profileImg/${Activity.From.image}`;
                 else
                 var Image = `${require("../assets/images/notable_img_1.png")}`;
 
                 const timediff = (a) =>{
                   var date1 = new Date(Date.now());
                   var date2 = new Date(a);
                   var Difference_In_Time =   date2.getTime() - date1.getTime() ;
                   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                   Difference_In_Days = (Difference_In_Days < 0)?Difference_In_Days*(-1) : Difference_In_Days
                   return (Difference_In_Days).toFixed(1)
                 }
 
                 return(
             <>
                  
                     <div className="col-12 col-md-6 col-xl-4 mb-2">
                     <div className="grid-card activity_card_align">
                         <div>
                         <div className="media follow_media">
                         <div className="tabimg mr-2 tab_image_tick">
                           
                           <img src={Image}  alt="Collections" className="img-fluid" />
                         </div>
                           <div className="media-body flex_body">
                             <div>
                             <p className="mt-0 media_text mb-2">{Activity.action}</p>
                             <div className="ago_card">
                 <p className="mt-0 mb-0"> {((timediff(Activity.created)*10) === 0)? `Just Now` : (timediff(Activity.created) < 2) ? `${(timediff(Activity.created)*10)} Hours Ago` : `${(timediff(Activity.created))} Days Ago`}</p>
             </div>
           {/* <p className="mt-0 media_num mt-0">1 edition purchased by <span className="media_text px-1">0xb6631a76645...b7de</span>for <span className="media_text px-1">2.3B OPEN</span>each from <span className="media_text pl-1">Elite Syrup</span></p> */}
 
           {/* <p className="mt-0 media_num mt-0">{(Activity.action == "Mint")?`${Activity.action} ${Activity.frommsg}`:{(Activity.action == "Like")?}}</p> */}
           <p className="mt-0 media_num mt-0">
             {(Activity.action == "Mint")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.token.tokenName} ${Activity.action} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("....")}`:`${Activity.token.tokenName} ${Activity.action} by you`
               :(Activity.action == "Like")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.token.tokenName}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} liked ${Activity.token.tokenName} `
               :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,4).concat("..")} accepted your bid`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).conccat("...")} now owns ${Activity.NoofNFT} ${Activity.token.tokenName}`
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Auction")?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} for ${Activity.tokenPrice} ${Activity.token.coinname}`:`${Activity.frommsg} ${Activity.tokenPrice} ${Activity.token.coinname} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `  
                  :(Activity.action == "Bid" && Activity.token.PutOnSaleType == "Unlimited" )?(Activity.From.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} ${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} `:` ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.NoofNFT}  ${Activity.token.tokenName} `              
                  //:(Activity.action == "Bid" && Activity.ifBid == "Accept")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,8).concat("...")} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} for ${Activity.token.tokenName} `:``
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType != "Unlimited" )?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought ${Activity.frommsg}  ${Activity.tokenPrice}   ${Activity.token.coinname}`:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                   :(Activity.action == "BuySell" && Activity.token.PutOnSaleType == "Unlimited")?(Activity.to == Wallet_Details.UserAccountAddr )?` ${Activity.NoofNFT}  ${Activity.token.tokenName} bought by you  `:`${Activity.folmsg} ${Activity.NoofNFT}  ${Activity.token.tokenName} ${Activity.To.name?Activity.To.name:Activity.to.slice(0,6).concat("...")} `
                     :(Activity.action == "Report")?`${Activity.token.tokenName} ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,8).concat("...")} `
                       :(Activity.action == "Profile")?(Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg}`:` ${Activity.frommsg} by ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat()}`
                       :(Activity.action == "Burn")?` ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} burned ${Activity.NoofNFT} ${Activity.tokenName}`
                      //  :(Activity.action == "Follow")?(Activity.From.curraddress  != Wallet_Details.UserAccountAddr && Activity.To.curraddress != Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,5).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("..")} `:``  
                        // :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} ${(!Activity.tomsg)?Activity.frommsg:Activity.tomsg} `
                        :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} started following ${Activity.To.curraddress == Wallet_Details.UserAccountAddr?"you":`${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,5).concat("...")}`} `
                      //:(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.frommsg} ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("...")}`:``
                     // :(Activity.action == "Follow")?(Activity.To.curraddress && Activity.To.curraddress == Wallet_Details.UserAccountAddr)?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following you`:``
                      //:(Activity.action == "Follow")?( Activity.To.curraddress != Wallet_Details.UserAccountAddr &&  Activity.From.curraddress != Wallet_Details.UserAccountAddr )?`${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("..")} started following ${Activity.To.name?Activity.To.name:Activity.To.curraddress.slice(0,6).concat("..")}`:``
                         :(Activity.action == "Salestate")?`${Activity.From.curraddress.slice(0,8).concat("...")}  Listed ${Activity.tokenName} ${Activity.frommsg} ${Activity.tokenPrice} ${Activity.istoken} `
                         :""}</p>

{/* {(Activity.action == "Mint")?`${Activity.token.tokenName} ${Activity.action} ${Activity.frommsg}`
               :(Activity.action == "Like")?`${Activity.frommsg} ${Activity.token.tokenName}`
                 :(Activity.action == "Bid")?`${Activity.frommsg} ${Activity.tokenPrice}  ${Activity.token.coinname} each for ${Activity.NoofNFT} ${Activity.token.tokenName}`
                   :(Activity.action == "BuySell")?`${Activity.frommsg} ${Activity.tokenPrice}  ${Activity.token.coinname} each for ${Activity.NoofNFT} ${Activity.token.tokenName} from ${Activity.To.curraddress.slice(0,6).concat("...")}`
                     :(Activity.action == "Report")?`${Activity.token.tokenName} ${Activity.frommsg} `
                       :(Activity.action == "Profile")?` ${Activity.frommsg} `
                       :(Activity.action == "Burn")?` ${Activity.From.name?Activity.From.name:Activity.From.curraddress.slice(0,6).concat("...")} burned ${Activity.tokenN}`
                         :(Activity.action == "Follow")?(Activity.From.curraddress && Activity.From.curraddress == Wallet_Details.UserAccountAddr)?(Activity.To.name)?`${Activity.frommsg} ${Activity.To.name}`:`${Activity.frommsg} ${Activity.To.to.slice(0,5)}`
                            :` ${Activity.To.name} ${Activity.tomsg}`:""}</p> */}
                         {/* (Activity.To.name)?`${Activity.frommsg} ${Activity.To.name}`:`${Activity.frommsg} ${Activity.To.to.slice(0,5)}`:""}</p> */}
 
                           </div>
                          
                           </div>
                     
                           </div>
                          
                         </div>
                       </div>
                     </div>
                    
                  </>)})):(<>   <div className="text-center py-5 d-none">
                   <p className="not_found_text">No users found</p>
                   <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                   <div className="mt-3">
                   <Link to="/explore/All">
                   <Button className="create_btn">Browse Marketplace</Button>
                   </Link>
                 </div>             
                 </div></>)} </div>
 
                 </div>
 
 
                   </div>
      
      </div>
    </div>
  </GridItem>
</GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
