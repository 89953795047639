import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { v1_Faq } from "actions/v1/user";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3a89b5;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3a89b5;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function HowItWorks(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const [Faqlist,setFaqlist]=useState([])

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const GetFaq = async()=>{
    var Resp = await v1_Faq()
    var userval =Resp.userValue
    var faq = userval.filter((item)=> item.status == 'faq')
//console.log("bdghjvkjhdsbvfjdsbfkjusdvfudvs",faq);
    if(faq){
      //console.log("how ir worm",faq)
        setFaqlist(faq)}
    else
      toast.error("Error on Server",{autoClose:2000})
  }
  useEffect(()=>{
    GetFaq()
  },[])




  return (
    <div className="home_header">
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className="container container_custom">
        <div className="page-head body-top mt-5 pt-5">
        <div className="">
            <h2 className="page-heading">How It Works</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">How It Works</a></li>
            </ul>
            
        </div>
    </div>
          
        </div>
        <div className="container container_custom mt-5 pb-5 px-0">
          <GridContainer className="justify-content-center">
            <GridItem>
              <div className="faq_panel">
                
              {Faqlist.length > 0 && Faqlist.map((faq,i)=>{
                 return(
                <Accordion expanded={expanded ===`panel${i+1}`} onChange={handleChangeFaq(`panel${i+1}`)} className="mt-5">
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <div className="accordian_head">
                      <h2>{faq.question}</h2>
                      </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                    {ReactHtmlParser(faq.answer)}
                    </div>
                  </AccordionDetails>
                </Accordion>
                  )})}
              </div>
            </GridItem>
            
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
