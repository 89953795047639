import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTokenDecimal, usePrevious } from "hooks/useMethods";
import { usePutonSaleReceipt } from "hooks/useReceipts";
import {
  YouwillGetCalculation,
  PurchaseCalculation,
} from "actions/controller/PriceCalculate";
import { v1_UpdateNFTState } from "actions/v1/token";
import { useCancelOrderReceipt } from "hooks/useReceipts";
import { Audio, TailSpin } from "react-loader-spinner";
import { PopupValidation } from "actions/controller/validation";
import { toast } from "react-toastify";
import config from "lib/config";
import Select from "react-select";

export const PlaceandCancel = forwardRef((props, ref) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const [NewTokenDecimal, setNewTokenDecimal] = useState("");
  const [NFTImage, setNFTImage] = useState("");
  const [NFT, setNFT] = useState({ CurrentOwner: {} });
  const [TokenPrice, setTokenPrice] = useState(0);
  const prevTokenPrice = usePrevious(TokenPrice);
  const [Coinname,setCoinname]=useState("")
  const [NFTDet, setNFTDet] = useState({});
  const [GetPrices, setGetPrices] = useState({
    Youwillpay: 0,
    PriceWithSerfee: "",
    ApprovePriceWithSerfee: "",
    PriceWithoutSerfee: "",
  });
  const [Prices, setPrices] = useState({
    Youwillpay: 0,
    PriceWithSerfee: "",
    ApprovePriceWithSerfee: "",
    PriceWithoutSerfee: "",
  });
  const [PlaceSate, setPlaceSate] = useState(false);
  const [CancelSate, setCancelSate] = useState(false);
  const [ bidTokenOptions , SetBidTokenOptions ] = useState([])

  useImperativeHandle(ref, () => ({
    async PlaceandCancelClick(ClickedNFT, NFTimage, NFT) {
      //console.log("nft details dtata plce ",ClickedNFT)
      setNFTImage(NFTimage);
      setNFTDet(NFT);
      var TokenDecimal = await useTokenDecimal(
        { Coin: config.tokenSymbol },
        721
      );
      setNewTokenDecimal(TokenDecimal);
      if (Wallet_Details.UserAccountAddr) {
        setNFT(ClickedNFT);
        var data = {
          Price: TokenPrice,
          Quantity: 1,
          Serfee: Wallet_Details.Service_Fee,
          Decimal: TokenDecimal,
        };
        var Data = YouwillGetCalculation(data);
        var Data2 = PurchaseCalculation(data);
        setPrices(Data2);
        setGetPrices(Data);
        //console.log("jdsnbfufnuewrgn", ClickedNFT, NFTimage, Data);
      }
    },
  }));

  useEffect(() => {
    if (TokenPrice !== 0 && prevTokenPrice !== TokenPrice) {
      var data = {
        Price: TokenPrice,
        Quantity: 1,
        Serfee: Wallet_Details.Service_Fee,
        Decimal: NewTokenDecimal,
      };
      var Data = YouwillGetCalculation(data);
      var Data2 = PurchaseCalculation(data);
      //console.log("Pricesssssss", Data2);
      setPrices(Data2);
      setGetPrices(Data);
    }
  }, [TokenPrice]);

  useEffect(()=>{
    if(Wallet_Details.TokenList.length > 0)
    {
    //console.log("wallet dtails toke list ",Wallet_Details.TokenList)
    var toklist = Wallet_Details.TokenList
    var bidTokenArr = toklist.filter((token)=>token.name != "BNB")
    //console.log("array of tokens ",bidTokenArr)
    SetBidTokenOptions(bidTokenArr)
    }
  },[Wallet_Details.TokenList])

  const ValidCheck = () => {
    var ValChk = {
      TokenPrice: TokenPrice,
      NoofToken: NFT.balance,
      type: NFT.type,
      MaxToken: NFT.balance,
    };
    var Valid = PopupValidation(ValChk);
    if (Valid.Success) {
      PlaceCall();
    } else toast.error(Valid.Message, { theme: "dark" });
  };

  const PlaceCall = async () => {
    setPlaceSate(true);
    var Arg = {
      NFTOwner: NFT.tokenOwner,
      NFTId: NFT.tokenID,
      PriceWithoutSerfee: Prices.PriceWithoutSerfee,
      PriceWithSerfee: Prices.PriceWithSerfee,
      NoofNFTs: 1,
    };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    var Receipt = await usePutonSaleReceipt(Arg, Type, Provider);
    if (Receipt.status) {
      var Payload = {
        Status: Receipt.status,
        Hash: Receipt.transactionHash,
        ConAddr: Receipt.to,
        tokenPrice: TokenPrice,
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        coinname: Coinname,
        tokenName: NFTDet.tokenName,
      };
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        $("#putonsale_close").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    }
  };

  const CancelCall = async () => {
    setCancelSate(true);
    var Arg = { NFTId: NFT.tokenID };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    var Receipt = await useCancelOrderReceipt(Arg, Type, Provider);
    if (Receipt.status) {
      setCancelSate(false);
      var Payload = {
        Status: Receipt.status,
        Hash: Receipt.transactionHash,
        ConAddr: Receipt.to,
        tokenPrice: "0",
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        coinname: Coinname,
        tokenName: NFTDet.tokenName,
      };
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        $("#cancel_closs").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    }
  };

  return (
    <>
      {/* change_price Modal */}
      <div
        className="modal fade primary_modal"
        id="change_price_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change_price_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            {!(GetPrices.PriceWithSerfee === "") ? (
              <>
                <div>
                  <div className="modal-header text-center">
                    <h5 className="modal-title" id="change_price_modalLabel">
                      {!NFT.tokenPrice? "Put On Sale" : "Change Price"}
                    </h5>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                      NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      {!NFT.tokenPrice 
                        ? `Your About to Place ${NFTDet.tokenName} On Sale`
                        : `Your About to Change Price for ${NFTDet.tokenName}`}
                    </p>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="putonsale_close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body px-0 pt-0">
                    <form className="px-4 bid_form">
                      <div>
                        <label className="text-center" htmlFor="bid">
                          {!NFT.tokenPrice 
                            ? `On Sale Price - ${TokenPrice}`
                            : `New Price ${TokenPrice}`}{" "}
                            {Coinname}
                          {/* {config.currencySymbol} */}
                        </label>
                      </div>
                      <div class="input-group-append">
                      <Select
                          className="yes1 form-control primary_inp select1 selxet_app"

                          id="tokenCategory"
                                              name="tokenCategory"
                                              // onChange={handleChange}
                                              // options={options1}
                                              onChange={(e)=>{setCoinname(e.name)}}
                                              options={Wallet_Details.TokenList}
                                              label="Single select"
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                        />
                      </div>
                      <div className="input-group mb-2 input_grp_style_1">
                        <input
                          type="number"
                          id="bid"
                          onChange={(e) => {
                            setTokenPrice(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Enter new price"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            {/* {config.currencySymbol} */}
                            {Coinname}
                          </span>
                        </div>
                      </div>
                      {NFT.tokenPrice !== 0 && (
                        <p className="form_note">
                          {/* Price must be less than the actual price{" "} */}
                          {NFT.tokenPrice} {NFT.coinname}
                          {/* {config.currencySymbol} */}
                        </p>
                      )}
                      {/* <label htmlFor="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
    <div className="mb-3 input_grp_style_1">
  <input type="text" id="qty" className="form-control" placeholder="1"  />
 
</div> */}
                      <div className="row pb-3">
                        <div className="col-12 col-sm-6">
                          <p className="buy_desc_sm">Seller Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 text-sm-right">
                          <p className="buy_desc_sm_bold">
                            {Wallet_Details.Service_Fee.Seller}%
                          </p>
                        </div>
                      </div>
                      <div className="row pb-3">
                        <div className="col-12 col-sm-6">
                          <p className="buy_desc_sm">You will get</p>
                        </div>
                        <div className="col-12 col-sm-6 text-sm-right">
                          <p className="buy_desc_sm_bold">
                            {GetPrices.PriceWithSerfee}
                          </p>
                        </div>
                      </div>

                      <div className="text-center">
                        <Button
                          className="create_btn btn-block"
                          disabled={PlaceSate}
                          onClick={() => {
                            ValidCheck();
                          }}
                        >
                          {" "}
                          {PlaceSate && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}
                          {!NFT.tokenPrice 
                            ? "Put On Sale"
                            : "Change Price"}
                        </Button>
                        <Button
                          className="btn_outline_red btn-block"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={PlaceSate}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <>
                <TailSpin
                  wrapperClass="reactloader mt-0 mb-3 react_loder_modal"
                  color="#00BFFF"
                  height={100}
                  width={70}
                  
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* end change_price modal */}

      {/* cancel_order Modal */}
      <div
        className="modal fade primary_modal"
        id="cancel_order_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel_order_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="cancel_order_modalLabel">
                Delete Instant Sale
              </h5>
              {!NFT.tokenPrice ? (
                <>
                  <TailSpin
                    wrapperClass="reactloader mt-0 mb-3 react_loder_modal"
                    color="#00BFFF"
                    height={100}
                    width={70}
                    
                  />
                </>
              ) : (
                <>
                  <div>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                      NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      You are about to delete Instant Sale for{" "}
                      {NFTDet.tokenName}  {NFTDet.Coinname}
                    </p>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="cancel_closs"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body px-0 pt-0">
                      <form className="px-4 bid_form">
                        <label htmlFor="bid" className="text-center">
                          {NFT.tokenPrice}  {NFT.coinname}
                          
                        </label>

                        <div className="text-center mt-3">
                          <Button
                            className="create_btn btn-block"
                            disabled={CancelSate}
                            onClick={() => {
                              CancelCall();
                            }}
                          >
                            {CancelSate && (
                              <i
                                class="fa fa-spinner mr-3 spinner_icon"
                                aria-hidden="true"
                              ></i>
                            )}
                            Delete Instant Sale
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={CancelSate}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end cancel_order modal */}
    </>
  );
});
