import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Proceedmodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
 <div className="modal fade primary_modal" id="proceed_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">                         
              <form> 
              <p className="mt-0 purchase_text text-center">Purchase</p>
              <p className="mt-0 purchase_desc text-center">Send transaction with your wallet</p>

              <div className="text-center my-3">
              <Button className="btn_outline_red btn-block">Inprogress</Button>                
              </div>
            
             
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
