import React, {
  forwardRef,
  useImperativeHandle,
  useState
} from 'react';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import config from '../../lib/config';
import { useSelector } from 'react-redux';




export const Share = forwardRef((props, ref) => {
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const [NFTLink, SetNFTLink] = React.useState({});
  const [NFTName, setNFTName] = React.useState({});
  const [UserName,setUserName] = useState('NFT')

  useImperativeHandle(
      ref,
      () => ({
          async ShareSocial_Click(items, NFTName, page) {
              if (items) {
                if(page !== 'info')
                    setUserName('A Opensale Artist')
                setNFTName(NFTName)
                SetNFTLink(items)
                  window.$('#share_modal').modal('show');

              }
          }
      }),
  )

  return(<>
    {/* SHARE Modal */}
    <div
        className="modal fade primary_modal"
        id="share_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="share_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="share_modalLabel">
                Share
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row justify-content-center mx-0">
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <TelegramShareButton
                      title={`${NFTName}  ${UserName}`}
                      url={`${NFTLink}` + " " +`${Wallet_Details.shareTag[0]}`+" "+`${Wallet_Details.shareTag[1]}` +" " +`${(Wallet_Details.shareTag[2])}`}
                  >
                    <i className="fab fa-telegram-plane"></i>
                    <p>Telegram</p>
                    </TelegramShareButton>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <TwitterShareButton
                      title={`${NFTName}  ${UserName}`}
                      url={`${NFTLink}` + " " +`${Wallet_Details.shareTag[0]}`+" "+`${Wallet_Details.shareTag[1]}` +" " +`${(Wallet_Details.shareTag[2])}`}  
                  >
                    <i className="fab fa-twitter"></i>
                    <p>Twitter</p>
                    </TwitterShareButton>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <FacebookShareButton
                      quote={`${NFTName} ${UserName}`}
                      url={`${NFTLink}` + " " +`${Wallet_Details.shareTag[0]}`+" "+`${Wallet_Details.shareTag[1]}` +" " +`${(Wallet_Details.shareTag[2])}`}
                  >
                    <i className="fab fa-facebook-f"></i>
                    <p>Facebook</p>
                    </FacebookShareButton>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <WhatsappShareButton
                      title={`${NFTName}  ${UserName}`}
                      url={`${NFTLink}` + " " +`${Wallet_Details.shareTag[0]}`+" "+`${Wallet_Details.shareTag[1]}` +" " +`${(Wallet_Details.shareTag[2])}`}
                    >
                    <i className="fab fa-whatsapp"></i>
                    <p>Whatsapp</p>
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end SHARE modal */}
  </>)})