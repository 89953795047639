import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField,Menu,MenuItem,Box,InputLabel,FormControl,Select, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import checkimg from "assets/images/eth.png"
import checkimg1 from "assets/images/polygon.svg"
import checkimg2 from "assets/images/klaytn.png"

import img2 from "assets/images/large-profile-tick.png"



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Myitems(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const[cngImage, setCngImage] = useState(false)
  const[sidebar, setSidebar] = useState(false);
//   const[imgs,set_imgs] = useState(img1)
  
 
  function func(e){
      var l =  document.getElementById("chainbtn");
      for(var i = 0; i<= l.lenght-1; i++){
        var elem = e.target.children(i);
        //console.log(elem);
        debugger;
        toggleimage();

      }
   
    var elem = e.target.children[0];
    //console.log(elem);
    toggleimage();
 };
  

 function changeImage(e)
 {
     //console.log(e.target.id,document.getElementById(e.target.id+"_img").src);
        document.getElementById(e.target.id+"_img").src = img2;
       
        
 }
  function toggleimage() {
    setCngImage(!cngImage);
}


function setBody()
{
    setSidebar(!sidebar);
    var bodyScroll = document.getElementsByTagName("html");
    // //console.log(usebody, "usebody home");
    for (var j = 0; j < bodyScroll.length; j++) {
        bodyScroll[j].classList.toggle('overflow_body')
    }

}
// var CheckImg= cngImage ? img1 : img2
var imgeClass=sidebar? " col-sm-6 col-md-4 col-lg-4" : " col-sm-6 col-md-4 col-lg-4" 

  return (
     
    <div className="home_header newmyitems">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader items_header"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                <div className="overlay_my_bg"></div>
              <img src={require("../assets/images/my_items_baner.png")} alt="profile" className="img-fluid" />
              <div className="container container_full edit_cover_container">
              <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>
              </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="inner_pagh_bg_1">
        <div className="container container_full">
        <div className="item_prof">
              
                <div className="item_prof_img">
                    <img src={require("../assets/images/logo_big_iocn.png")} alt="profile" className="img-fluid items_profile" />
               </div>
               <div className="sociallinks">
               <div className="d-flex state">
                    <div className="">
                        <i className="fab fa-discord"></i>
                    </div>
                    <div className="">
                    <i class="fas fa-th-large"></i>
                    </div>
                    <div className="">
                    <i class="fab fa-medium-m"></i>
                    </div>
                    <div className="">
                    <i class="fab fa-instagram"></i>
                    </div>
                   <div>

                   <div className="dropdown dd_myitems_main">
                            <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i> 
                            </button>
                            <div className="dropdown-menu dropdown-right dd_myitems_menu" aria-labelledby="dropdownMenuButton">
                              <div className="dropdown-item"><a href="#">Profile</a></div>
                              <div className="dropdown-item"><a href="#">My account</a></div>
                              <div className="dropdown-item"><a href="#">Logout</a></div>
                            </div>
                          </div>
                    {/* <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                <Button variant="contained" {...bindTrigger(popupState)}>
                                <i class="fas fa-ellipsis-v"></i>
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={popupState.close}>Profile</MenuItem>
                                    <MenuItem onClick={popupState.close}>My account</MenuItem>
                                    <MenuItem onClick={popupState.close}>Logout</MenuItem>
                                </Menu>
                                </React.Fragment>
                            )}
                        </PopupState> */}
                   </div>
                    
                </div>
              </div>
             
              </div>
        </div>
        <div className="container container_full">
          <div className="row">
          <div className="col-12 text-center">
                <p className="my_items_head">OpenSaleArt</p>
                <p className="my_items_head_1">@OpenSale</p>

                <div class="input-group mb-2 inut_gro_stke_1">
                <input type="text" class="form-control" disabled value="0x3f2ccfa8367543ndb62dde0x3f2ccfa8367543ndb62dde" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">
                <img src={require("../assets/images/copy_icon.png")} className="img-fluid cur_pointer"/>
                </span>
                </div>
                </div>

                <p className="my_items_head_1">Unique Abstract Art NFTs</p>
                <div className="d-flex state abstract_stae">
                    <div className="">
                        <h3> 9.7K</h3>
                        <p>items</p>  
                    </div>
                    <div className="">
                        <h3> 3.9K</h3>
                        <p>owners</p>  
                    </div>
                    <div className="">
                        <h3>0.499</h3>
                        <p>floor price</p>  
                    </div>
                    <div className="">
                        <h3>3.0K</h3>
                        <p>volume traded</p>  
                    </div>
               


                </div>


          </div>
          <div className="col-12 text-center">
                    <p className="join_head">
                    <span>Joined January 2022</span>
                    
                    <button className="create_btn btn_rect" data-toggle="modal" data-target="#share_modal">
                            <i class="fas fa-upload pr-2"></i>
                            Share
                            </button></p>

                    <p className="my_items_desc_1 mb-0 mt-2">NFT Marketplace is the glorified space that adds glory to individual intellectuals.</p>
                    <p className="my_items_desc_1 mb-0">People get to showcase their collective ability and get benefit of their modes to be listen on other Marketplaces in the trends.</p>
                    <p className="my_items_desc_1">The Marketplaces is a direct entity ensuring talents to showcase their exclusive attributes in a variety of domains.</p>

                    <p>
                    <a href="#" target="_blank" className="social_a_icon">
                    <img src={require("../assets/images/twitter_icon.png")} className="img-fluid"/>
                    <span>OpenSaleNFT</span>
                    </a>

                    <a href="#" target="_blank" className="social_a_icon pl-3">
                    <img src={require("../assets/images/insta_icon.png")} className="img-fluid"/>
                    <span>OpenSale_io</span>
                    </a>
                    </p>

            </div>
            </div>
        </div>
        <button class="btn btn-link filter-icon px-4 filterbtn mobilebtn create_btn" onClick={setBody}>
                            <span>Filter</span> 
                            <label className="mb-0">1</label>
                            </button>



        <div className="newclass">
            <div className="filtersec d-flex">
                <div className={sidebar ? "sidehide  filter" : "sideshow  filter"}>
                   

                 
                <button class="btn btn-link filter-icon px-4 filterbtn filter_btn_scroll_web" onClick={() => setSidebar(!sidebar)}>
                            <span>Filter</span> 
                            <i class="fas fa-arrow-left" id="filter_icon_right"></i>
                            </button>
<button class="btn btn-link filter-icon px-4 filterbtn filter_btn_scroll" onClick={setBody}>
                            <span>Filter</span> 
                            <i class="fas fa-arrow-left" id="filter_icon_right"></i>
                            </button>
                    <div className= "filteritems"  > 
                        <div id="accordion">
                        <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <span>Status</span> 
                            <i class="fas fa-chevron-down"></i>
                            </button>
                        </h5>
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <div className="stabtn">
                                 <Button variant="outlined">Buy New</Button>
                                <Button variant="outlined">On Auction</Button>
                                <Button variant="outlined">New</Button>
                                <Button variant="outlined">Has Offer</Button>
                            </div>
                           

                            </div>
                        </div>
                    </div>

                    <div id="accordion">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                <span>Price</span> 
                                <i class="fas fa-chevron-down"></i>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <div className="pricefil">
                                    <select class="form-control mb-4">
                                    <option>Small select</option>
                                    <option>Small select</option>
                                    <option>Small select</option>
                                    <option>Small select</option>
                                    </select>
                                    
                                    <div className="d-flex align-items-center justify-content-between gap-1 minmax">
                                      <div className="input-group sideinput">
                                        <input type="text" className="form-control" placeholder="Min"  />
                                        </div>
                                        to
                                        <div className="input-group sideinput">
                                        <input type="text" className="form-control" placeholder="Max"  />
                                        </div>
                                    </div>
                                    <Button disabled variant="outlined" className="mt-3 create_btn btn_rect">Apply </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordion">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                <span>Chains</span> 
                                <i class="fas fa-chevron-down"></i>
                                </button>
                            </h5>
                        </div>
                      
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                               <div  className="" id="chainbtn">
                                   <button className="btn chainimg" id="chainimg1" onClick={changeImage}> 
                                            <img src={checkimg} alt="img1" id="chainimg1_img" />
                                       Ethereum
                                    </button>
                                    <button className="btn chainimg" id="chainimg2" onClick={changeImage}> 
                                       
                                            <img src={checkimg1} alt="img2" id="chainimg2_img"/>
                                       
                                       Polygon
                                    </button>
                                    <button className="btn chainimg" id="chainimg3" onClick={changeImage}> 
                                            <img src={checkimg2} alt="img3" id="chainimg3_img"/>
                                       Klaytn
                                    </button>
                                   
                               </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordion">
                        <div class="card-header" id="headingFour">
                            <h5 class="mb-0">
                                <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                <span>On sale In</span> 
                                <i class="fas fa-chevron-down"></i>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                            <div class="card-body">
                                <div>
                                    <ul>
                                        <li className="custcheck">
                                            <input type="checkbox"/>
                                            ETH
                                            <span className="check"></span>
                                        </li>
                                        <li className="custcheck">
                                            <input type="checkbox"/>
                                            WETH
                                            <span className="check"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordion">
                        <div class="card-header" id="headingFive">
                            <h5 class="mb-0">
                                <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                
                                <span><i class="fas fa-list mr-2"></i>Backgroud</span> 
                                <i>25<i class="fas fa-chevron-down ml-2"></i></i>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                            <div class="card-body">
                               <div className="searchfils">
                                   <div className="searchinput">
                                        <div class="input-group sideinput mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i className="fa fa-search"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                                        </div>
                                        <ul>
                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Gray</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>
                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Blush</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>

                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Sky</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>
                                      
                                    </ul>

                                       
                                   </div>
                               </div>
                            </div>
                        </div>
                        
                    </div>

                    <div id="accordion">
                        <div class="card-header" id="headingSix">
                            <h5 class="mb-0">
                                <button class="btn btn-link filter-icon" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                
                                <span><i class="fas fa-list mr-2"></i>Eyes</span> 
                                <i>25<i class="fas fa-chevron-down ml-2"></i></i>
                                </button>
                            </h5>
                        </div>
                        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                            <div class="card-body">
                               <div className="searchfils">
                                   <div className="searchinput">
                                        <div class="input-group sideinput mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i className="fa fa-search"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                                        </div>
                                        <ul>
                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Gray</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>
                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Blush</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>

                                        <li className="custcheck d-flex">
                                            <span>
                                            <input type="checkbox"/>
                                            <span className="check"></span>
                                          
                                            <span>Sky</span>
                                            </span>
                                            <span>410</span>
                                          
                                            
                                        </li>
                                      
                                    </ul>
                                   </div>
                               </div>
                            </div>
                        </div>
                        
                    </div>
             </div>
                </div>
                <div className={sidebar ? "expand" : "shrink" + " w-full"}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>                                       
                        <nav className="masonry_tab_nav  mb-3 items_tab_outer filtertab">
                            <div className="nav nav-tabs masonry_tab primary_tab items_tab items_tab_new pb-0 pl-0" id="nav-tab" role="tablist">
                           

                                <a className="nav-link active" id="saved-tab" data-toggle="tab" href="#saved" role="tab" aria-controls="liked" aria-selected="false">
                            <div className="tab_head">Saved</div>
                            
                                </a> 
                                <a className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">
                            <div className="tab_head">Activity</div>
                        
                                </a>
                             
                            </div>
                        </nav>
                        <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                         

                            <div className="tab-pane fade show active" id="saved" role="tabpanel" aria-labelledby="saved-tab">
                            <div className="proposal_panel_overall">
                                <div className="text-center py-5 d-none">
                                    <p className="not_found_text">No items found</p>
                                    <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                                    <div className="mt-3">
                                    <Button className="create_btn">Browse Marketplace</Button>
                                    </div>             
                                </div>
                                
                                <div className="row masonry m-0 ma_no_gap card_main_iner">
                                    <div className="col-md-12">
                                        <div className="d-flex itemsselect">
                                        <div class="input-group sideinput mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search"></i></span>
                                            </div>
                                                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>

                                        </div>
                                            <select class="form-control mb-3">
                                                <option>Small select</option>
                                                <option>Small select</option>
                                                <option>Small select</option>
                                                <option>Small select</option>
                                            </select>
                                            <select class="form-control mb-3">
                                                <option>Small select</option>
                                                <option>Small select</option>
                                                <option>Small select</option>
                                                <option>Small select</option>
                                            </select>
                                        </div>
                                        <p>996 items</p>
                                        <div className="filteredlist">
                                            <ul>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                <Button variant="text" className="d-inline-block">Clear all</Button>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                   
                                
                                </div>
                         
                            <div className="row masonry m-0 ma_no_gap card_main_iner">
                            <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                            <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/my_items_1.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/logo_big_iocn.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                                {/* <div className="d-flex creators_details">
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                </div> */}

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>
                                <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                                <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/my_items_2.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/user_img_2.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                            

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>
                                <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                                <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/my_items_3.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/user_img_1.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                                {/* <div className="d-flex creators_details">
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                </div> */}

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>
                                <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                                <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/masonary_03.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/user_img_1.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                                {/* <div className="d-flex creators_details">
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                </div> */}

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>

                                <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                            <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/my_items_4.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                                {/* <div className="d-flex creators_details">
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                                </div> */}

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>
                                <div className={"item col-12 col-xl-3 mb-3" + imgeClass}>
                                <div className="">
                                    <div className="card_inner_item">
                                    
                                <div className="item_inner_img">
                                <div className="like_div_round">
                                <i className="far fa-heart liked"></i>
                                </div>
                                <img src={require("../assets/images/masonary_04.png")}  alt="Collections" className="img-fluid" />
                                </div> 
                                <div className="img_desc_nft px-3 py-3">
                                <img src={require("../assets/images/user_img_2.png")} alt="User" className="img-fluid profiel_img_pos" />
                                <img src={require("../assets/images/star.png")}  alt="Collections" className="img-fluid star_img_blue" />

                                <div className="d-flex justify-content-between mt-2">
                                                <h2>Pet Dragon | Spirit image</h2>
                                                <div>
                                    <h3 className="my-2"><span>2.3OPEN</span> </h3>

                                                </div>
                                            

                                                </div>
                                            
                                <div className="d-flex justify-content-between align-items-center">
                                <div>
                                <p className="like_count">5 likes</p>
                                </div>
                                
                                </div>
                            
                                    </div>

                                
                            
                                                </div>
                            </div>
                                </div>
                                
                            </div>


                            </div>
                            </div>
                            <div className="tab-pane fade " id="activity" role="tabpanel" aria-labelledby="activity-tab">
                            <div className="proposal_panel_overall">
                                <div className="text-center py-5 d-none">
                            <p className="not_found_text">No items found</p>
                            <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                            <div className="mt-3">
                            <Button className="create_btn">Browse Marketplace</Button>
                            </div>             
                            </div>
                            <div className="row masonry m-0 ma_no_gap card_main_iner">
                                <div className="col-md-12">
                            <div className="filteredlist">
                                            <ul>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button>Lavender 
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </li>
                                                <li>
                                                <Button variant="text" className="d-inline-block">Clear all</Button>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                        </div>
                                        </div>

                                        <hr/>
                            <div className="followers_overall py-1">
                           
                            <div className="row masonry m-0 ma_no_gap card_main_iner">
                            <div className=" col-md-12 mb-3">
                            <div className="d-grid itemsselect">
                                <select class="form-control">
                                    <option>Small select</option>
                                    <option>Small select</option>
                                    <option>Small select</option>
                                    <option>Small select</option>
                                </select>
                            <div>
                                <p className="avgprice">90 days Avg.price</p>
                                <p className="pricestate themeclr"><span className="fa fa-align-left"></span>0.4587</p>
                            </div>
                            <div>
                                <p className="avgprice">90 days Avg.price</p>
                                <p className="pricestate themeclr"><span className="fa fa-align-left"></span>0.4587</p>
                            </div>
                            </div>
                        </div>
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                            <p className="join_head mt-0">Filters</p>
                            <div className="my-4 btn_filter_row">
                            
                            <Button className="create_btn mb-3">Minted</Button>
                            <Button className="create_btn ml-3 mb-3">Bids</Button>
                            <Button className="create_btn ml-3 mb-3">Likes</Button>
                            <Button className="create_btn ml-3 mb-3">Followings</Button>


                            </div>
                            </div>
                           
                            </div>
                         
            <div className="row masonry m-0 ma_no_gap card_main_iner">
                    <div className="col-12 col-md-12  mb-4">
                        <div className="table-responsive">
                    <table class="table activtab">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Items</th>
      <th scope="col">Price</th>
      <th scope="col">Quantity</th>
      <th scope="col">From</th>
      <th scope="col">To</th>
      <th scope="col">Time</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">
      <span className=""><i className="fa fa-shopping-cart mr-2"></i>Sale</span>
          </th>
      <td>
          <div className="d-flex tableprofimg">
          <img src={require("../assets/images/img_03.png")} alt="User" className="img-fluid" />
         <p className="mb-2 media_text">OpenSaleArt</p></div>
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>ETH</span>
              <p>$15.15</p>
          </div>
      </td>
      <td>1</td>
      <td>
          <a href="#">548HGJ4</a>
      </td>
      <td><a href="#">548HGJ4</a></td>
      <td>
          <a href="#">6 mins ago<span className="fa fa-share ml-2"></span></a>
      </td>
    </tr>

    <tr>
      <th scope="row">
      <span className=""><i className="fa fa-shopping-cart mr-2"></i>Sale</span>
          </th>
      <td>
          <div className="d-flex tableprofimg">
          <img src={require("../assets/images/img_03.png")} alt="User" className="img-fluid" />
         <p className="mb-2 media_text">OpenSaleArt</p></div>
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>ETH</span>
              <p>$15.15</p>
          </div>
      </td>
      <td>1</td>
      <td>
          <a href="#">548HGJ4</a>
      </td>
      <td><a href="#">548HGJ4</a></td>
      <td>
          <a href="#">6 mins ago<span className="fa fa-share ml-2"></span></a>
      </td>
    </tr>

    <tr>
      <th scope="row">
      <span className=""><i className="fa fa-shopping-cart mr-2"></i>Sale</span>
          </th>
      <td>
          <div className="d-flex tableprofimg">
          <img src={require("../assets/images/img_03.png")} alt="User" className="img-fluid" />
         <p className="mb-2 media_text">OpenSaleArt</p></div>
      </td>
      <td>
          <div className="price-tab">
              <span><i className="fab fa-ethereum mr-2"></i>ETH</span>
              <p>$15.15</p>
          </div>
      </td>
      <td>1</td>
      <td>
          <a href="#">548HGJ4</a>
      </td>
      <td><a href="#">548HGJ4</a></td>
      <td>
          <a href="#">6 mins ago<span className="fa fa-share ml-2"></span></a>
      </td>
    </tr>
 
  </tbody>
</table>
</div>
                        {/* <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                    
                                    <div className="img_media_new  mr-3">
                                    <img src={require("../assets/images/user_img_1.png")} alt="User" className="img-fluid" />
                                    </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@JamesArt</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">3 hours ago</p>
                        </div>
                        
                        </div>
                        </div>

                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                    
                                    <div className="img_media_new  mr-3">
                                    <img src={require("../assets/images/user_img_2.png")} alt="User" className="img-fluid" />
                                    </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@James</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">20 minutes ago</p>
                        </div>
                        
                        </div>
                        </div>

                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                            <div className="img_media_new  mr-3">
                                <img src={require("../assets/images/user_img_3.png")} alt="User" className="img-fluid" />
                            </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@JamesArt</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">2 hours ago</p>
                        </div>
                        
                        </div>
                        </div>


                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                    
                                    <div className="img_media_new  mr-3">
                                    <img src={require("../assets/images/user_img_1.png")} alt="User" className="img-fluid" />
                                    </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@JamesArt</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">3 hours ago</p>
                        </div>
                        
                        </div>
                        </div>

                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                    
                                    <div className="img_media_new  mr-3">
                                    <img src={require("../assets/images/user_img_2.png")} alt="User" className="img-fluid" />
                                    </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@James</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">20 minutes ago</p>
                        </div>
                        
                        </div>
                        </div>

                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                        <div className="card-body px-3">
                        <div className="media follow_media icon_img">
                        <div className="icon-img-div">
                    
                                    <div className="img_media_new  mr-3">
                                    <img src={require("../assets/images/user_img_3.png")} alt="User" className="img-fluid" />
                                    </div>
                        </div>
                            <div className="media-body flex_body">
                            <div>
                            <p className="mb-2 media_text">OpenSaleArt</p>
                            <p className="media_text mb-0">Liked by <span className="media_text">@JamesArt</span></p>
                        
                            </div>
                        
                            </div>
                            </div>
                            <div className="ml-2">
                            <p className="mt-0 media_num mb-0 dur_text">2 hours ago</p>
                        </div>
                        
                        </div>
                        </div> */}
                    </div>

                
                    </div>
                            </div>


                            </div>
                            </div>
                          
                        </div>
                        </GridItem>
                    </GridContainer>
                </div>
          </div>
        </div>
        </div>
      </div>
      <Footer/>
          {/* edit_cover Modal */}
 <div class="modal fade primary_modal" id="edit_cover_modal" tabindex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div class="modal-content">
     <div class="modal-header text-center">
       <h5 class="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
       <h5 class="modal-title d-none" id="edit_cover_modalLabel_2">Follow Steps</h5>

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">   
     <div className="update_cover_div_1" id="update_cover_div_1">
     <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution</p>                      
       <form> 
       <div className="file_btn btn primary_btn">Choose image
          <input className="inp_file" type="file" name="file"/>
        </div>         
       </form>
       </div>
       <div className="update_cover_div_2 d-none" id="update_cover_div_2">
       <div className="media approve_media">
       {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
       <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Preferences</p> 
         <p className="mt-0 approve_desc">Updating cover</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="btn_outline_red btn-block">Inprogress</Button>                
       </div>
       </div>
     </div>
   </div>
 </div>
</div>
{/* end edit_cover modal */}
 {/* SHARE Modal */}
 <div className="modal fade primary_modal" id="share_modal" tabIndex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="share_modalLabel">Share this NFT</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row justify-content-center mx-0">
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <i className="fab fa-telegram-plane"></i>
                  <p>Telegram</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <i className="fab fa-twitter"></i>
                  <p>Twitter</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <i className="fab fa-facebook-f"></i>
                  <p>Facebook</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                  <i className="fab fa-whatsapp"></i>
                  <p>Whatsapp</p>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      {/* end SHARE modal */}
       {/* report Modal */}
 <div className="modal fade primary_modal" id="report_page_modal" tabIndex="-1" role="dialog" aria-labelledby="report_page_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="report_page_modalLabel">Report This Profile?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form>
<p className="font_14 font_600 line_20 mb-1 text_drk_bl">Tell us how this user violates the rules of the site</p>
<div className="form-row mt-3">
<div className="form-group col-md-12">
<label className="primary_label" for="name">Message</label>
<textarea type="text" className="form-control primary_inp" id="name" rows="3"  placeholder="Tell us some details" />
</div>
</div>
<div className="text-center mt-2">
<button className="create_btn btn-block w-100" type="button">
Submit
</button>
</div>
</form>
             
            </div>
          </div>
        </div>
      </div>
      {/* end report modal */}
    
    </div>
  );
}