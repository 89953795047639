import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";
import {FaAngleDown} from "react-icons/fa";

// @material-ui/icons
import { AccountBalanceWallet } from '@material-ui/icons';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Itemcard from "./separate/itemcard.js";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";


import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import { GetProfiledata, EditCoverPhoto } from "actions/controller/userControl";
import { ImageValidation } from "actions/controller/validation";
import { useSelector } from "react-redux";
import isEmpty from "lib/isEmpty";
import config from "lib/config";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast, Flip } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ExploreCard from "./separate/explore_card";
import ActivityCard from "./separate/ActivityCard";
import { MyItemsData } from "actions/controller/tokenControl";
import { MyUserData } from "actions/controller/userControl";
import { FollowAction } from "actions/v1/user";
import { useHistory, useParams } from "react-router-dom";
import { Audio, TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { TramRounded } from "@material-ui/icons";
import {Share} from "./separate/ShareNow"
toast.configure();







const dashboardRoutes = [];

const useStyles = makeStyles(styles);

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function Authors(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    
  };

  var state = {
    nav: true,
    responsive:{
      0: {
          items: 1,
      },
      575: {
        items: 5,
    },
      992: {
        items: 6,
    },
      1200: {
          items: 9,
      },
  },
  };

  const ShareNowRef = useRef();
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const { useradd, userurl } = useParams();
  const InitialForm = {
    Name: "",
    CustomUrl: "",
    Joined: "",
    Bio: "",
    Twitter: "",
    Facebook: "",
    Instagram: "",
    Youtube: "",
    Count: {},
    curraddress: Wallet_Details.UserAccountAddr,
  };
  const [Formdata, setFormdata] = useState(InitialForm);
  const [Pro, setPro] = useState(require("../assets/images/profile_img.png"));
  const [Cover, setCover] = useState(
    require("../assets/images/my_items_baner.png")
  );
  const [MyItemNFTs, setMyItemNFTs] = useState({ onsale: { page: 1, list: [], onmore: true } });
  const [MyUserFollow, setMyUserFollow] = useState({
    followers: { page: 1, list: [], onmore: true },
    activity: { page: 1, list: [], onmore: true },
  });
  const [FollowState, setFollowState] = useState(false);
  const [TabName, setTabName] = useState();
  const [Loader, setLoader] = useState(false);
  const [ ShowModal,setShowModal] = useState(false)
  const [ FollowButState,setFollowButState] = useState(false)
 

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};


useEffect(() => {
  setShowModal(true)
  GetProfile();
  //console.log("useradd.uerurl in useeffect ",userurl, useradd)
}, [userurl, useradd, Wallet_Details.UserAccountAddr]);

//Function
const GetProfile = async () => {
  //console.log("i/p dta to get profile my-item ",userurl,useradd,Wallet_Details.UserAccountAddr )
  setLoader(true);
  if (userurl)
  {
  //console.log("userurldfgdfg",userurl)
    var Payload = {
      user: { customurl: userurl },
      followerAddress: Wallet_Details.UserAccountAddr,
    };
  }
  if (useradd)
  {
    var Payload = {
      user: { curraddress: useradd },
      followerAddress: Wallet_Details.UserAccountAddr,
    };
  }
  if (!userurl && !useradd)
  {
    var Payload = {
      user: { curraddress: Wallet_Details.UserAccountAddr },
      followerAddress: Wallet_Details.UserAccountAddr,
    };
  }
    //console.log("ppayload data ",Payload)
  var Resp = await GetProfiledata(Payload);
  console.log("resp dtat of getprofile ",Resp)
  if (!isEmpty(Resp)) {
    setFormdata(Resp);
    if (Resp.photo != "")
      setPro(
        `${config.Back_URL}/Users/${Resp.curraddress}/profileImg/${Resp.photo}`
      );
    else setPro(require("../assets/images/profile_img.png"));
    if (Resp.coverimage)
      setCover(
        `${config.Back_URL}/Users/${Resp.curraddress}/coverImg/${Resp.coverimage}`
      );
    else setCover(require("../assets/images/my_items_baner.png"));
    if (Resp.Follow) {
      setFollowState(Resp.Follow);
    }
    setMyItemNFTs({ onsale: { list: [] } });
    await MyItemTab("onsale", Resp.curraddress, "Profile");
  }
};
//Function
const Handlefile = (e) => {
  if (e.target && e.target.files) {
    var file = e.target.files[0];
    var Resp = ImageValidation(file, "Edit");
    if (Resp.Success) {
      CoverPhoto(file);
    } else toast.error(Resp.Message);
  }
};
//Function
const CoverPhoto = async (file) => {
  var Payload = { File: file, curraddress: Formdata.curraddress };
  var Cresp = await EditCoverPhoto(Payload);
  $("#coverchoose").trigger("click");
  setCover(URL.createObjectURL(file));
};
//Function
const MyItemTab = async (tab, addr, filter,Pageno) => {
 
  if(tab && !MyItemNFTs[tab])
    {
      setLoader(true);
      MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
      setMyItemNFTs(MyItemNFTs);
    }
  var curraddress = addr ? addr : Formdata.curraddress;
  var Payload = {
    curraddress: curraddress,
    Tab: tab ? tab : "onsale",
    Action: "Click",
    Limit: 16,
    Page: Pageno || 1,
    filter: filter,
  };
  if (tab === "activity" || tab === "following" || tab === "followers") {
    var myUser = await MyUserData(Payload);
    if (myUser.Success) {
      setTabName(Payload.Tab);
      var temp = MyUserFollow;
      temp[myUser.Tab] = myUser.records;
      setMyUserFollow([]);
      setMyUserFollow(temp);
    } else {
      setTabName(Payload.Tab);
    }
  } else {
    var myNFT = await MyItemsData(Payload);
    if (myNFT.Success) {
      if(Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page)
      {
        MyItemNFTs[myNFT.Tab].page = Pageno
        MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
        MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
        setMyItemNFTs([]);
        setMyItemNFTs(MyItemNFTs);
      }
      else{
        if(MyItemNFTs[myNFT.Tab].page === 1){
          MyItemNFTs[myNFT.Tab].list = myNFT.records;
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
          setTimeout(() => {
            setShowModal(false)
          }, 1500);
        }
      }
      setTabName(Payload.Tab);
    } else {
      setTabName(Payload.Tab);
    }
  }
  setTimeout(() => {
    setLoader(false);
  }, 1000);
};
//Function
const FollowAct = async () => {
 
  if (Wallet_Details.UserAccountAddr !== "") {
    setFollowButState(true)
    if(FollowState){
      toast.success("Removed from Followings", {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
    }
    else{
      toast.success("Added to Followings", {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
    }
    var Payload = {
      userAddress: Formdata.curraddress,
      followerAddress: Wallet_Details.UserAccountAddr,
    };
    var resp = await FollowAction(Payload);
    if (resp.data.Success) {
      setFollowState(!FollowState);
      setFollowButState(false)
    } else{
      toast.warning(resp.data.Message, {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
      });
      setFollowButState(false)
    }
  } else history.push("/connect-wallet");
};

  //Component
  const MyItemCard = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5 col-12 tail_spin_div">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (
          <>
            {MyItemNFTs &&
            MyItemNFTs[TabName] &&
            MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {MyItemNFTs[TabName].list.map((NFT) => {
                  return (
                    <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                    <Itemcard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    /></div>
                  );
                })}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>
        )}
      </>
    );
  };
  //Component
  const FollowDetails = () => {
    return (
      <>
        {MyUserFollow &&
          MyUserFollow[TabName] &&
          MyUserFollow[TabName].length > 0 &&
          MyUserFollow[TabName].map((user) => {
            if (user.user.image !== "")
              var Userimg = `${config.Back_URL}/Users/${user.user.curraddress}/profileImg/${user.user.image}`;
            else var Userimg = `${require("../assets/images/user_img_1.png")}`;
            var Username = user.user.name
              ? user.user.name
              : user.user.curraddress.slice(0, 20).concat("..");
            return (
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <Link to={(user.user.customurl)?`/my-items/user/${user.user.customurl}`:`/my-items/${user.user.curraddress}`}>
                <div className="card acti_card rounded-corners-gradient-borders">
                  <div className="card-body px-3">
                    <div className="media follow_media">
                      <div className="img_media_new  mr-3">
                        <img src={Userimg} alt="User" className="img-fluid" />
                      </div>

                      <div className="media-body flex_body">
                        <div>
                        <p className="mt-0 media_text">{Username}</p>
                          <p className="mt-0 media_num font_14 mb-0">
                            {user.Followers && user.Followers.count
                              ? user.Followers.count
                              : 0}{" "}
                            followers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              </div>
            );
          })}
      </>
    );
  };
  //Component
  const ActivityDetails = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5 col-12 tail_spin_div">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (
          <>
            {MyUserFollow &&
            MyUserFollow[TabName] &&
            TabName === "activity" &&
            MyUserFollow[TabName].length > 0 ? (
              MyUserFollow[TabName].map((act) => {
                //console.log("innnfiknsfkneinact", act);
                return (
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                      <ActivityCard Activity={act} />
                    </div>
                  </div>
                );
              })
            ) : (
              <BrowseMarketPlace />
            )}
          </>
        )}
      </>
    );
  };
  //Component
  const BrowseMarketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
        <Link to="/explore/All">
          <Button className="create_btn">Browse Marketplace</Button>
          </Link>
        </div>
      </div>
    );
  };

  






  return (
    <div>
  
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid logo_img" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />



    <section className="page-head body-top">
        <div className="container container_custom">
            <h2 className="page-heading">Authors</h2>
            <ul className="breadcrums">
                <li><a href="#">Home</a></li>
                <li><a href="#"  className="active">Authors</a></li>
            </ul>
            
        </div>
    </section>

    <section className="top-nav-tab pt-0">
      <div className="container container_custom">
          <div className="author-top-box">
            <div className="topbg">
              <div className="row authors_row_img pt-4 pb-2 pb-xl-4">
                  <div className="col-xl-3 col-md-3">
                         <img src={Pro}  alt="Collections" className="img-fluid" />
                  </div>
                  <div className="col-xl-9 col-md-9 p-authdet">
                      <div className="row pb-4">
                          <div className="col-xl-6 col-md-12">
                            <label className="author_lable">Author</label>
                            <h3 className="auth-name">
                            {Formdata.Name}
                            </h3>
                            <p>
                            @{Formdata.CustomUrl}
                            </p>
                          
                        
                              <button className="copy" >
                                  <span className="elipsis">{Formdata.curraddress}</span>
                                  <CopyToClipboard
                        text={Formdata.curraddress}
                        onCopy={() =>
                          toast.success("Address Copied", {
                            closeButton: true,
                            autoClose: 1500,
                            transition: Flip,
                            theme: "dark",
                          })
                        }
                      >
                                  <span className="fa fa-copy"></span>
                                  </CopyToClipboard>
                              </button>
 
                          </div>
                          <div className="col-xl-6 col-md-12 jus_end_cont">
                          <div className="mt-3">
                          {Formdata.Twitter !== "" && (
                            <a href={Formdata.Twitter} target="_blank" className="social_blue_icons mr-2">
                            <i class="fab fa-twitter"></i>
                            </a>)}
                            {Formdata.reddit !== "" && (
                            <a href={Formdata.reddit} target="_blank" className="social_blue_icons mr-2">
                            <i class="fab fa-reddit-alien"></i>
                            </a>)}
                            {Formdata.Youtube !== "" && (
                            <a href={Formdata.Youtube} target="_blank" className="social_blue_icons mr-2">
                            <i class="fab fa-youtube"></i>
                            </a>)}
                            {/* {Formdata.Email !== "" && (
                            <a href={Formdata.Email} target="_blank" className="social_blue_icons mr-2">
                            <i class="fas fa-envelope"></i>
                            </a>)} */}
                            {Formdata.Instagram !== "" && (
                            <a href={Formdata.Instagram} target="_blank" className="social_blue_icons mr-2">
                            <i class="fab fa-instagram"></i>
                            </a>)}
                            {Formdata.Facebook !== "" && (
                            <a href={Formdata.Facebook} target="_blank" className="social_blue_icons mr-2">
                            <i class="fab fa-facebook-f"></i>
                            </a>)}
                            {Formdata.Site !== "" && (
                            <a href={Formdata.Site} target="_blank" className="social_blue_icons mr-2">
                            <i class="fas fa-share"></i>
                            </a>)}
                            
                            {Wallet_Details.UserAccountAddr !== Formdata.curraddress && (
                            <button className="followbtn"  disabled={FollowButState}
                               onClick={() => FollowAct()}>
                               {FollowState ? "Unfollow" : "Follow"}
                            </button>
                            )}
                           
                          </div>
                       
                          </div>
                       
                      </div>
                      {Formdata.Bio !== "" && (
                               <p>{Formdata.Bio}</p> 
                           )}
                  </div>
              </div>
              </div>
              <div className="bottombg">
                      <div className="row ">
                          <nav className="explore-tab py-0">
                                  <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                  <a onClick={() => {
                                      MyItemTab("onsale");
                                    }}
                                    className="nav-link active" id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true">
                                      <div className="tab_head">Onsale</div>
                                      
                                      </a>
                                      <a
                                       onClick={() => {
                                        MyItemTab("created");
                                      }}
                                       className="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false">
                                      <div className="tab_head">Created</div>
                                  
                                      </a>
                                  <a
                                   onClick={() => {
                                    MyItemTab("collectibles");
                                  }}
                                  className="nav-link" id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false">
                                  <div className="tab_head">Collectibles</div>
                                  
                                      </a>
                                  
                                  <a onClick={() => {
                                      MyItemTab("liked");
                                    }}
                                    className="nav-link" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false">
                                  <div className="tab_head">Liked</div>
                                  
                                      </a>

                                      <a 
                                       onClick={() => {
                                        MyItemTab("following");
                                      }}
                                      className="nav-link" id="saved-tab" data-toggle="tab" href="#saved" role="tab" aria-controls="liked" aria-selected="false">
                                  <div className="tab_head">Following {(Formdata.Count && Formdata.Count.Followings) || 0}</div>
                                  
                                      </a>
                                      <a  onClick={() => {
                                      MyItemTab("followers");
                                    }}
                                    className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">
                                  <div className="tab_head">Followers {(Formdata.Count && Formdata.Count.Followers) || 0}</div>
                              
                                      </a>
                                  
                                  </div>
                              </nav>
                          </div>
                          </div>
            
          </div>
          
        </div>
    </section>
 

      <section className="explore pt-0">
         <div className="container container_custom">
           
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>                                       
           
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                <div className="proposal_panel_overall">
                      {TabName === "onsale" ||
                      TabName === "created" ||
                      TabName === "collectibles" ||
                      TabName === "liked" ? (
                        <>
                          <MyItemCard />
                          {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
                          <div  className="loadmore mt-4">
                            <button className="theme-btn"
                            onClick={()=>MyItemTab(TabName, Formdata.curraddress, null,MyItemNFTs[TabName].page+1)}>
                              Load more 
                            </button>
                          </div>
                          }
                        </>
                      ) : (
                        <>
                          {TabName === "followers" ||
                          TabName === "following" ? (
                            <>
                              {Loader ? (
                                <div className="text-center py-5 col-12 tail_spin_div">
                                  <TailSpin
                                    wrapperClass="searreactloader"
                                    color="#00BFFF"
                                    height={100}
                                    width={70}
                                  />
                                </div>
                              ) : (
                                <div className="followers_overall py-1">
                                  {MyUserFollow &&
                                  MyUserFollow[TabName] &&
                                  MyUserFollow[TabName].length > 0 ? (
                                    <>
                                      <div className="row mt-3">
                                        <FollowDetails />
                                      </div>
                                      <div className="col-12 pb-5 text-center pt-4">
                                        <button className="create_btn">
                                          Load more items
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <BrowseMarketPlace />
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {TabName === "activity" && (
                                <div className="followers_overall py-1">
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                                      <p className="join_head mt-0">Filters</p>
                                      <div className="my-4 btn_filter_row">
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr
                                            );
                                          }}
                                          className="create_btn mb-3"
                                        >
                                          All
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Profile"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Profile
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Like"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Like
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Mint"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Mint
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Follow"
                                            );
                                          }}
                                          className="create_btn ml-3 mb-3"
                                        >
                                          Followings
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <ActivityDetails />
                                  <div className="col-12 pb-5 text-center pt-4">
                                    <button className="create_btn">
                                      Load more items
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                </div>

                {/* <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                <div className="row">
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                    </div>
                    <div className="loadmore mt-4">
                      <button className="theme-btn">Load more</button>
                    </div>
                </div>
                <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="row">
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                    </div>
                    <div className="loadmore mt-4">
                      <button className="theme-btn">Load more</button>
                    </div>
                </div>
               

                <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                <div className="row">
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                    </div>
                    <div className="loadmore mt-4">
                      <button className="theme-btn">Load more</button>
                    </div>
                </div>

                <div className="tab-pane fade" id="saved" role="tabpanel" aria-labelledby="saved-tab">
                <div className="row">
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                    </div>
                    <div className="loadmore mt-4">
                      <button className="theme-btn">Load more</button>
                    </div>
                </div>
                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                <div className="row">
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-12 col-md-6">
                        <Itemcard />

                        </div>
                    </div>
                    <div className="loadmore mt-4">
                      <button className="theme-btn">Load more</button>
                    </div>
                </div> */}
              </div>
            </GridItem>
          </GridContainer>
         </div>
      </section>
      <div id="logo_overlay" className="logo_ovelay">
        
      <Footer />

    </div>
    </div>
    </div>
  );
}
