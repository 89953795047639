import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

// @material-ui/icons

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

export default function Placebidmultiplemodal(props) { 


//   const classes = useStyles();

  const { ...rest } = props;

 
  return (
    <div>
 <div className="modal fade primary_modal" id="place_bid_multiple_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_multiple_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_multiple_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>
             
              
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <form className="px-4 bid_form">
            <label for="bid">Your bid</label>
            <div className="input-group mb-3 input_grp_style_1">
           
           <input type="text" id="bid" className="form-control" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" />
           <div className="input-group-append">
             <span className="input-group-text pl-0" id="basic-addon2">USD</span>
           </div>
         </div>
 
         <div className="input-group mb-3 input_grp_style_1">
           
           <input type="text" id="bid" className="form-control" value="0.01B" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" disabled/>
           <div className="input-group-append">
             <span className="input-group-text pl-0" id="basic-addon2">OPEN</span>
           </div>
         </div>

        <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
            <div className="mb-3 input_grp_style_1">
          <input type="text" id="qty" className="form-control" placeholder="1"  />
         
        </div>
               <div className="row pb-3">
                 <div className="col-12 col-sm-6">
                 <p className="buy_desc_sm">Your balance</p>
                 </div>
                 <div className="col-12 col-sm-6 text-sm-right">
                 <p className="buy_desc_sm_bold">2.3B OPEN</p>
                 </div>
                </div>   
                <div className="row pb-3">
                 <div className="col-12 col-sm-6">
                 <p className="buy_desc_sm">Your bidding balance</p>
                 </div>
                 <div className="col-12 col-sm-6 text-sm-right">
                 <p className="buy_desc_sm_bold">2.3B OPEN</p>
                 </div>
                </div>             
                <div className="row pb-3">
                 <div className="col-12 col-sm-6">
                 <p className="buy_desc_sm">Service fee</p>
                 </div>
                 <div className="col-12 col-sm-6 text-sm-right">
                 <p className="buy_desc_sm_bold">2.3B OPEN</p>
                 </div>
                </div> 
                <div className="row pb-3">
                 <div className="col-12 col-sm-6">
                 <p className="buy_desc_sm">You will pay</p>
                 </div>
                 <div className="col-12 col-sm-6 text-sm-right">
                 <p className="buy_desc_sm_bold">1.3B OPEN</p>
                 </div>
                </div>           
                            
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>

                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
